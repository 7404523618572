import React, { useEffect, useState ,useContext } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import  AuthUser  from "../../context/AuthUser";
import Cookies from 'js-cookie';
import {  decrpt  } from "../../component/encryption";

function ChangePass() {  
  const [data, setData] = useState({
    old_password : '',
    password : '',
    password_confirmation : ''
  });
  const [formError, setFormError] = useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });
  const [errors,setErrors]=useState();

  const handleUserInput = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateFormInput = (e) => {
    // e.preventdefault();
    let inputError = {
      old_password: "",
      password: "",
      password_confirmation: "",
    };
    const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');
 if (validPassword.test(data.password)) {
      setFormError({
        ...inputError,
        password: "Please check password",
      });
      return
    }
    if (data.password_confirmation !== data.password) {
      setFormError({
        ...inputError,
        password_confirmation: "Password and confirm password should be same",
      }); 
      return;
    }
    if (data.password_confirmation === data.password && data.old_password !== setData.old_password  ) {
      setFormError({
        ...inputError,
        old_password: "old password is wrong",
      }); 
      return;
    }
    if (!data.password_confirmation) {
      setFormError({
        ...inputError,
        password: "Password should not be empty",
      });
      return
    }
    if (!data.password.length < 8) {
      setFormError({
        ...inputError,
        password: "Password length must be greater than 8",
      });
      return
    }
    if (!data.old_password ==='' && !data.password ==='') {
      setFormError({
        ...inputError,
        old_password: "Password should not be empty",
        password: "Password should not be empty",
      });
      return
    }
    if (data.old_password !== setData.old_password) {
      setFormError({
        ...inputError,
        old_password: "Please enter your old password",
      });
      return
    }
   
    setFormError(inputError);
  };

  const handleSubmit  = async(e)  => {
    e.preventDefault();
    validateFormInput();
    // setError(false);
    let token = decrpt(Cookies.get('s_l'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axios.post(`/seller/change/password` ,data)
    .then((response) => {
      console.log('password data' , response.data);
      if(response.data.success){
      }
    }).catch((errors)=>{
      // setError(true); 
      if (errors.response.status==400) {
        setErrors(errors.response.data.errors);
      console.log('password data erros' , errors.response);

      } else {
      
      }
  })
 
  }
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <div className=" mb-3 p-2">
         <div className=" mt-3 mb-5">
          <div className="row">
            <div className="col-md-12">
            <div className="section-title"><h5>Change Password</h5></div>
              <form onSubmit={ handleSubmit } method="post">
                <>
                <div className="row mt-4" style={{marginLeft:0}}>
                  <div className="col-md-6 card" style={{padding:20}}>
                    <div className="col-md-12">
                        <label className="form-label label-txt">
                          Old Password 
                        </label>
                        <input type="text" className="form-control " 
                        value={data.old_password}
                        onChange={({ target }) => {
                          handleUserInput(target.name, target.value);
                        }}
                        name="old_password"
                        //  onChange={e=>setData({...data, old_password:e.target.value})}
                          required/>
                    {formError && <p style={{color : 'red',fontSize:12}}>{formError.old_password}</p>}
                    {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors}</p>)}
                    </div>
                    <div className="col-md-12 mt-1">
                    <label className="form-label label-txt">
                      New Password
                    </label>
                    <input type="text" className="form-control " 
                     value={data.password}
                     onChange={({ target }) => {
                       handleUserInput(target.name, target.value);
                     }}
                     name="password"
                    // onChange={e=>setData({...data, password:e.target.value})} 
                    required/>
                    {formError && <p style={{color : 'red',fontSize:12}}>{formError.password}</p>}
                    {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors}</p>)}

                    </div>
                    <div className="col-md-12 mt-1">
                    <label className="form-label label-txt">
                      Confirm Password
                    </label>
                    <input type="text" className="form-control " 
                     value={data.password_confirmation}
                     onChange={({ target }) => {
                       handleUserInput(target.name, target.value);
                     }}
                     name="password_confirmation"
                    // onChange={e=>setData({...data, password_confirmation:e.target.value})} 
                    required/>
                    </div>
                    {formError && <p style={{color : 'red',fontSize:12}}>{formError.password_confirmation}</p>}
                    {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors}</p>)}

                  <div className="mt-3 d-flex justify-content-center col-md-12">
                    {/* <Link
                      to="/templete"
                      style={{ textDecorationLine: "none" }}
                      className="changePassBtn">
                      Change Password
                    </Link> */}
                    <button type="submit" style={{ textDecorationLine: "none" }}
                      className="changePassBtn">Change Password</button>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
              </>
            </form>
          </div>
          </div>
          </div>
         </div> 
    </div>
  );
}

export default ChangePass;