import React, { useEffect, useState } from 'react';
import {BiEditAlt } from "react-icons/bi";
import {AiOutlinePlusCircle } from "react-icons/ai";
import { Modal, Button, Table} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Cookies from 'js-cookie';
import {decrpt} from '../../../component/encryption';

const ProductPrice = (props) => {
  // console.log("multiProductList",props)
// console.log(props.adminCommission,'add product data');

useEffect(()=>{
    // setProductIdFunc(props.productID);
    // props.taxCal();
    // props.adminCom();
    // props.multiProductFetchList();
    // props.commisionApi();
  },[]);


  
  return (
    <div>
      <div className="container mt-4 mb-4">
        <form>
          {props.sizeOption && props.sizeOption == '1' ?
            <div className="row mt-2">
               <p style={{ fontWeight: "bold" }}>Add Product Price & Stock</p>
              <div className="col-md-4 ">
                <label className="form-label label-txt col-md-7"> Product MRP </label>
                <input type="text" className="form-control" onChange={(e)=> props.setProductPrice({...props.productPrice,mrp:e.target.value})} />
              </div>
              <div className="col-md-4 ">
                <label className="form-label label-txt col-md-7"> Product Price </label>
                <input type="text" className="form-control" onChange={(e)=> props.openTaxTable(e.target.value)} defaultValue={props.productPrice.price} />

                 {/* Price Table */}
                {props.taxTable && ( <table className="table table-bordered mt-2">
                  <tbody>
                    <tr>
                      <td>Tax (18%)</td>
                      <td>₹ {props.tax}</td>
                    </tr>
                    <tr>
                      <td>Admin Commission (2%)</td>
                      <td>₹ {props.adminCommission}</td>
                    </tr>
                    <tr>
                      <td>You will get </td>
                      <td style={{fontSize:15}}>₹ {parseInt(props.productPrice && props.productPrice.price) - parseInt(props.tax) - parseInt(props.adminCommission)}</td>
                    </tr>
                  </tbody>
                </table> )}

              </div>
              <div className="col-md-4 ">
                <label className="form-label label-txt col-md-7">Stock</label>
                <input type="text" className="form-control" onChange={(e)=> props.setProductPrice({...props.productPrice,stock:e.target.value})} />
              </div>
            </div>
          :
          <>
            <div className="section">
              <button type="button" className="saveChangesBtn btn btn-primary" onClick={()=> props.openAddPriceModal()} style={{boxShadow: '0 0 6px 0 #ffc0019e',fontWeight: 'bold'}}>Add Details <AiOutlinePlusCircle style={{ color: '#fff', fontSize: 18 }} ></AiOutlinePlusCircle></button>
              <div className="row align-items-center">
                <div className="col-md-12 mt-4">
                  <div className="card p-2">
                    <div className="card-title d-flex align-items-center justify-content-between">
                      <div></div>
                    </div>
                    <Table responsive="sm">
                      <thead>
                        <tr className="text-center">
                          <th>Product Size</th>
                          <th>Product MRP</th>
                          <th>Product Price</th>
                          <th>Product Stock</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{verticalAlign: 'middle'}} className="text-center">
                      {props.multiProductList && props.multiProductList.length > 0 ?
                        <>
                        {props.multiProductList && props.multiProductList.map((item,index)=>{
                          return(
                            <tr key={index}>
                            <td>{item.size}</td>
                            <td>{item.mrp}</td>
                            <td>{item.price}</td>
                            <td>{item.stock}</td>
                            <td>
                              <button type="button" style={{border: 'none', background: 'transparent'}}>
                              <BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={()=> props.multiProductUpdateHandle(item)} ></BiEditAlt>
                              </button>
                            </td>
                          </tr>
                          )
                        })}
                         
                        </>:
                        <tr>
                          <td colSpan={5}>No Product Found</td>
                        </tr>
                        }
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          
          {/* add modal */}
            <Modal show={props.showMultiProduct} onHide={()=> props.setShowMultiProduct(false)}> <Modal.Header closeButton>
                <Modal.Title>Add Product Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Size</Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(e) => 
                      {
                        e.preventDefault();
                        console.log('Size Name',e);                                                                                         
                        props.setProductPrice({...props.productPrice, size:e.target.value})
                      }}>
                        <option selected disabled> Select Product Size</option>
                      {props.productSize && props.productSize.map((item,index)=>{
                        return(
                          <option key={index} value={item.size_id}>{item.size_name}</option>
                        )
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Product MRP</Form.Label>
                    <Form.Control type="text" placeholder="Enter MRP" onChange={(e)=> props.setProductPrice({...props.productPrice,mrp:e.target.value})}/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Product Price</Form.Label>
                    <Form.Control type="text" placeholder="Enter price" onChange={(e)=> props.openTaxTable(e.target.value)} />
                    {/* Price Table */}
                    {props.taxTable && ( <table className="table table-bordered mt-2">
                      <tbody>
                        <tr>
                          <td>Tax (18%)</td>
                          <td>₹ {props.tax}</td>
                        </tr>
                        <tr>
                          <td>Admin Commission (2%)</td>
                          <td>₹ {props.adminCommission}</td>
                        </tr>
                        <tr>
                          <td>You will get </td>
                          <td style={{fontSize:15}}>₹ {parseInt(props.productPrice.price)-parseInt(props.tax)-parseInt(props.adminCommission)}</td>
                        </tr>
                      </tbody>
                    </table> )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Product Stock</Form.Label>
                    <Form.Control type="text" placeholder="Enter Stock" onChange={(e)=> props.setProductPrice({...props.productPrice,stock:e.target.value})}/>
                  </Form.Group>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={()=> props.setShowMultiProduct(false)}> Close </Button>
                    <Button className="saveChangesBtn" type="button" onClick={()=> props.addproductPrice()} > Save Changes </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>

            {/* update modal */}
            <Modal show={props.showMultiProductUpdate} onHide={()=> props.setShowMultiProductUpdate(false)}> <Modal.Header closeButton>
                <Modal.Title>Add Product Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Size</Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(e) => 
                      {
                        e.preventDefault();
                        props.setProductPrice({...props.productPrice, size:e.target.value});}}

                        defaultValue={props.productPrice.size}>
                         <option disabled> Select Product Size</option>
                          {props.productSize && props.productSize.map((item,index)=>{
                            return(
                              <option key={index} value={item.size_id}>{item.size_name}</option>
                            )
                          })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Product MRP</Form.Label>
                    <Form.Control type="text" placeholder="Enter MRP" onChange={(e)=> props.setProductPrice({...props.productPrice,mrp:e.target.value})}
                    defaultValue={props.productPrice && props.productPrice.mrp}/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Product Price</Form.Label>
                    <Form.Control type="text" placeholder="Enter price" onChange={(e)=> props.openTaxTable(e.target.value)} 
                    defaultValue={props.productPrice && props.productPrice.price }/>
                    {/* Price Table */}
                    {props.taxTable && ( <table className="table table-bordered mt-2">
                      <tbody>
                        <tr>
                          <td>Tax (18%)</td>
                          <td>₹ {props.tax}</td>
                        </tr>
                        <tr>
                          <td>Admin Commission (2%)</td>
                          <td>₹ {props.adminCommission}</td>
                        </tr>
                        <tr>
                          <td>You will get </td>
                          <td style={{fontSize:15}}>₹ {parseInt(props.productPrice.price)-parseInt(props.tax)-parseInt(props.adminCommission)}</td>
                        </tr>
                      </tbody>
                    </table> )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Product Stock</Form.Label>
                    <Form.Control type="text" placeholder="Enter Stock" onChange={(e)=> props.setProductPrice({...props.productPrice,stock:e.target.value})}
                    defaultValue={props.productPrice && props.productPrice.stock }/>
                  </Form.Group>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={()=> props.setShowMultiProductUpdate(false)}> Close </Button>
                    <Button className="saveChangesBtn" type="button" onClick={()=> props.UpdateproductMulti()} > Save Changes </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>
          </>
          }
        </form>
      </div>
      {props.sizeOption && props.sizeOption == 1 ?
      <div className="product-Modal-footer" style={{position: 'fixed', width: '100%',left: 0,bottom: 0, marginLeft: 0,backgroundColor:'#fff'}}>
        <button type="button" className="btn btn-primary product-modal-saveBtn" onClick={() =>props.backBtnHandleInfo()}>Back</button>
        <button type="button" className="btn btn-primary product-modal-saveBtn" onClick={() => props.addproductPrice()} >Save & Continue</button>
      </div>:
    
      <div className="product-Modal-footer" style={{position: 'fixed', width: '100%',left: 0,bottom: 0,marginLeft: 0,backgroundColor:'#fff'}}>
          <button type="button" className="btn btn-primary product-modal-saveBtn" onClick={() =>props.backBtnHandleInfo()}>Back</button>
        <button type="button" className="btn btn-primary product-modal-saveBtn" onClick={() => props.DisableHandler3()} >Continue</button>
      </div>
}
    </div>
  );
};

export default ProductPrice;