import React, { useEffect, useState,useRef } from "react";
import { Outlet ,useLocation} from "react-router-dom";
import { FaPhoneAlt, FaRegEnvelopeOpen, FaRegPaperPlane } from "react-icons/fa";
import { AiOutlinePoweroff, AiOutlineKey } from "react-icons/ai";
import { Badge, Button, Table} from 'react-bootstrap';
import Loader from '../../component/loader';
import TableThumbImage from '../../component/tableThumbImage';
import axios from 'axios';
import Cookies from "js-cookie";
import {decrpt} from "../../component/encryption";
import ReactToPrint  from 'react-to-print';




function OrderSingle() {
  let componentRef = useRef();
  const location = useLocation();

  const [loader,setLoader] = useState(false)
  console.log(location , "location");

  const [orderDetails,setOrderDetails]=useState('');

  const [orderDetailsProduct,setOrderDetailsProduct]=useState('');
  console.log(orderDetailsProduct,'orderDetailsProduct');
  
  const fetchOrderDitails=async()=>{
    setLoader(true);
    const data = {
      order_id: location.state.item,
    }
    console.log(data,'data');
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization']=`Bearer ${token}`;
      const response = await axios.post(`/seller/orders/details`,data);
      if (response.data.success) {
        setLoader(false);
        setOrderDetails(response.data.order_data);
        setOrderDetailsProduct(response.data.order_details)
        console.log(response, 'response skhd')
      } else {
        setLoader(false);
      }
      
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  useEffect (() => {
    window.scrollTo(0, 0);
    fetchOrderDitails();
    setLoader(true);
  }, []);

  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
          {/* New User Registration */}
          <div className="section">
            <div className="row" ref={el => (componentRef = el)}>

              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="d-flex justify-content-between">
                    {/* <h3 className="h3 fw-bold" style={styles.header}>Order ID : {orderDetailsProduct && orderDetailsProduct.unique_id}</h3> */}
                    <div  style={{marginLeft : 'auto'}} className="hidestuff">
                        <ReactToPrint
                            trigger={() => (
                              <button className="btn btn-warning" type="button" >
                                Print Receipt
                              </button>
                            )}
                            content={() => componentRef}
                            />
                        </div>
                  </div>
                  <div className="d-flex mt-2">
                    {/* <label className="mb-2"><strong>Order Status : </strong>
                    { orderDetails && orderDetails.order_status == 1 ? <Badge bg="primary"> New</Badge> : 
                    (orderDetails && orderDetails.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : 
                    (orderDetails && orderDetails.order_status == 7)  ? <Badge bg="danger"> Cancel By Seller </Badge> : 
                    (orderDetails && orderDetails.order_status == 6)  ? <Badge bg="danger"> Cancel By User </Badge> : 
                    (orderDetails && orderDetails.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
                    (orderDetails && orderDetails.order_status == 4) ? <Badge bg="secondary"> Out For Delivere</Badge> : 
                    <Badge bg="success"> Delivered</Badge> }</label> */}
                    {/* &nbsp;  | &nbsp;  */}
                    <label className="mb-2"><strong>Order Date : </strong> {orderDetails && orderDetails.order_date}</label>
                    &nbsp;  | &nbsp; 
                    <label className="mb-2"><strong>Buyer Name : </strong> {orderDetails && orderDetails.user_name}</label>
                  </div>

                  <div className="d-flex order-flex-wrap ">
                    <label><strong>Order Ammount : </strong> <span className="mb-2" >₹ {orderDetails && orderDetails.total_price}</span></label>
                       &nbsp;  |  &nbsp;
                     <label><strong>Payment Type : </strong>  <span className="mb-2" ><Badge bg="primary" text="light">{orderDetails && orderDetails.payment_type}</Badge></span></label>
                        &nbsp;  |  &nbsp; 
                    <label><strong>Payment Status : </strong>  <span className="mb-2" >
                      {
                      (orderDetails && orderDetails.payment_status == 1) ? <Badge bg="warning"> Pending </Badge> : 
                      (orderDetails && orderDetails.payment_status == 2) ? <Badge bg="success"> Paid</Badge> : 
                      <Badge bg="danger"> Failed</Badge> }</span></label>
                       &nbsp;  | &nbsp; 
                      <label><strong>Shipping Charge : </strong> <span className="mb-2" >₹ {orderDetails && orderDetails.shipping_charge}</span></label>
                    </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      {/* Shipping Address */}
                      <div>
                        <label className="mb-0"><strong>Shipping Address</strong> </label> 
                        
                        <p className="mb-0">Address Type : <span > 
                          {(orderDetails && orderDetails.shippingAddress.address_type == 'O') ? 
                          <Badge bg="secondary"> Office</Badge> : 
                          <Badge bg="secondary"> Home</Badge> }</span></p>

                        <p className="mb-0" style={{fontSize:15}}><FaRegPaperPlane /> {orderDetails && orderDetails.shippingAddress.address}, {orderDetails && orderDetails.shippingAddress.street} {orderDetails && orderDetails.shippingAddress.land_mark} {orderDetails && orderDetails.shippingAddress.city} - {orderDetails && orderDetails.shippingAddress.pin}, {orderDetails && orderDetails.shippingAddress.state}</p>

                        <div className="d-flex ">
                          <p style={{fontSize:15}}> <FaPhoneAlt /> +91 {orderDetails && orderDetails.shippingAddress.mobile}</p> 
                          {/* &nbsp;  | &nbsp;  */}
                          {/* <p style={{fontSize:15}}> <FaRegEnvelopeOpen />  demo@mail.com</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* Shipping Address */}
                      <div>
                        <label className="mb-0"><strong>Billing Address</strong> </label> 
                        <p className="mb-0">Address Type : <span > 
                          {(orderDetails && orderDetails.billingAddress.address_type == 'O') ? 
                          <Badge bg="secondary"> Office</Badge> : 
                          <Badge bg="secondary"> Home</Badge> }</span></p>

                          <p className="mb-0" style={{fontSize:15}}><FaRegPaperPlane /> {orderDetails && orderDetails.billingAddress.address}, {orderDetails && orderDetails.billingAddress.street} {orderDetails && orderDetails.billingAddress.land_mark} {orderDetails && orderDetails.billingAddress.city} - {orderDetails && orderDetails.billingAddress.pin}, {orderDetails && orderDetails.billingAddress.state}</p>

                          <div className="d-flex ">
                            <p style={{fontSize:15}}> <FaPhoneAlt /> +91 {orderDetails && orderDetails.billingAddress.mobile}</p> 
                            {/* &nbsp;  | &nbsp; 
                            <p style={{fontSize:15}}> <FaRegEnvelopeOpen />  demo@mail.com</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>

              {/* Past Order */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>All Items</h5>
                  </div>
                  <div>
                    <Table responsive="sm">
                        <thead>
                            <tr>
                                <th>Order Id</th>
                                <th >Product Name</th>
                                <th >Brand</th>
                                <th >Quantity</th>
                                <th >MRP</th>
                                <th >Price</th>
                                <th >Discount</th>
                            </tr>
                        </thead>
                        <tbody>
                          {orderDetailsProduct && orderDetailsProduct.map((item,index)=>{
                            return(
                              <tr key={index}>
                              <td>{item.unique_id} </td>
                              <td className="d-flex align-items-center">
                                 <TableThumbImage 
                                 image={item.product_image} className="table-img" /> 
                                 <span >{item && item.product_name}</span> </td>
                              <td style={{verticalAlign: 'middle'}}>{item && item.brand}</td>
                              <td style={{verticalAlign: 'middle'}}>{item && item.quantity}</td>
                              <td style={{verticalAlign: 'middle'}}>₹ {item && item.mrp}</td>
                              <td className="text-right" style={{verticalAlign: 'middle'}}>₹ {item.price}</td>
                              {/* <td className="text-right" style={{verticalAlign: 'middle'}}>{(parseInt(item && item.price)/(item && item.mrp) * parseInt(100)).toFixed(2)}%</td> */}
                              <td className="text-right" style={{verticalAlign: 'middle'}}>
                              {(100 * ((item && item.mrp) - (item && item.price))/(item && item.mrp)).toFixed(2)}%
                                </td>
                          </tr>
                            )
                          })}
                          
                            
                        </tbody>
                    </Table>
                  </div>                                                                                                             
                  {/* <ChangeStatusModal show={show} handleClose={handleClose} /> */}

                  <div className="row justify-content-end">
                    <div className="col-md-4">
                      <Table bordered variant="light">
                        <tbody className="text-right">
                          <tr>
                            <td>Sub Total</td>
                            <td>₹ {orderDetails && orderDetails.total_price}</td>
                          </tr>
                          <tr>
                            <td>Shipping Charge </td>
                            <td>₹ {orderDetails && orderDetails.shipping_charge}</td>
                          </tr>
                          <tr>
                            <td><strong>Grand Total</strong></td>
                            <td><strong>₹ {parseInt(orderDetails && orderDetails.total_price)+parseInt(orderDetails && orderDetails.shipping_charge)}</strong></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </>
      }
    </div>

  );
}

export default OrderSingle;
const styles = {
  header: {textTransform: 'uppercase',color:'rgb(249 188 2)',marginBottom:0},
  box : {background:'#e1e1e1',borderRadius:10, paddingLeft:10,paddingRight:10,padding:10,flex:1,boxShadow: '0 0 5px 0 #868686',border: '2px dotted #fff'},
  boxHeading : {color:'#333',fontWeight:'900',fontSize:15,marginBottom:0},
  boxNumber : {color:'green',fontWeight:'900',fontSize:25,marginBottom:0},
}
