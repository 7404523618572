import React, { useEffect, useState,useContext } from "react";
import { Outlet, Link ,useNavigate} from "react-router-dom";
import { AiOutlinePullRequest, AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import Loader from '../../component/loader';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AddCategory from './ProductComponent/addCategory';
import ProductInformation from './ProductComponent/productInformation';
import ProductPrice from './ProductComponent/productPrice';
import Filter from './ProductComponent/filter';
import { Modal, Button, Table} from 'react-bootstrap';
import OrderStatus from './orderStatus';
import axios from 'axios';
import Cookies from 'js-cookie';
import {decrpt} from '../../component/encryption';
import Pagination from "react-js-pagination";
import NotApprove from '../../component/notApprove';
import  RegistrationStatus  from "../../context/RegistrationStatus";



function ProductList() {
    const navigate = useNavigate();
    const profile = useContext(RegistrationStatus);
    // console.log(profile,'Context Profile Status on product page');
    const [filterError, setFilterError ] = useState();
    const [filterMessage, setFilterMessage ] = useState();

    //multiple product price model
    const [showMultiProduct, setShowMultiProduct] = useState(false);
    const [showMultiProductUpdate, setShowMultiProductUpdate] = useState(false);

    //add filter modal
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [showFilterProductUpdate, setShowMFilterProductUpdate] = useState(false);
    
    //add filter List
    const [filterList, setFilterList] = useState('');
    //model
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    //loader
    const [loader, setLoader] = useState(false)

    //tab key
    const [key, setKey] = useState('category');

    //show product list
    const [productShow, setProductShow] = useState();
    // console.log(productShow,'productShow');

    //product list btn change ststus
    const [selectedStatus, setSelectedStatus] = useState();

    //1st category data
    const [categoryData, setCategoryData] = useState('');

    //2nd category data
    const [subCategoryData, setSubCategoryData] = useState('');

    //3rdt category data
    const [lastCategoryData, setLastCategoryData] = useState('');

    //footer btn enable
    const [done, setDone] = useState(false);

    // Brand Array
    const [brandData, setBrandData] = useState('');

    //product size array
    const [productSize, setProductSize] = useState('');
    // console.log(productSize,'size');

    //product filter array
    const [productFilter, setProductFilter] = useState('');
    // console.log(productFilter,'productFilter');

    //product filter array
    const [productFilterValue, setProductFilterValue] = useState('');
    // console.log(productFilterValue,'productFilterValue');

    //select size option (many or one size,radio btn)
    const [sizeOption, setSizeOption] = useState('1');
    //  console.log(sizeOption,'sizeOption');

    //return policy check box list array
    const [policyList,SetPolicyList]=useState();
    // console.log(policyList,'policyList data');

    //return day option (yes or No,radio btn )
    const [returnDay, setReturnDay] = useState('1');

    //product ID
    const [productID,setProductID]=useState('');
    // console.log(productID,'productID');

    const [sizeID,setSizeID]=useState('');

    //product price and size list show
    const [multiProductList,setMultiProductList]=useState([]);

    //pagination
    const [paginate , setPaginate] = useState();

    //product price commision 
    const [taxTable, setTaxTable] = useState(false);
    
    const [tax, setTax] = useState();
    
    const [adminCommission, setAdminCommission] = useState();
    
    const [dataTax,setDataTax]=useState();
    const [filePreview , setFilePreview] = useState();
    const [ errors , setErrors ] = useState();
    // console.log(dataTax,"dataTax");


    // Temporary Image
    const [file, setFile] = useState([]);

    // Image Add Function
    const handleChange = async(e) => {
        // console.log('new',e.target.files);
        // setLoader(true); 
        // let fileObj = e.target.files
        // let image1 = file
        // let orginalImage = addProductData.image
        // for (let i = 0; i < fileObj.length; i++) {
        //     // console.log({ image: URL.createObjectURL(e.target.files[i]) })
        //     image1.push({ image: URL.createObjectURL(e.target.files[i]) })
        //     orginalImage.push({
        //         path: URL.createObjectURL(e.target.files[i]),
        //         name: e.target.files[i].name,
        //         size: e.target.files[i].size,
        //         type: e.target.files[i].type,
        //     });
        // }
        // console.log('Current Image',image1);
        // await setFile(image1);
        // await setAddProductData({...addProductData, image:orginalImage});
            setAddProductData({...addProductData, image:e.target.files[0]});
        setFilePreview({...filePreview, image:URL.createObjectURL(e.target.files[0])});
        console.log(filePreview && filePreview, 'for final check')
        setLoader(false); 
    };  

    //add product category and information
    const[addProductData, setAddProductData]=useState({
        category_id:'',
        sub_category_id:'',
        third_category_id:'',
        brand_id:'',
        product_name:'',
        short_description:'',
        long_description:'',
        weight:'',
        height:'',
        width:'',
        is_policy:'2',
        return_day:'',
        image:'',
        return_policy:'',
    });
    console.log('Product Added',addProductData.main_image)
    
    const AddProductData= async() => {
        setLoader(true); 
        try {
            // make Form Data
            const formData = new FormData();
            formData.append("category_id", addProductData.category_id);
            formData.append("sub_category_id", addProductData.sub_category_id);
            formData.append("third_category_id", addProductData.third_category_id);
            formData.append("brand_id", addProductData.brand_id);
            formData.append("product_name", addProductData.product_name);
            formData.append("short_description", addProductData.short_description);
            formData.append("long_description", addProductData.long_description);
            formData.append("weight", addProductData.weight);
            formData.append("height", addProductData.height);
            formData.append("width", addProductData.width);
            formData.append("is_policy", addProductData.is_policy);
            formData.append("return_day", addProductData.return_day);
            formData.append("image", addProductData.image);
            formData.append("return_policy", addProductData.return_policy);
            for (const value of formData.values()) {
                console.log(value , 'chake vvalue main_image');
              }
            let token = decrpt(Cookies.get('s_l'));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(`/seller/product/add`,formData);
            console.log(response.data,'addProductData response');
            if (response.data.success) {
                await setProductPrice({...productPrice,product_id:response.data.product_id});
                await setData({...data,product_id:response.data.product_id});
                await setProductAddFilter({...productAddFilter,product_id:response.data.product_id});
                if(sizeOption == '1') {
                    multiProductFetchList();
                }
                filterFetchList();                
                DisableHandler2();
                commisionApi(addProductData.third_category_id);
                setLoader(false); 
            } else {
                setLoader(false); 
            }
            
        } catch (error) {
            console.log(error);
            setLoader(false); 
            setErrors(error.response.data.errors)
        }
    }

    const onchangeSizeOption=(sizeType)=>{
        setSizeOption(sizeType)
        setProductPrice({
            ...productPrice,
            type:sizeType,
        })
    }

    //add product size and price
    const [productPrice,setProductPrice] = useState ({
        product_id: '',
        stock:'',
        mrp:'',
        price:'',
        size:'',
        size_name:'',
        type: sizeOption,
        product_size_id:'',

    })
    // console.log('productPrice',productPrice);

    // Open Add price Modal
    const openAddPriceModal = () => {
        setProductPrice({
            product_size_id : '',
            stock: '',
            mrp: '',
            price: '',
            size: '',
            product_id: data.product_id,
            type: sizeOption,
        });
        setShowMultiProduct(true)
    }

    //product price and size list show
    const addproductPrice=async()=>{ 
        setLoader(true);    
        try {
            let token = decrpt(Cookies.get('s_l'));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(`/seller/product/size/add`,productPrice);
            console.log(response.data,'addProductPrice response');

            if (response.data.success) {
                // DisableHandler3();
                const data=response.data.data
                if (sizeOption && sizeOption == '1') {
                    DisableHandler3();
                }else if (sizeOption && sizeOption == '2'){
                    // let product = multiProductList;
                    // product.push(productPrice);
                    // await setMultiProductList(product);
                    await multiProductFetchList();
                    setShowMultiProduct(false);
                } else{
                    setLoader(false); 
                }
                setLoader(false); 
            } 
            setLoader(false); 
        } catch (error) {
            console.log(error);    
            setLoader(false);       
        }
    }

    const multiProductUpdateHandle = (item) => {
        // console.log(item,'item');
        setProductPrice({
            ...productPrice,
            product_size_id  : item.id ,
            stock:item.stock,
            mrp:item.mrp,
            price:item.price,
            size:item.size_id,
            product_id: data.product_id,
            type: sizeOption,
        });
        setShowMultiProductUpdate(true);
    }
    
    // update product size and price
    const UpdateproductMulti=async()=>{ 
        setLoader(true); 
        try {
            let token = decrpt(Cookies.get('s_l'));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(`/seller/product/size/add`,productPrice);
            console.log(response.data,'UpdateproductPrice response');
            if (response.data.success) {
                await multiProductFetchList();
                setShowMultiProductUpdate(false);
                setLoader(false); 
            } 
            
        } catch (error) {
            console.log(error);    
            setLoader(false);       
        }
    }    

    const [data,setData]=useState({
        product_id: productID
    });
    
    const multiProductFetchList = async()=>{
        setLoader(true); 
        try {
            let token = decrpt(Cookies.get('s_l'));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(`seller/product/size/fetch`,data);
             console.log(response.data.data,'multiProductFetchList respons');
            if (response.data.success) {
                setMultiProductList(response.data.data.list);
            }
            setLoader(false); 
        } catch (error) {
            console.log(error,'error');
            setLoader(false); 
        }
    }

    // product fetch Api
    const fetchProductList = async (status) =>{
        setLoader(true); 
        const data = {
            status: status,
        }
        try {
         let token = decrpt(Cookies.get('s_l'));
        // let user = Cookies.get('username');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/seller/product/list`,data)
        if (response.data.data.list) {
            console.log('fetchProductList', response.data.data.list);
            setProductShow(response.data.data.list);
            setPaginate(response.data.data.pagination);
            if(profile.profile === '5' || profile.profile === '6' ){
                setLoader(false);
            }
        }
        } catch (error) {
            // console.log('Error',error.response);
            setLoader(false);
        }
    }

    //list btn status change
    const statusTypeHandler = (status) => {
        fetchProductList(status);
        setSelectedStatus(status);
        console.log(status,'status');
    }

    //1st Category data fetch api
    const fetchCategory = async () => {
        setLoader(true);
        try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const response = await axios.get(`/seller/category/list/dropdown`);
          if (response.data){
            setCategoryData(response.data.data);
            //  console.log('category',response.data.data);
            setLoader(false);
          } else{
            setLoader(false);
          }
        }catch (error) {
          console.log(error);
          setLoader(false);
        }
      };

    //2nd subCategory data fetch api
    const fetchSubCategory = async (category_id) => {
        setLoader(true);
       
        let data ={
            category_id:category_id,
        }
        try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const response = await axios.post(`/seller/category/sub/category/list/dropdown`,data);
          if (response.data.success){
            setSubCategoryData(response.data.data);
            // console.log('subcategory',response.data.data);
            setLoader(false);
          } else{
            setLoader(false);
          }
        }catch (error) {
          console.log(error);
          setLoader(false);
        }
  
    };

    //3rd lastCategory data fetch api
    const fetchLastCategory = async (category_id) => {
        setLoader(true);
  
        let data ={
            category_id:category_id,
        }
        // console.log(category_id,"data");
        try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(`/seller/category/sub/category/list/dropdown`,data);
            if (response.data.success){
            setLastCategoryData(response.data.data);
            // console.log('lastcategory',response.data.data);
            setLoader(false);
            } else{
            setLoader(false);
            }
        }catch (error) {
            console.log(error);
            setLoader(false);
        }
    };
    
    //fetch brand api
    const fetchBrandData = async (third_category_id) => {
        setLoader(true);
        console.log(third_category_id,'thirdCategoryId')
        try {
            let token = decrpt(Cookies.get('s_l'));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(`/seller/product/brand/size/filter/fetch`,{category_id:third_category_id});
            console.log(response.data,'response fetch filter');
            if (response.data.success){
                console.log(response.data.data,'brand name');
                setBrandData(response.data.data.brand_list)
                setProductSize(response.data.data.size_list)
                setProductFilter(response.data.data.filter_list)
                setLoader(false);
            } else{
                setLoader(false);
            }
        }catch (error) {
            console.log(error);
            setLoader(false);
        }
    };
   
    //product return policy list(check box)
    const fetchPolicyList = async()=>{
        setLoader(true);
        try {
            let token = decrpt(Cookies.get('s_l'));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.get(`/seller/return/policy/list`);
            
            if (response.data.success) {
                SetPolicyList(response.data.data);
                setLoader(false);
            }
        } catch (error) {
            console.log(error);
            setLoader(false);
        }
    }

    //add product model open
    const openAddModel=()=>{
        setShow(true)
        setKey('category')
        resetInputvalue();
    }

    //reset all input model open
    const resetInputvalue=()=>{
        setSubCategoryData('')
        setLastCategoryData('')
        setDone('')
        // setThirdCategoryId('')
        setBrandData('')
        setProductSize('')
        // setSizeOption('')
        setReturnDay('')
    }

    //submit btn Disable enable
    const [isDisabled1, setDisableButtons1] = useState(true);
    const DisableHandler1 = (() => {
        setDisableButtons1(false);
        setKey('information');
    })

    const [isDisabled2, setDisableButtons2] = useState(true);
    const DisableHandler2 = (() => {
        setDisableButtons2(false);
        setKey('price');
    })

    const [isDisabled3, setDisableButtons3] = useState(true);
    const DisableHandler3 = (() => {
        setDisableButtons3(false);
        setKey('filter');
    })
    
    // back button information
    const backBtnHandle=(tabindex)=>{
        setKey('category');
    }

    // back button price
    const backBtnHandleInfo=(tabindex)=>{
        setKey('information');
    }

    // pagination
    const handlePageChange = async (pageNumber) => {
        setLoader(true);
        console.log(`active page is ${pageNumber}`);
        try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/seller/product/list?page=${pageNumber}`)
        console.log(response,'pageNumber');
        if(response.data.success) {
            setProductShow(response.data.data.list);
            setLoader(false);
        }else{
            setLoader(false);
        }
        } catch (error) {
        console.log(error);
        setLoader(false);
        }
    }

    //product price commision 
    const commisionApi = async (third_category_id) => {
        setLoader(true);
        let data ={
            category_id:addProductData.third_category_id,
        }
        console.log(data,'commisionApi third')
        try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(`/seller/tax_commissions/fetch/details`,data);
            console.log(response,'response');
            if (response.data.success){
            setDataTax(response.data.data);
            // console.log('commisionApi',response.data.data);
              taxCal();
              adminCom()
            setLoader(false);
            } else{
            setLoader(false);
            }
        }catch (error) {
            console.log(error);
            setLoader(false);
        }
    
    };

    const openTaxTable = (e) =>{
        console.log(e,'e');
        if (e.length > 0){
            setTaxTable(true);
        } else{
            setTaxTable(false);
        }
        discountCal(e);
        taxCal();
        adminCom();
    }

    const discountCal = (e) => {
        const discount1 = parseFloat((productPrice.mrp - e)) / parseFloat(productPrice.mrp);
        const discount = parseFloat(discount1 * 100 );
        // console.log('Discount',discount);
        setProductPrice({...productPrice,discount:discount,price:e})
    }

    const taxCal = () => {
        // Api Call for tax
        const discounttax = parseFloat(dataTax && dataTax.tax/100) * parseFloat(productPrice.price);
        setTax(discounttax.toFixed(2));
    }

    const adminCom = () => {
        // Api Call for tax
        const adminCommission = parseFloat(dataTax && dataTax.commission/100) * parseFloat(productPrice.price);
        setAdminCommission(adminCommission.toFixed(2));
    }

    const handleFilter=(item)=>{
        console.log(item,'filter data');
    
        let tmparray = productFilter;
        let obj = tmparray.find((o, i) => {
            // console.log(o,'ooo');
            if (o.filter_master_id == item) {
                setProductFilterValue(o.values);
            }
        });
        setProductAddFilter({...productAddFilter, filter_master_id:item})
    }

    const handleFilterDetails=(item)=>{
        setProductAddFilter({...productAddFilter, filter_master_id:item})
        setProductAddFilter({...productAddFilter, filter_master_details_id:item})
    }

    //add product filter
    const [productAddFilter,setProductAddFilter] = useState ({
        product_filter_id:'',
        product_id: '',
        filter_master_id:'',
        filter_master_details_id:'',
    })
    // console.log(productAddFilter,'productFilterAdd');

    // Open Add Filter Modal
    const openFilterModal = () => {
        setProductAddFilter({
            product_filter_id:'',
            product_id: data.product_id,
            filter_master_id:'',
            filter_master_details_id:'',
        });
        setShowAddFilter(true)
    }
    console.log(productAddFilter && productAddFilter , 'productAddFilter ds')

    const addProductFilter=async()=>{ 
        setLoader(true);    
        try {
            let token = decrpt(Cookies.get('s_l'));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(`/seller/product/filter/add`,productAddFilter);
            // console.log(response.data,'addProductFilter response');
            if (response.data.success) {
                setShowAddFilter(false);
                await filterFetchList();
                setLoader(false); 
            } 
        }catch (error) {
            console.log(error);    
            setLoader(false); 
            setFilterMessage(error.response.data.message)      
        }
    }

    const filterProductUpdateHandle = async(item) => {
        console.log(item,'productFilterItem');
        await setProductAddFilter({
            product_filter_id:item.id,
            product_id:data.product_id,
            filter_master_id:item.main_category_id,
            filter_master_details_id:item.sub_category_id,        
        });
        setShowMFilterProductUpdate(true);
    }

    const UpdateproductFilter=async()=>{ 
        setLoader(true); 
        try {
            let token = decrpt(Cookies.get('s_l'));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(`/seller/product/filter/add`,productAddFilter);
            console.log(response.data,'UpdateproductPrice response');
            if (response.data.success) {
                setShowMFilterProductUpdate(false);
                await filterFetchList();
                setLoader(false);                    
            } 
            setLoader(false); 
        } catch (error) {
            console.log(error);    
            setLoader(false);       
        }
    }

    const filterFetchList = async()=>{
        setLoader(true); 
        try {
            let token = decrpt(Cookies.get('s_l'));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const data ={product_id : productAddFilter?.product_id}
            const response = await axios.post(`seller/product/filter/fetch` , data);
            // console.log(response.data.data,'filterFetchList respons');
            if (response.data.success) {
                setFilterList(response.data.data.list);
                
            }
            setLoader(false); 
        } catch (error) {
            console.log(error,'error');
            setLoader(false); 
        }
    }

    // const imageUploadProduct = async() => {
    //     try {
    //         const data = {
    //             product_id : '' ,
    //             image : ''
    //         }
    //         let token = decrpt(Cookies.get('s_l'));
    //         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    //         const response = await axios.post(`seller/product/image/upload`,data);
    //     }catch{

    //     }
    // }

    // view details
    const viewDetails =(item)=> {
        navigate('./single' , {state : {item}});
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchCategory();
        fetchProductList();
        fetchPolicyList();
        // filterFetchList();
        // commisionApi();
        // taxCal();
        // adminCom();

    }, []);

    return (
        <>
         {/* New Order */}
         {/* {loader ? <Loader />
            : */}
            <div className="responsive-xs responsive-sm">
            { profile && profile.profile == 5  ?
                <NotApprove/> :
                    <>
                            {/* Count */}
                            <div className="count-section d-flex justify-content-between">
                                {/* count 1 */}
                                <button className="product-page-block d-flex align-items-center" onClick={() => statusTypeHandler(3)} style={selectedStatus == 3 ? styles.activeBtn : null}>
                                    <div className="product-page-inner-block">
                                        <h5 style={selectedStatus == 3 ? styles.activeBtnTxt : null}><AiOutlinePullRequest /> Requested</h5>
                                    </div>
                                </button>
                                {/* count 2 */}
                                <button className="product-page-block d-flex align-items-center" onClick={() => statusTypeHandler(1)} style={selectedStatus == 1 ? styles.activeBtn : null}>
                                    <div className="product-page-inner-block">
                                        <h5 style={selectedStatus == 1 ? styles.activeBtnTxt : null}><AiOutlineCheckCircle /> Approved</h5>
                                    </div>
                                </button>
                                {/* count 3 */}
                                <button className="product-page-block d-flex align-items-center" onClick={() => statusTypeHandler(4)} style={selectedStatus == 4 ? styles.activeBtn : null}>
                                    <div className="product-page-inner-block">
                                        <h5 style={selectedStatus == 4 ? styles.activeBtnTxt : null}><AiOutlineCloseCircle /> Rejected</h5>
                                    </div>
                                </button>
                                <button className="product-page-block d-flex align-items-center " onClick={() => openAddModel()}>
                                    <div className="product-page-inner-block">
                                        <h5 ><AiOutlinePlusCircle /> Add Product</h5>
                                    </div>
                                </button>
                            </div>
                                <>
                                    <div className="section">
                                        <div className="row align-items-center">
                                            <div className="col-md-12">
                                                <div className="card p-2">
                                                    <div className="card-title d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h5>New Order</h5>
                                                        </div>
                                                        {/* <div>
                                                        <Link to='./productSearch' className="btn btn-sm btn-warning" >Search</Link>
                                                        </div> */}
                                                    </div>
                                                    <Table responsive="sm">
                                                        <thead>
                                                            <tr className="text-center">
                                                                <th>Product ID</th>
                                                                <th>Product Name</th>
                                                                <th>Category</th>
                                                                <th>Sub category</th>
                                                                <th>Brand</th>
                                                                <th>Order Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{verticalAlign: 'middle'}} className="text-left">
                                                            {productShow && productShow.length > 0 ?
                                                                <>
                                                                    {productShow && productShow.map((item, index) => {
                                                                        return (
                                                                            <tr key={index} >
                                                                                <td>P0{item.id} </td>
                                                                                <td><img src={item.main_image} className="product-table-img"/>{item.name}</td>
                                                                                <td>{item.category}</td>
                                                                                <td>{item.sub_category}</td>
                                                                                <td>{item.brand}</td>
                                                                                <td>
                                                                                    <OrderStatus delivery_status={item.status}/>
                                                                                </td>
                                                                                <td>
                                                                                <button type="button" className='btn btn-xs btn-success text-white table-btn'  onClick={()=> viewDetails(item.id) }>View</button></td>
                                                                            </tr>
                                                                        );
                                                                    })}</>
                                                                :
                                                                <tr>
                                                                    <td colSpan={5}>No Product Found</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                    { productShow && productShow.length > 0 ?
                                                    <>
                                                        <div className="col-md-12">
                                                            <Pagination
                                                            totalItemsCount={ paginate && paginate.total_data}
                                                            onChange={handlePageChange}
                                                            activePage={  paginate && paginate.current_page}
                                                            itemsCountPerPage={paginate && paginate.per_page}
                                                            pageRangeDisplayed={10}
                                                            />
                                                        </div>  
                                                    </>: <></>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                           
                            {/* Add product modal */}
                            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                            {loader && <Loader />}
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Product</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="tab-block-main">
                                    <Tabs
                                        activeKey={key}
                                        // onSelect={(k) => setKey(k)}
                                        onSelect={(k) => setKey(k)}
                                        id="noanim-tab-example"
                                        className="mb-3 justify-content-around tab-block product-add">
                                        <Tab eventKey="category" title="Category" className="flex-grow-1">
                                            <AddCategory categoryData={categoryData} 
                                                subCategoryData={subCategoryData}  
                                                fetchSubCategory={fetchSubCategory} 
                                                fetchLastCategory={fetchLastCategory} 
                                                lastCategoryData={lastCategoryData}  
                                                done={done} setDone={setDone} 
                                                // setThirdCategoryId={setThirdCategoryId} 
                                                fetchBrandData={fetchBrandData}
                                                DisableHandler1={DisableHandler1}
                                                addProductData={addProductData}
                                                setAddProductData={setAddProductData}
                                                />
                                        </Tab>
                                        <Tab eventKey="information" title="Product Information" className="flex-grow-1" disabled={isDisabled1}>
                                            <ProductInformation 
                                                brandData = {brandData}
                                                fetchBrandData={fetchBrandData}
                                                // thirdCategoryId={thirdCategoryId}
                                                returnDay={returnDay}
                                                setReturnDay={setReturnDay}
                                                sizeOption={sizeOption}
                                                // setSizeOption={setSizeOption}
                                                onchangeSizeOption={onchangeSizeOption}
                                                policyList={policyList}
                                                DisableHandler2={DisableHandler2}
                                                backBtnHandle={backBtnHandle}
                                                addProductData={addProductData}
                                                setAddProductData={setAddProductData}
                                                AddProductData={AddProductData}
                                                productID={productID}
                                                sizeID={sizeID}
                                                handleChange={handleChange}
                                                file={file}
                                                setFile={setFile}
                                                errors = {errors}
                                                filePreview={filePreview}
                                                setFilePreview = {setFilePreview}
                                            />
                                        </Tab>
                                        <Tab eventKey="price" title="Product Price & Stock" className="flex-grow-1" disabled={isDisabled2}>
                                            <ProductPrice 
                                                setProductSize = {setProductSize}
                                                productSize = {productSize}
                                                sizeOption={sizeOption}
                                                DisableHandler3={DisableHandler3}
                                                backBtnHandleInfo={backBtnHandleInfo}
                                                productID={productID}
                                                sizeID={sizeID}
                                                productPrice={productPrice}
                                                setProductPrice={setProductPrice}
                                                addproductPrice={addproductPrice}
                                                taxTable={taxTable}
                                                setTaxTable={setTaxTable}
                                                tax={tax}
                                                setTax={setTax}
                                                adminCommission={adminCommission}
                                                setAdminCommission={setAdminCommission}
                                                openTaxTable={openTaxTable}
                                                discountCal={discountCal}
                                                taxCal={taxCal}
                                                adminCom={adminCom}
                                                commisionApi={commisionApi}
                                                addProductData={addProductData}
                                                setAddProductData={setAddProductData}
                                                showMultiProduct={showMultiProduct}
                                                setShowMultiProduct={setShowMultiProduct}
                                                // multiProductFetchList={multiProductFetchList}
                                                multiProductList={multiProductList}
                                                UpdateproductMulti={UpdateproductMulti}
                                                showMultiProductUpdate={showMultiProductUpdate}
                                                setShowMultiProductUpdate={setShowMultiProductUpdate}
                                                multiProductUpdateHandle={multiProductUpdateHandle}
                                                openAddPriceModal={openAddPriceModal}

                                            />
                                        </Tab>
                                        <Tab eventKey="filter" title="Filter" className="flex-grow-1" disabled={isDisabled3}>
                                            <Filter
                                                productFilter={productFilter}
                                                setProductFilter={setProductFilter}
                                                handleFilter={handleFilter}
                                                productFilterValue={productFilterValue}
                                                showAddFilter={showAddFilter}
                                                setShowAddFilter={setShowAddFilter}
                                                addProductFilter={addProductFilter}
                                                productAddFilter={productAddFilter}
                                                setProductAddFilter={setProductAddFilter}
                                                handleFilterDetails={handleFilterDetails}
                                                filterList={filterList}
                                                setFilterList={filterList}
                                                filterProductUpdateHandle={filterProductUpdateHandle}
                                                showFilterProductUpdate={showFilterProductUpdate}
                                                setShowMFilterProductUpdate={setShowMFilterProductUpdate}
                                                UpdateproductFilter={UpdateproductFilter}
                                                openFilterModal={openFilterModal}
                                                setShow={setShow}
                                                filterMessage={filterMessage}
                                            />
                                        </Tab>
                                    </Tabs>

                                </Modal.Body>
                                {/* {isDisabled1 == true ?
                                <>
                                    <div className="product-Modal-footer" >
                                        <button type="button" disabled={!done} className="btn btn-primary product-modal-saveBtn" onClick={() => DisableHandler1()}>Save & Continue</button>
                                    </div>
                                </>
                                
                                    : isDisabled2 == true ?
                                    <>
                                
                                        <div className="product-Modal-footer" >
                                            <button type="button" className="btn btn-primary product-modal-saveBtn" onClick={() =>backBtnHandle()}>Back</button>

                                            <button type="button" className="btn btn-primary product-modal-saveBtn" onClick={() => DisableHandler2()} >Save & Continue</button>
                                        </div>
                                    
                                        </>
                                        : isDisabled3 == true ?
                                        <>
                                        
                                        <div className="product-Modal-footer" >
                                            <button type="button" className="btn btn-primary product-modal-saveBtn" onClick={() =>backBtnHandle()}>Back</button>

                                            <button type="button" className="btn btn-primary product-modal-saveBtn" onClick={() => DisableHandler3()} >Save & Continue</button>
                                        </div>
                                        
                                        </>
                                        :
                                        <div className="product-Modal-footer" >
                                            <button type="button" className="btn btn-primary product-modal-saveBtn">Close</button>
                                        </div>
                                } */}
                                </Modal>
                            <Outlet />
                            </>
                            }
            </div>
             {/* } */}
        </>
    );
}

export default ProductList;
const styles = {
    activeTable: { disply: 'table-row' },
    inActiveTable: { display: 'none' },
    activeBtn: { backgroundColor: '#ffc001ad' },
    activeBtnTxt: { color: '#000' },
}; 