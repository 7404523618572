import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

const NewPassword = () => {
  return (
    <div>
      <div className="container register-block">
        <div style={{ textAlign: "center" }}>
          <img src="./logo.webp" style={{ width: 130 }} />
        </div>
        <div className="col-md-12 mt-3">
          <div className="col-md-4 mx-auto">
            <div className="loginFormBlock">
              <div>
                <h4>Create New password</h4>
                <p style={{fontSize:13}}>We'll ask for this password whenever you sign in.</p>
              </div>
              <form className="row ">
                <div className="col-md-12">
                  <label className="form-label label-txt">Your name</label>
                  <input type="text" className="form-control " />
                </div>
                <div className="col-md-12 mt-1">
                  <label className="form-label label-txt col-md-7">
                    Re-enter Password
                  </label>
                  <input type="email" className="form-control" />
                  <p style={{ fontSize: 12, marginTop: 5 }}>
                    ! Passwords must be at least 6 characters.
                  </p>
                </div>
                <Link
                  to="/login"
                  style={{ textDecorationLine: "none" }}
                >
                  <div className="col-md-12 mt-1 text-center signIn-btn">
                    <p className="">Save changes and sign in</p>
                  </div>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default NewPassword;
