import React, { useEffect, useState } from "react";
import BasicInfo from '../profile/component/basicInfo';
import Address from '../profile/component/address';
import BankDetails from '../profile/component/bankDetails';
import Document from '../profile/component/document';
import PickUp from '../profile/component/pickUpLocation';
import Loader from '../../component/loader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {decrpt} from '../../component/encryption';

const ProfileDetails = () => {
    const [sellerProfile , setSellerProfile] = useState()
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false)
    const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const statusModal = () => {setShow(true);};
    const [pickupError , setPickupError] = useState();
    const [pickUpModal , setPickUpModel] = useState();
    const[fetchStates,setFetchStates] = useState();
    const[ fetchCities, setFetchCities] = useState();
    const [basicInfoModal , setBasicInfoModal] = useState(false);

    const[basicUpdate,setBasicUpdate] = useState({
        name       :  '',
        email      :  '',
        phone      :  '',
        alt_mobile :  '',
        gender     :  '',
        dob        :  ''
    });
    const [bankDetails, setBankDetails] = useState({
        bank_name   : '',
        branch      : '',
        ac_no       : '',
        ifsc        : '',
        name : ''
    });
    const [sellerDocument, setSellerDocument] = useState({
        pan_image       : '',
        trade_lic_image : '',
        gst_image       : '',
        pan             : '',
        trade_lic_no    : '',
        gst             : ''
    });
    console.log(sellerProfile, 'sellerProfile new chec jk' );
    const [ address , setAddress] = useState({
        state_id    : '',
        city_id     : '',
        state       : '',
        city        : '',
        pin         : '',
        street      : '',
        land_mark   : '',
        address     : ''
    });  
    const [filePreview, setFilePreview] = useState(
        {
            trade_lic_no : '' ,
            trade_lic_image:'' ,
            gst               : '',
            gst_image:'',
            pan       : '',
            pan_image:''
        }
    );
    const [bussinesDetails, setBussinesDetails ] = useState({
        business_name : '',
        state : '',
        city : '',
        district : '',
        pin : '',
        street : '',
        land_mark : '',
        address : '',
        mobile : '',
        alt_mobile : '',
        business_email : '',
        business_description : ''

    });

// console.log(sellerProfile , 'sellerProfile');

    const updateDocument = async(e) => {
        e.preventDefault();
        // setLoader(true);
        setShow(false);
        const formData = new FormData();
        formData.append("pan_image", sellerDocument.pan_image);
        formData.append("gst_image", sellerDocument.gst_image);
        formData.append("trade_lic_image", sellerDocument.trade_lic_image);
        formData.append("gst",sellerDocument.gst  );
        formData.append("trade_lic_no", sellerDocument.trade_lic_no);
        formData.append("pan",sellerDocument.pan  );
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/seller/profile/document/update`,  formData  )
        .then((response) => {
          console.log('update seller document' , response.data);
          if(response.data.success){
              console.log('formData', formData);
            setSellerDocument(response.data.data);
            setSellerProfile(response.data.data);
            setShow(false);
            // setLoader(false);
          }
        }).catch((err)=>{
          console.log('document update Error',err.response);
          setError(err.response.data.errors);
          setShow(false);
        //   setLoader(false);
      })
     }
    const updatePersonal = async(e) => {
        e.preventDefault();
        setLoader(true);
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/seller/profile/personal/details/update`, basicUpdate )
        .then((response) => {
        console.log('basic update' , response);
          if(response.data.success){
            setBasicUpdate(response.data.data);
            setSellerProfile(response.data.data);
            setLoader(false);
            setBasicInfoModal(false)
            setError(false)
          }
        }).catch((err)=>{
          console.error('Error',err.response);
          setError(err.response.data.errors);
          setLoader(false);
          setBasicInfoModal(true)
          console.log(error && error , 'cjeckjskdns')
      })
     }
     const updateBankDetails = async(e) => {
        e.preventDefault();
        setLoader(true);
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/seller/profile/bank/details/update`,bankDetails)
        .then((response) => {
            if(response.data.success) {
                setBankDetails(response.data.data);
                setSellerProfile(response.data.data);
                setLoader(false);
            }
        }).catch((err)=>{
            console.error('Error bank',err.response);
                setLoader(false);
        })
    }
    const fetchData = async() => {
        setLoader(true);
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.get(`/seller/profile` )
        .then((response) => {
            if(response.data.success){
                setSellerProfile(response.data.data);
                fetchCity(response.data.data.state_id);
                setLoader(false);
                console.log( response , 'seller profile');
        }
        }).catch((err)=>{
        console.error('Error',err.response.sellerProfile);
        setLoader(false);
    })
    }
    const updateAddress = async(e) => {
        e.preventDefault();
        setLoader(true);
        // console.log('address data',address)
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/seller/profile/address/update` ,address)
        .then((response) => {
        console.log('address updates' , response);
        if(response.data.success){
            setAddress(response.data.data);
            setSellerProfile(response.data.data);
            setLoader(false);
        }
        }).catch((err)=>{
        console.error('Error',err.response);
        setLoader(false);
    })
    }
    // 
    // fetch state and city
    const fetchState = async () => {
        try {
          const response = await axios.get(`seller/masters/state/list`);
          if (response.data.success){
            setFetchStates(response.data.data);
          }else{
  
          }
      } catch (error) {
          console.log( 'fetch state', error);
      }      
      };
   



    const fetchCity = async (state_id) => {
    try {
        const response = await axios.get(`/seller/masters/city/list/${state_id}`);
        if (response.data.success){
        setFetchCities(response.data.data);
        }
    }catch (error) {
        console.log(error);
    }
    };


    const fetchDistrict = async (state_id) => {
        console.log ('stateid', state_id);
        const data = {
          state_id :state_id
        }
        try {
          const response = await axios.post(`/seller/district/dropdown/list`, data);
          // console.log("district data",response.data);
            setFetchDistricts(response.data.data);
      } catch (error) {
          console.log(error);
      }      
      };

    const handleDistrict = ( district) => {
        console.log(district  , 'fetch districts id');
        props.setBussinesDetails({
            ...props.bussinesDetails,
          // state : state, 
          district : district
        })
        fetchCity( district);
      }
  

      const handleState = (state) => {
        setAddress({
          ...address,
          state : state, 
        })
        fetchCity(state);
      }
      console.log('basic update bussinesDetails' , bussinesDetails);




      const updateBussinessDetails = async() => {
        // e.preventDefault();
        setLoader(true);
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/seller/profile/business/details/update`, bussinesDetails )
        .then((response) => {
        console.log('basic update' , response);
        console.log('basic update bussinesDetails' , bussinesDetails);
          if(response.data.success){
            // setBasicUpdate(response.data.data);
            setBussinesDetails(response.data.data.business_details);
            setLoader(false);
            setPickUpModel(false);
        }
    }).catch((err)=>{
        console.error('Error',err.response);
        setLoader(false);
        setPickUpModel(true)
        setPickupError(err.response.data.errors)
    })
}
// console.log(bussinesDetails , 'bussinesDetails');



      useEffect(() => {
        fetchData();
        fetchState();
        fetchCity();
      }, []);
// fetch state and city
// 
    useEffect(() => {
        setAddress({
            ...address,
        state       : sellerProfile ? sellerProfile.state_id : '',
        city        : sellerProfile ? sellerProfile.city_id : '',
        pin         : sellerProfile ? sellerProfile.pin : '',
        street      : sellerProfile ? sellerProfile.street : '',
        land_mark   : sellerProfile ? sellerProfile.land_mark : '',
        address     : sellerProfile ? sellerProfile.address : ''
        })
    }, [sellerProfile]);
    useEffect(() => {
        setBasicUpdate({
            ...basicUpdate,
            name   : sellerProfile ? sellerProfile.name : '',
            email   : sellerProfile ? sellerProfile.email : '',
            phone   : sellerProfile ? sellerProfile.mobile : '',
            alt_mobile : sellerProfile ? sellerProfile.alt_mobile : '',
            gender     : sellerProfile ? sellerProfile.gender : '',
            dob        : sellerProfile ? sellerProfile.dob : '',
        })
    }, [sellerProfile]);
    useEffect(() => {
        setBankDetails({
            ...bankDetails,
                bank_name   : sellerProfile ? sellerProfile.bank_detail.bank_name : '',
                branch      : sellerProfile ? sellerProfile.bank_detail.branch    : '',
                ac_no       : sellerProfile ? sellerProfile.bank_detail.ac_no     : '',
                ifsc        : sellerProfile ? sellerProfile.bank_detail.ifsc      : '',
            name  : sellerProfile ? sellerProfile.bank_detail.name    : ''
        })
    },[sellerProfile]);

    useEffect(() => {
        setSellerDocument({
            ...sellerDocument,
            pan_image       : sellerProfile ? sellerProfile.pan_image       : '',
            trade_lic_image : sellerProfile ? sellerProfile.trade_lic_image : '',
            gst_image       : sellerProfile ? sellerProfile.gst_image       : '',
            pan             : sellerProfile ? sellerProfile.pan             : '',
            trade_lic_no    : sellerProfile ? sellerProfile.trade_lic_no    : '',
            gst             : sellerProfile ? sellerProfile.gst             : ''
        })
    },[sellerProfile]);

    // useEffect(() => {
    //     setBussinesDetails({
    //         ...bussinesDetails,
    //         mobile : sellerProfile && sellerProfile ? sellerProfile.mobile : '' 
    //     }) 
    // },[sellerProfile])
    
    useEffect(() => {
        setBussinesDetails({
            ...bussinesDetails,
            business_name : sellerProfile && sellerProfile ? sellerProfile.business_name : '' ,
            state : sellerProfile && sellerProfile ? sellerProfile.business_details.state : '' ,
            city : sellerProfile && sellerProfile ? sellerProfile.business_details.city : '' ,
            district : sellerProfile && sellerProfile ? sellerProfile.business_details.district : '' ,
            pin : sellerProfile && sellerProfile ? sellerProfile.business_details.pin : '' ,
            street : sellerProfile && sellerProfile ? sellerProfile.street : '' ,
            land_mark : sellerProfile && sellerProfile ? sellerProfile.land_mark : '' ,
            address : sellerProfile && sellerProfile ? sellerProfile.address : '' ,
            mobile : sellerProfile && sellerProfile ? sellerProfile.mobile : '' ,
            alt_mobile : sellerProfile && sellerProfile ? sellerProfile.alt_mobile : '' ,
            business_email : sellerProfile && sellerProfile ? sellerProfile.business_email : '' ,
            business_description : sellerProfile && sellerProfile ? sellerProfile.business_details.business_description : '' ,
            business_category : sellerProfile && sellerProfile ? sellerProfile.business_details.business_category : '' ,
            business_name : sellerProfile && sellerProfile ? sellerProfile.business_details.business_name : '' ,
        })
    },[sellerProfile])


    return (
        <div>
            {loader ?
                <Loader />
                :
                <>
                    <BasicInfo 
                        sellerProfile={sellerProfile}
                        basicUpdate={basicUpdate}
                        setBasicUpdate={setBasicUpdate}
                        updatePersonal={updatePersonal}
                        basicInfoModal={basicInfoModal}
                        setBasicInfoModal={setBasicInfoModal}
                        error={error}
                    />
                    <div className="section">
                        <div className="row">
                             <div className="col-md-12">
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <Address
                                            sellerProfile = {sellerProfile}
                                            address       = {address}
                                            setAddress    = {setAddress}
                                            updateAddress = {updateAddress}
                                            fetchStates   = {fetchStates}
                                            fetchCities    = {fetchCities}
                                            handleState     = {handleState}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <BankDetails 
                                            sellerProfile={sellerProfile}
                                            bankDetails={bankDetails}
                                            setBankDetails={setBankDetails}
                                            updateBankDetails={updateBankDetails}
                                            setShow={setShow}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <PickUp 
                                        sellerProfile =  { sellerProfile && sellerProfile.business_details}     
                                        updateBussinessDetails = {updateBussinessDetails}   
                                        bussinesDetails={bussinesDetails}           
                                        setBussinesDetails={setBussinesDetails}        
                                        pickUpModal={pickUpModal} 
                                         setPickUpModel={setPickUpModel}             
                                         pickupError={pickupError}
                                        />
                                    </div>
                                </div>
                                <Document
                                    sellerProfile={sellerProfile}
                                    sellerDocument={sellerDocument}
                                    setSellerDocument={setSellerDocument}
                                    updateDocument={updateDocument}
                                    setFilePreview={setFilePreview}
                                    filePreview={filePreview}
                                    error={error}
                                    setError ={setError}
                                    setShow={setShow}
                                    show={show}
                                   />
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default ProfileDetails;