import React, { useEffect, useState } from 'react';
import {BiEditAlt } from "react-icons/bi";
import {AiOutlinePlusCircle } from "react-icons/ai";
import { Modal, Button, Table} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const Filter = (props) => {
  // console.log(props,'filter props data');
   return (
        <div>
        <div className="container mt-4 mb-4">
          <form>
            <>
            <div className="section">
            <button type="button" className="saveChangesBtn btn btn-primary" onClick={()=> props.openFilterModal()} style={{boxShadow: '0 0 6px 0 #ffc0019e',fontWeight: 'bold'}}>Add Filter <AiOutlinePlusCircle style={{ color: '#fff', fontSize: 18 }} ></AiOutlinePlusCircle></button>
              <div className="row align-items-center">
                <div className="col-md-12 mt-4">
                  <div className="card p-2">
                    <div className="card-title d-flex align-items-center justify-content-between">
                      <div></div>
                    </div>
                    <Table responsive="sm">
                      <thead>
                        <tr className="text-center">
                          <th>Name</th>
                          <th>Details</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{verticalAlign: 'middle'}} className="text-center">
                      {props.filterList && props.filterList.length > 0 ?
                        <>
                        {props.filterList && props.filterList.map((item,index)=>{
                          return(
                            <tr key={index}>
                          <td>{item.main_category_name}</td>
                          <td>{item.sub_category_name}</td>
                          <td>
                            <button type="button" style={{border: 'none', background: 'transparent'}}>
                            <BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={()=> props.filterProductUpdateHandle(item)} ></BiEditAlt>
                            </button>
                          </td>
                          </tr>
                          )
                        })
                      }
                        </>:
                        <tr>
                          <td colSpan={5}>No Product Found</td>
                        </tr>
                        }
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          
          {/* add modal */}
          <Modal show={props.showAddFilter} onHide={()=> props.setShowAddFilter(false)}> <Modal.Header closeButton>
              <Modal.Title>Add Product Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {props.filterMessage && props.filterMessage ? <p style={{color : 'red' , fontSize : 12}}>{props.filterMessage}</p> : '' }
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Name</Form.Label>
                  <Form.Select aria-label="Default select example" onChange={(e) => 
                      {
                        e.preventDefault();
                        
                        props.handleFilter(e.target.value)
                        // props.setProductAddFilter({...props.productAddFilter, filter_master_id:e.target.value})
                      }}>
                        <option > Select Name</option>
                      {props.productFilter && props.productFilter.map((item,index)=>{
                        return(
                          <option key={index} value={item.filter_master_id}>{item.name}</option>
                        )
                      })}
                    </Form.Select>

                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Details</Form.Label>
                  <Form.Select aria-label="Default select example" onChange={(e) => 
                      {
                        e.preventDefault();
                        props.handleFilterDetails(e.target.value)
                        // props.setProductAddFilter({...props.productAddFilter, filter_master_details_id:e.target.value})
                      }}>
                        <option  > Select Name</option>
                      {props.productFilterValue && props.productFilterValue.map((item,index2)=>{
                        return(
                          <option key={index2} value={item.id}>{item.name}</option>
                               )
                      })}
                    </Form.Select>
                  </Form.Group>
                <Modal.Footer>
                  <Button variant="secondary" onClick={()=> props.setShowAddFilter(false)}> Close </Button>
                  <Button className="saveChangesBtn" type="button" onClick={()=> props.addProductFilter()} > Save Changes </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </Modal>

            {/* edit modal */}
            <Modal show={props.showFilterProductUpdate} onHide={()=> props.setShowMFilterProductUpdate(false)}> <Modal.Header closeButton>
              <Modal.Title>Edit Product Filter {props.productAddFilter && props.productAddFilter.filter_master_id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Name</Form.Label>
                  <Form.Select aria-label="Default select example" onChange={(e) => 
                      {
                        e.preventDefault();                        
                        props.handleFilter(e.target.value)
                        // props.setProductAddFilter({...props.productAddFilter, main_category_name:e.target.value})
                      }}
                      defaultValue={props.productAddFilter && props.productAddFilter.filter_master_id}>
                        <option disabled> Select Name</option>
                      {props.productFilter && props.productFilter.map((item,index)=>{
                        return(
                          <option key={index} value={item.filter_master_id}>{item.name}</option>
                        )
                      })}
                    </Form.Select>

                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Details</Form.Label>
                  <Form.Select aria-label="Default select example" onChange={(e) => 
                      {
                        e.preventDefault();
                        props.handleFilterDetails(e.target.value);
                        // props.setproductAddFilter({...props.productAddFilter, sub_category_name:e.target.value})
                      }}
                      defaultValue={props.productAddFilter && props.productAddFilter.filter_master_details_id}>
                      <option disabled>Select Details</option>
                      {props.productFilterValue && props.productFilterValue.map((item,index2)=>{
                        return(
                          <option key={index2} value={item.id}>{item.name}</option>
                          )
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Modal.Footer>
                  <Button variant="secondary" onClick={()=> props.setShowMFilterProductUpdate(false)}> Close </Button>
                  <Button className="saveChangesBtn" type="button" onClick={()=> props.UpdateproductFilter()} > Save Changes </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </Modal>
          </>

          </form>
        </div>
        <div className="product-Modal-footer" style={{position: 'fixed', width: '100%',left: 0,bottom: 0,
        marginLeft: 0,backgroundColor:'#fff'}}>
         <button type="button" className="btn btn-primary product-modal-saveBtn" >Close</button>
      </div>
      </div>
    );
};

export default Filter;