import React, { useEffect, useState, useContext } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import AuthUser from "../../context/AuthUser";
import Cookies from 'js-cookie';
import axios from 'axios';
import { encrpt } from "../../component/encryption";


const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const Auth = useContext(AuthUser);
  // console.log(Auth, 'auth');
  const [errors, setErrors] = useState(false);
  const [message, setMessage] = useState(false);
  const [data, setData] = useState({
    user_name: '',
    password: '',
  });

  const readCookie = () => {
    const token = Cookies.get('s_l');
    if (token && token.length > 15) {
      Auth.setAuth(true);
    }
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setMessage(false);
    setErrors(false);
    const response = await axios.post(`/seller/login`, data)
      .then((response) => {
        console.log('responces data', response.data);
        if (response.data.success) {
          Auth.setAuth(true);
          let token = encrpt(response.data.token)
          Cookies.set('s_l', token, { expires: 360 })
          Cookies.set('i_r', false)
          navigate('/');
          console.log(token, 'token ')
          // navigate('/registrationApprovalPage');
        }
      }).catch((err) => {
        console.log('Error', err);
        if (err.response.status === 401) {
          setMessage(err.response.data.message);
        }
        setErrors(err.response.data.errors);
      })
  }
  console.log(data && data, 'cius')
  useEffect(() => {
    window.scrollTo(0, 0);
    readCookie();
  }, []);
  return (
    <div>
      <div className="container register-block">
        <div className="col-md-12 mt-3">
          <div className="col-md-4 mx-auto">
            <div className="loginFormBlock">
              <div className="text-center mb-3">
                <img src={require("../../assets/ooooo.png")} style={{width:200}} />
              </div>
              <div>
                <h4>Sign In</h4>
              </div>
              <form className="row " onSubmit={submitForm}>
                {/* {errors && 
                <p className="fw-bold text-danger m-0 text-center  w-100 animate__animated animate__headShake" style={{top:-15,fontSize:13}}>Email doesn't match</p>
                } */}
                {message && message ? <p className="fw-bold text-danger m-0 text-center  w-100 animate__animated animate__headShake" style={{ top: -15, fontSize: 13 }}>{message}</p> : ''}
                <div className="col-md-12 col-lg-12">
                  <label className="form-label label-txt">
                    Mobile phone number
                  </label>
                  <input type="text" className="form-control " onChange={e => setData({ ...data, user_name: e.target.value })} required />
                </div>

                <div className="col-md-12 mt-3 col-lg-12">
                  <div className="row" style={{ justifyContent: "space-between", marginBottom: -10, }}>
                    <label className=" label-txt col-md-6  password-mb-20">Password</label>
                    <Link to="/forgotPassword" className="forgot-pass col-md-6">
                      <p>Forgot Password </p>
                    </Link>
                  </div>
                  <input type="password" className="form-control" onChange={e => setData({ ...data, password: e.target.value })} required />
                  {errors &&
                    <p className="fw-bold text-danger m-0 text-center  w-100 animate__animated animate__headShake" style={{ top: -15, fontSize: 13 }}>{errors}</p>
                  }
                </div>
                <div style={{alignItems:"center",marginTop:15}}>
                <button
                  // className="col-md-12 mt-4 text-center signIn-btn"
                  style={{width:"100%",backgroundColor:"#ff0000",borderColor:"#fff",padding:5,color:"#fff"}}
                  type="submit">
                  Sign in
                </button>
                </div>
              </form>
              <div className="a-divider a-divider-break mt-4">
                <h5>New to Aivimart?</h5>
              </div>
              <Link to="/registration" style={{ textDecorationLine: "none" }}>
                <div className="col-md-12 mt-3 text-center create-account-btn">
                  <p className="">Create your AiviMart account</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
