import React from 'react';
import { Link } from "react-router-dom";
import { FaExpandArrowsAlt } from "react-icons/fa";

function ProductDetailsThumbImage({image}) {
    // console.log('Hello',image);
    return (
        <span style={styles.thumbnail} >
            <img src={image} style={styles.thumbnailImage} />
            <a className="link-item" href={image} target="_blank" style={styles.thumbnailLink}><FaExpandArrowsAlt /></a>
        </span>
    );
}

export default ProductDetailsThumbImage;
const styles = {
    thumbnail: {position:'relative',width:45,height:62,marginTop:5,display: 'block',flexShrink:1,marginRight:10},
    thumbnailImage: {width:'100%',height:'100%',borderRadius:5},
    thumbnailLink: {color: '#333333cf',position: 'absolute',top: '30%',left: '25%',background: '#ffffff9e',padding: 5,borderRadius: '50%',fontSize: 10,},
    
}