import React from 'react';
import { Link } from "react-router-dom";
import { FaExpandArrowsAlt } from "react-icons/fa";

function TableThumbImage({image}) {
    console.log('Hello',image);
    return (
        <span className='thumbnail'>
            <img src={image} className='thumbnailImage' />
            <a className="link-item thumbnailLink" href={image} target="_blank" ><FaExpandArrowsAlt /></a>
        </span>
    );
}

export default TableThumbImage;
const styles = {
    
 }