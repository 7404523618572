import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation,useNavigate } from "react-router-dom";

const RegistrationApprovalPage = () => {
    return (
        <div>
          <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              <div className="mt-5" style={{height: '85vh', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                alignItems: 'center',}}>
              <h2 >Your profile is Submited For Approval</h2>
              <p style={{fontSize:20,}}>
              please login to check status
              </p>
              <Link
                to="/login"
                style={{ textDecorationLine: "none" }}
                className=" mt-1 text-center signIn-btn">
                Click here to login
            </Link>   
            </div>
               
              </div>
            </div>
            </div>
            </div> 
            <Outlet />
        </div>
    );
};

export default RegistrationApprovalPage;