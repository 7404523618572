import React,{useState,useEffect} from "react";
import { Outlet,Link,useLocation } from "react-router-dom";
import OtpTimer from 'otp-timer';

const OtpVerification = () => {
    const [counter, setcounter] = useState(10);
    const reSendOtpHandle = () => {
        setcounter(10);
       
      }
      const location = useLocation();
      useEffect(() => {
        window.scrollTo(0, 0);
      }, [location]);
  return (
    <div>
      <div className="container register-block">
        <div style={{ textAlign: "center" }}>
          <img src="./logo.webp" style={{ width: 130 }} />
        </div>
        <div className="col-md-12 mt-3">
          <div className="col-md-4 mx-auto">
            <div className="loginFormBlock">
              <div>
                <h4>Otp Verification required</h4>
                <p style={{fontSize:13}}>To continue, complete this verification step. We've sent an OTP to the mobile number</p>
              </div>
              <form className="row ">
                <div className="col-md-12">
                  <label className="form-label label-txt">
                    Enter OTP
                  </label>
                  <input type="text" className="form-control " />
                </div>
                <div className="text-center mt-2">
                <OtpTimer seconds= {counter}  
                    buttonColor={"#000"}
                    background={"#fff"}
                    textColor={"#000"}
                    ButtonText="Resend Otp"
                    text="Resend Otp in :"
                    minutes={0}
                    resend={() => reSendOtpHandle()}
                    />
                  </div>
                <Link to="/newPassword" style={{ textDecorationLine: "none" }}>
                  <div className="col-md-12 mt-3 text-center signIn-btn">
                    <p >Continue</p>
                  </div>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default OtpVerification;
