import React, {  useState } from "react";
import { BiBuildingHouse,BiEditAlt } from "react-icons/bi";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';


const BankDetails = (props) => {
    // console.table(props , 'bankdetails data');
    const [show, setShow] = useState(false);
    return (
        <div>
            <ToastContainer />
            <div className="card mb-3 p-2">
                <div className="d-flex justify-content-between">
                    <h4 className="h4 fw-bold" style={styles.header}>Bank Details</h4>
                    <button className="profile-edit-btn"><BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={() => setShow(true)} /></button>
                </div>
                <div className=" mt-3" >
                    <div className="row">
                        <div className="col-md-6">
                            <label><strong>Name of Bank Account No</strong></label>
                            <p className="mb-2" > {props.sellerProfile && props.sellerProfile.bank_detail.name}</p>
                        </div>
                        <div className="col-md-6">
                            <label><strong>Account Number</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.bank_detail.ac_no}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label><strong>IFSC Code</strong></label>
                            <p className="mb-2">{props.sellerProfile && props.sellerProfile.bank_detail.ifsc}</p>
                        </div>

                        <div className="col-md-4">
                            <label><strong>Bank Name</strong></label>
                            <p className="mb-2">{props.sellerProfile && props.sellerProfile.bank_detail.bank_name}</p>
                        </div>
                        <div className="col-md-4">
                            <label><strong>Branch Name</strong></label>
                            <p className="mb-2">{props.sellerProfile && props.sellerProfile.bank_detail.branch}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Bank Details Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={props.updateBankDetails} method="POST">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Account Holder </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="name of bank account no"
                                value={props.bankDetails ? props.bankDetails.name : '' }
                                onChange={e =>props.setBankDetails({...props.bankDetails,  name:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>account number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Account Number"
                                defaultValue={props.bankDetails ? props.bankDetails.ac_no : ''}
                                onChange={e =>props.setBankDetails({...props.bankDetails, ac_no:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>IFSC Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="IFSC code"
                                defaultValue={props.bankDetails ? props.bankDetails.ifsc : ''}
                                onChange={e =>props.setBankDetails({...props.bankDetails, ifsc:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="bank name"
                                defaultValue={props.bankDetails ? props.bankDetails.bank_name : ''}
                                onChange={e =>props.setBankDetails({...props.bankDetails, bank_name:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Branch Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="branch name"
                                defaultValue={props.bankDetails ? props.bankDetails.branch : ''}
                                onChange={e =>props.setBankDetails({...props.bankDetails, branch:e.target.value})}
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShow(false)}>
                                Close
                            </Button>
                            <Button className="saveChangesBtn" onClick={() => props.setShow(false)} type="submit" >
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default BankDetails;
const styles = {
    header: {textTransform: 'uppercase',color:'#149348',marginBottom:0},
    box : {background:'#e1e1e1',borderRadius:10, paddingLeft:10,paddingRight:10,padding:10,flex:1,boxShadow: '0 0 5px 0 #868686',border: '2px dotted #fff'},
    boxHeading : {color:'#333',fontWeight:'900',fontSize:15,marginBottom:0},
    boxNumber : {color:'green',fontWeight:'900',fontSize:25,marginBottom:0},
  }