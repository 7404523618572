import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const CategoryEdit = (props) => {
  // console.log(props, 'check props for ')
  return (
    <div>
        <div className="container">
          <div className="row ">
            <div>
              <label className="form-label label-txt"> Product Name</label>
              <input type="text" className="form-control" defaultValue={props.productDetails && props.productDetails.name}
              onChange={(e)=> props.setProductDetails({...props.productDetails , name:e.target.value})}
              />
              {props.error ? <p style={{fontSize : 12 , color : 'red'}}> {props.error.product_name}</p> : ''}
            </div> 
        
            <div className='col-md-4 mt-2'>
              <label className="form-label label-txt">Select Category</label>
              <select  className="form-select" onChange={(category_id) => { 
                // e.preventDefault();
                // props.fetchUpdateCategory(e.target.value)
                props.handleCategoryFirst(category_id.target.value)
              }}
              defaultValue={props.productDetails && props.productDetails.category_id}
              >
                <option disabled selected> Select Category</option>
                {props.categoryData && props.categoryData.map((item, index)=>{
                return (
                  <option key={index} value={item.id}>{item.name}</option>
                )
              })
              }
              </select>
              {props.error ? <p style={{fontSize : 12 , color : 'red'}}> {props.error.category_id}</p> : ''}
            </div>
           
            <div className='col-md-4 mt-2'>
              <label className="form-label label-txt col-md-7">
                Select Sub Category
              </label>
              <select className="form-select"   disabled={props.disabled}
              onChange={(sub_category_id) =>{ 
                // e.preventDefault();
                // props.fetchUpdateCategory(e.target.value)
                props.handleCategorySecond(sub_category_id.target.value)
                // props.setAddProductData({...props.addProductData, sub_category_id:e.target.value})
              }
            }
              defaultValue={props.productDetails && props.productDetails.sub_category_id}
              >
                <option disabled  selected> Select Sub Category</option>
                {props.subCategoryData && props.subCategoryData.map((item,index)=>{
                return(
                  <option key={index} value={item.id}>{item.name}</option>
                )
              })}
              </select>
              {props.error ? <p style={{fontSize : 12 , color : 'red'}}> {props.error.sub_category_id}</p> : ''}
            </div>
            
            <div className='col-md-4 mt-2'>
              <label className="form-label label-txt col-md-7">Select Last Category</label>
              <select className="form-select"   disabled={props.disabled2}
              onChange={(third_category_id) => {
                // e.preventDefault();
                // props.setDone(true);
                // props.fetchUpdateCategory(e.target.value)
                props.handleCategoryLast(third_category_id.target.value)
              }}
              defaultValue={props.productDetails && props.productDetails.third_category_id}
              >
                <option disabled selected > Select Last Category</option>
                {props.lastCategoryData && props.lastCategoryData.map((item,index)=>{
                return(
                  <option key={index} value={item.id}>{item.name}</option>
                )
              })}
              </select>
              {props.error ? <p style={{fontSize : 12 , color : 'red'}}> {props.error.third_category_id}</p> : ''}
            </div>
            
            <div className='col-md-12 mt-2'>
              <label className="form-label label-txt col-md-7">Select Brand</label>
              <select className="form-select"  
              onChange={(e) =>{ 
                e.preventDefault();
                props.setProductDetails({...props.productDetails, brand_id:e.target.value})
              }
            }
            defaultValue={props.productDetails && props.productDetails.brand_id}>
              <option disabled > Select  Brand</option>
              {props.brandData && props.brandData.map((item,index)=>{
                return(
                  <option key={index} value={item.brand_id}>{item.brand_name}</option>
                )
              })}
            </select>
            {props.error ? <p style={{fontSize : 12 , color : 'red'}}> {props.error.brand_id}</p> : ''}
            </div>

              {props.productDetails && props.productDetails.height != null ?
                <div className=" col-md-4 mt-2">
                  <label className="form-label label-txt"> Weight</label>
                  <input type="text" className="form-control " defaultValue={props.productDetails && props.productDetails.weight}
                    onChange={(e)=> props.setProductDetails({...props.productDetails , weight:e.target.value})}
                  />
                </div>:''
              }

              {props.productDetails && props.productDetails.height != null ?
                <div className='col-md-4 mt-2'>
                  <label className="form-label label-txt"> Height</label>
                  <input type="text" className="form-control " defaultValue={props.productDetails && props.productDetails.height}
                  onChange={(e)=> props.setProductDetails({...props.productDetails , height:e.target.value})}
                  />
                </div>:''
              }
           
            {props.productDetails && props.productDetails.width != null ?
              <div className='col-md-4 mt-2'>
                  <label className="form-label label-txt"> Width</label>
                  <input type="text" className="form-control " defaultValue={props.productDetails && props.productDetails.width}
                    onChange={(e)=> props.setProductDetails({...props.productDetails , width:e.target.value})}
                  />
              </div>:''
            }
           
              <div className="col-md-12 mt-2">
                <label className="form-label label-txt col-md-7">Short Description</label>
                <textarea defaultValue={props.productDetails && props.productDetails.short_description}
                  className="form-control"
                  aria-label="With textarea"
                  onChange={(e)=> props.setProductDetails({...props.productDetails , short_description:e.target.value})}
                ></textarea>
                {props.error ? <p style={{fontSize : 12 , color : 'red'}}> {props.error.short_description}</p> : ''}
              </div>
              <div>
            <div className="col-md-12">
                <div className="col-mb-12 mt-3">
                  <label className="form-label label-txt">Return Days</label>
                    <select className="form-select" aria-label="Default select example" 
                       onChange={(e)=> props.setProductDetails({...props.productDetails , return_day:e.target.value})}
                    >
                        <option selected disabled> Select Day</option>
                        <option value='15day'>15 Days Return</option>
                        <option value='30day'>30 Days Return</option>
                    </select>
                    <div className="col-md-12 mt-2">
                        <label className="form-label label-txt">Return Policy</label>
                        <textarea className="form-control" rows={5}
                       onChange={(e)=> props.setProductDetails({...props.productDetails , return_policy:e.target.value})}

                            aria-label="With textarea"></textarea>
                    </div>
                    {
                      props.error && props.error ? <p style={{color : 'red' , fontSize : 12}}>The return policy field is required .</p> : '' 
                    }
                  </div>
                </div>
            </div>
          </div>
          
        </div>
    
    </div>
  );
};

export default CategoryEdit;