import React,{useState, useContext} from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { ImHome3, ImUser } from "react-icons/im";
import { AiFillAppstore, AiOutlinePoweroff, } from "react-icons/ai";
import { FaProductHunt } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { BiUserCircle, BiUser } from "react-icons/bi";
import { FaUserAlt ,FaBookmark} from "react-icons/fa";
import {IoIosMenu} from "react-icons/io";
import {FiX } from "react-icons/fi";
import AuthUser from "../../context/AuthUser";
import Cookies from 'js-cookie'
import { Badge } from 'react-bootstrap';
import  RegistrationStatus  from "../../context/RegistrationStatus";
import  ProfileNameContext  from "../../context/ProfileName";
import axios from 'axios';
import { decrpt } from '../../component/encryption';
import { useEffect } from 'react';
import Loader from '../../component/loader';


const Templete = () => {
  const profileStatus = useContext(RegistrationStatus);
  const ForName = useContext(ProfileNameContext);

  console.log(profileStatus,'Context Profile Status');
  console.log(ForName,'Context Profile for name');


  const Auth = React.useContext(AuthUser);
  const navigate = useNavigate();
  const [nameLoader , setNameLoader] = useState();
  const [openMenu, seOpenMenu] = useState(false);
  const [ menuList, setMenuList ] = useState({
    activeMenu:1,
    menu:[
        {
            key:1,
            link:'./',
            icon:<ImHome3 className="menu-icon" /> ,
            name: 'Dashboard'
        },
        {
            key:2,
            link:'order',
            icon:<AiFillAppstore className="menu-icon" /> ,
            name: 'Orders'
        },
        {
            key:3,
            link:'product',
            icon:<FaProductHunt className="menu-icon" /> ,
            name: 'Products'
        },
        // {
        //     key:4,
        //     link:'commision',
        //     icon:<FaBookmark className="menu-icon" /> ,
        //     name: 'commision'
        // },
        {
            key:5,
            link:'profile',
            icon:<ImUser className="menu-icon" /> ,
            name: 'Profile'
        },
        {
            key:6,
            link:'changePass',
            icon:<FiSettings className="menu-icon" /> ,
            name: 'Change Password'
        },
    ]
})
// console.log(Auth && Auth , 'auth auth')
const appLoad = async () => {
  try {
    setNameLoader(true);
    let token = decrpt(Cookies.get('s_l'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axios.get(`/seller/dashboard`)
    console.log(response.data,'response dasfasfd');
    if (response.data.success) {
      let ProfilefromApi = response.data.registration_status;
      profileStatus.setProfile(ProfilefromApi);  
      ForName.setProfileNameState(response.data.seller_name)
      // Status
      setNameLoader(false);
    }         
  } catch (error) {
      console.log('Error',error);
      // if(error.response.data.success == false){
      //   console.log(Auth , "Auth outside")
      //   if(Auth && Auth.auth == true && error.response.data.success == false  ){
      //     navigate('/home');
      //     console.log(Auth , "Auth inside")
      //   }
      // }
      Auth.setAuth(false);
      Cookies.remove('s_l');
      navigate('/login');
  }
}




  const linkHandler = (key) => {
    // console.log('Key',key);
    setMenuList({...menuList,activeMenu:key});
    seOpenMenu(false)
  }

  const logOutHandler = () => {     
    Auth.setAuth(false);
    Cookies.remove('s_l');
    navigate('/login');
  }

  useEffect(() => {
    appLoad();
  },[])
  return (
  <div>
    {/* Header */}
    <div className="header">
      <div className='d-md-none  '>
      <button className="mble-menu-btn" onClick={() => seOpenMenu(true)} style={{border:' none',
        backgroundColor: 'transparent',}}><IoIosMenu style={{fontSize:25}}/></button> 
      </div>
      <div style={{paddingBottom:5,paddingTop:5}}>
        <img src={require('../../assets/ooooo.png')} className="site-logo" />
      </div>
      <div>
        <div className="dropdown">
          <button className="dropbtn"><FiSettings /></button>
          <div className="dropdown-content">
            <Link to="/profile"><BiUserCircle /> Profile</Link>
            <a onClick={()=>logOutHandler()}><AiOutlinePoweroff /> Log Out</a>
          </div>
        </div>
      </div>
    </div>
    <div className="page-content-area">
      <div className="d-flex">
        {/* side bar */}
        <aside className="sidebar d-none d-sm-block">
          <div className="admin-logo d-flex">
            <FaUserAlt style={{ fontSize: 50 }} />
            <div style={{ paddingBottom: 8 }}>
              <h5>
                Welcome, 
                {
                  profileStatus && profileStatus.profile == 5 ?
                  <Badge bg="success" style={{fontSize: '10px', lineHeight: 1,  color: 'white' ,marginLeft : '5px'}}> Pending</Badge>  :
                  <img src={require('../../assets/approve.png')} style={{width: 25}}/>
                }
                 <br />
                 {
                  nameLoader && nameLoader ? <div className='loaderName' style={{height : 20 , width : 20}}> <Loader /> </div> :
                <span style={{fontSize:14}}>{ForName && ForName.profileNameState ? ForName.profileNameState : ''  }</span>
                 }
                
              </h5>
            </div>
          </div>
          <div className="menuList">
            <ul>
              {menuList && menuList.menu.map((elements)=>{
                return(
                <li className={(menuList.activeMenu === elements.key) ? "active": ''} onClick={()=>linkHandler(elements.key)} key={elements.key}>
                  <Link to={elements.link} style={{ textDecoration: 'none',color:'#000',display:'block'}}>{elements.icon} {elements.name}</Link> 
                </li>
                )
              })}
              <li onClick={()=>logOutHandler()}><AiOutlinePoweroff /> Log Out</li>
            </ul>
          </div>
          {/* <div className="menuList">
            <ul>
              <Link to="./" style={{ textDecoration: 'none' }}><li><ImHome3 /> Dashboard</li></Link>
              <Link to="./order" style={{ textDecoration: 'none' }}><li><AiFillAppstore /> Orders</li></Link>
              <Link to="./product" style={{ textDecoration: 'none' }}><li><FaProductHunt /> Products</li></Link>
              <Link to="./profile" style={{ textDecoration: 'none' }}> <li><ImUser /> Profile</li></Link>
              <Link to="./changePass" style={{ textDecoration: 'none' }}> <li><FiSettings /> Change Password</li></Link>
              <li><AiOutlinePoweroff /> Log Out</li>
            </ul>
          </div> */}
        </aside>

        {/* Mobile Navbar */}
        {openMenu && 
        <div className="responsive-xs">
          <div className="mobileNavbar">
              <div className="mobileNavbarInner">
                  <button className="mble-menu-close" onClick={() => seOpenMenu(false)}><FiX /></button>
                  <aside className="sidebar d-md-none  ">
              <div className="admin-logo d-flex">
                <FaUserAlt style={{ fontSize: 50 }} />
                <div style={{ paddingBottom: 8 }}>
                  <h5>
                    Welcome, <br />
                    <span >Seller</span>
                  </h5>
                </div>
              </div>
              <div className="menuList">
              <ul>
                  {menuList && menuList.menu.map((elements)=>{
                    return(
                    <li className={(menuList.activeMenu === elements.key) ? "active": ''} onClick={()=>linkHandler(elements.key)} key={elements.key}>
                      <Link to={elements.link} style={{ textDecoration: 'none',color:'#000',display:'block'}}>{elements.icon} {elements.name}</Link> 
                    </li>
                    )
                  })}
                  <li><AiOutlinePoweroff /> Log Out</li>
                </ul>
              </div>
              {/* <div className="menuList">
                <ul>
                  <Link to="./" style={{ textDecoration: 'none' }}><li><ImHome3 /> Dashboard</li></Link>
                  <Link to="./order" style={{ textDecoration: 'none' }}><li><AiFillAppstore /> Orders</li></Link>
                  <Link to="./product" style={{ textDecoration: 'none' }}><li><FaProductHunt /> Products</li></Link>
                  <Link to="./profile" style={{ textDecoration: 'none' }}> <li><ImUser /> Profile</li></Link>
                  <Link to="./changePass" style={{ textDecoration: 'none' }}> <li><FiSettings /> Change Password</li></Link>
                  <li><AiOutlinePoweroff /> Log Out</li>
                </ul>
              </div> */}
            </aside>
              </div>        
          </div>    
        </div>    
        }

        {/* Main */}
        <main className="main">
          <Outlet />
        </main>
      </div>
    </div>
      {/* Footer */}
    <div className="footer">
      <p>©2022 AiviMart. All rights reserved</p>
    </div>
  </div>
  );
};

export default Templete;