import React, { useEffect, useState } from "react";
import { Outlet, Link ,useNavigate} from "react-router-dom";
import { AiOutlinePullRequest, AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineRotateLeft } from "react-icons/ai";
import {VscServerProcess} from "react-icons/vsc";
import { Badge, Button, Table} from 'react-bootstrap';
import Loader from '../../component/loader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {decrpt} from '../../component/encryption';
import { Modal } from "react-bootstrap";


function RefundOrderList() { 
  const navigate = useNavigate();
  const [show,setShow] = useState(false);
  const [loader,setLoader] = useState(false)
  const [showOrder,setOrderShow]=useState();
  const [selectedStatus, setSelectedStatus] = useState();
  console.log(selectedStatus,'selectedStatus');
  const [orderList,setOrderList]=useState();
  // console.log(orderList,"orderList");
  const [selectedOrderList,setSelectedOrderList]=useState();
   console.log(selectedOrderList,'selectedOrderList');
    // order fetch Api
    const fetchOrderList = async (status) =>{
      setLoader(true); 
      const data = {
          status: status,
      }
      console.log(data,'data');
      try {
        let token = decrpt(Cookies.get('s_l'));
      // let user = Cookies.get('username');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/seller/returned/orders/list`)
      console.log(response.data,'response');
      if (response.data.success) {
        setOrderList(response.data.data);
        setSelectedOrderList(response.data.data);
        setLoader(false);
      } else {
          setLoader(false);
      }
      } catch (error) {
          console.log('Error',error);
          setLoader(false);
      }
  }

    //list btn status change
    const statusHandler = (status) => {
      console.log(status,'status');
      // fetchProductList(status);
      if(status==1){
        let array=[];
        orderList && orderList.map((item,index)=>{
          if(item.status==1){
            array.push(item);
          }
        })
        setSelectedOrderList(array);
        fetchOrderList (array);
      }else if (status==2) {
        let array=[];
        orderList && orderList.map((item,index)=>{
          if(item.status==2){
            array.push(item);
          }
        })
        setSelectedOrderList(array);
        fetchOrderList (array);
      } else if (status==3) {
        let array=[];
        orderList && orderList.map((item,index)=>{
          if(item.status==3){
            array.push(item);
          }
        })
        setSelectedOrderList(array);
        fetchOrderList (array);
      }else if (status==4) {
      let array=[];
      orderList && orderList.map((item,index)=>{
        if(item.status==4){
          array.push(item);
        }
      })
      setSelectedOrderList(array);
      fetchOrderList (array);
    }
    else if (status==5) {
      let array=[];
      orderList && orderList.map((item,index)=>{
        if(item.status==5){
          array.push(item);
        }
      })
      setSelectedOrderList(array);
      fetchOrderList (array);
    }else{
        console.log("something went wrong");
      }
      
      setSelectedStatus(status);
      // console.log(status,'status');
  }

  const [orderStatus,setOrderStatus]=useState();
  // console.log(orderStatus,'approve');

  const orderStatusModalClose = () => {
    setShow(false);
  };

  const orderStatusChange = (item,status) => {
    setOrderStatus({item,status});
    setShow(true);
  }

//make approve Api
  const statusChange =async()=>{
    setLoader(true);
    setShow(false)
    const data = {
      order_id: orderStatus.item.id,
      status : orderStatus.status
    }
    console.log(data,'data');
      try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/seller/returned/orders/status/update`,data)
         console.log(response.data,'update response');
        // if (response.data.success) {
          setLoader(false);
        let newData = response.data.data;
        console.log(newData,'newData');
        let tmparray = selectedOrderList;
        let obj = tmparray.find((o, i) => {
          if (o.id === newData.id) {
                tmparray[i] = {...selectedOrderList[i] ,   ...newData  };
                return true; 
            }
        });
      // }
  } catch (error) {
    console.log(error);
    setLoader(false);
  }
  }

    // view details
    const viewDetails =(item)=> {
      navigate('../refundSingle' , {state : {item}});
    }

  useEffect (() => {
    window.scrollTo(0, 0);
    fetchOrderList();
    // setOrderShow(orderList.order)
  }, []);

  return (
        <div className="main-content">
            <div className="count-section d-flex justify-content-between">

             <button className="refundOrder-page-block d-flex align-items-center" onClick={() => statusHandler(1)} style={selectedStatus == 1 ? styles.activeBtn : null}>
                <div className="refundOrder-page-inner-block">
                    <h5 style={selectedStatus == 1 ? styles.activeBtnTxt : null}><VscServerProcess /> New Order</h5>
                </div>
            </button>
            {/* count 2 */}
            <button className="refundOrder-page-block d-flex align-items-center" onClick={() => statusHandler(2)}style={selectedStatus == 2 ? styles.activeBtn : null}>
                <div className="refundOrder-page-inner-block">
                    <h5 style={selectedStatus == 2 ? styles.activeBtnTxt : null}><AiOutlineCheckCircle/> Accepted Order</h5>
                </div>
            </button>
            {/* count 3 */}
            <button className="refundOrder-page-block d-flex align-items-center" onClick={() => statusHandler(3)}style={selectedStatus == 3 ? styles.activeBtn : null}>
                <div className="refundOrder-page-inner-block">
                    <h5 style={selectedStatus == 3 ? styles.activeBtnTxt : null}>< AiOutlineRotateLeft/> Picked Up Order</h5>
                </div>
            </button>
                {/* count 3 */}
            <button className="refundOrder-page-block d-flex align-items-center" onClick={() => statusHandler(4)}style={selectedStatus == 4 ? styles.activeBtn : null}>
              <div className="refundOrder-page-inner-block">
                  <h5 style={selectedStatus == 4 ? styles.activeBtnTxt : null}>< AiOutlineCloseCircle/> Refunded Order</h5>
              </div>
            </button>
              {/* count 3 */}
            <button className="refundOrder-page-block d-flex align-items-center" onClick={() => statusHandler(5)}style={selectedStatus == 5 ? styles.activeBtn : null}>
              <div className="refundOrder-page-inner-block">
                  <h5 style={selectedStatus == 5 ? styles.activeBtnTxt : null}>< AiOutlineCloseCircle/> Cancelled Order</h5>
              </div>
          </button>
        </div>
    {loader ? 
    <Loader />
    :
    <>
        {/* New User Registration */}
        <div className="section">
        <div className="row">
            {/* Past Order */}
            <div className="col-md-12">
            <div className="card mb-3 p-2">
                <div className="card-title d-flex align-items-center justify-content-between">
                <h5>All Order Items</h5>
                <div>
                    <Link to='./orderSearch' className="btn btn-sm btn-warning" >Search</Link>
                </div>
                </div>
                <div>
                <Table responsive="sm">
                    <thead>
                        <tr>
                            {/* <th>SL</th> */}
                            <th>Product Name</th>
                            <th>Byer Name</th>
                            <th className="text-center">Repaid Amount</th>
                            <th className="text-center">Order Status</th>
                            <th className="text-center">Payment Status</th>
                            <th className="text-center">Delivered Date</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {selectedOrderList && selectedOrderList.length > 0 ?
                          <>
                        {selectedOrderList && selectedOrderList.map((item,index)=>{
                          return(
                          <tr key={index}>
                            {/* <td>1. </td> */}
                            <td>{item.product_name}</td>
                            <td>{item.user_name}</td>
                            <td className="text-center">{item.amount_to_be_repaid}</td>
                            <td className="text-center">
                                  { item.status == 1 ? <Badge bg="primary"> New</Badge> : 
                                  (item.status == 2)  ? <Badge bg="success"> Accepted </Badge> :
                                  (item.status == 3)  ? <Badge bg="secondary"> Pick Up </Badge> : 
                                  (item.status == 4) ? <Badge bg="success"> Refunde Completed </Badge> : 
                                  <Badge bg="danger"> Cancelled</Badge> }
                            </td>
                            <td className="text-center">
                                  { item.payment_status == 1 ? <Badge bg="warning"> Pending</Badge> :
                                  <Badge bg="success"> Refunded</Badge> }
                            </td>
                            <td className="text-center">{item.delivered_date}</td>
                            <td className="text-center">
                                  <button type="button" className='btn btn-xs btn-primary text-white'  onClick={()=> viewDetails(item.id) }>View</button> &nbsp;
                                  
                                  {item.status == 1 ?
                                    <>
                                    <button type="button" className='btn btn-xs btn-success text-white me-1' onClick={()=>orderStatusChange(item,'2')}> Accept</button> 
                                    <button type="button" className='btn btn-xs btn-danger text-white' onClick={()=>orderStatusChange(item,'5')}> Deny </button> 
                                    </>
                                    :''
                                    }
                                    {item.status == 2 ?   
                                    <button type="button"   className='btn btn-xs btn-warning text-dark me-1' onClick={()=>orderStatusChange(item,'3')}>Pick Up</button> :
                                    (item.status == 3)  ?
                                    <button type="button"   className='btn btn-xs btn-success text-white me-1' onClick={()=>orderStatusChange(item,'4')}>Refund</button> :'' }
                                 </td>
                          </tr>
                          )
                        })}
                        </>:
                            <tr>
                              <td colSpan={5}>No Order Found</td>
                            </tr>
                            }
                    </tbody>
                </Table>
                </div>
            </div>
            </div>
        </div>
        </div>
          {/* approve reject */}
          <Modal size="sm" show={show} onHide={orderStatusModalClose} centered>
            <Modal.Header>
            {orderStatus && orderStatus.status == '2' ?
                <Modal.Title className="h6 fw-bolder">Are You Sure to Accept?</Modal.Title>:
                (orderStatus && orderStatus.status == '3')?
                <Modal.Title className="h6 fw-bolder">Are You Sure to Pick Up?</Modal.Title>:
                (orderStatus && orderStatus.status == '4')?
                <Modal.Title className="h6 fw-bolder">Are You Sure to Refund?</Modal.Title>:
                <Modal.Title className="h6 fw-bolder">Are You Sure to Deny?</Modal.Title>
                }
            </Modal.Header>
            <Modal.Footer className="p-1 justify-content-end" style={{borderTop:'none'}}>
              <button onClick={()=>orderStatusModalClose()} className="btn btn-light btn-sm">
                  No
              </button>
              <button onClick={()=>statusChange()} className="btn btn-primary btn-sm">
                  Yes
              </button>
            </Modal.Footer>
        </Modal>
    </>
    }
</div>

  );
}

export default RefundOrderList;
const styles = {

  activeBtn: { backgroundColor: '#ffc001ad' },
  activeBtnTxt: { color: '#000' },
}; 