import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Badge, Button, Table} from 'react-bootstrap';


function OrderSearchDetails() {
    return (
       <div className="main-content">
         {/* New User Registration */}
          <div className="section">
            <div className="row">
              {/* Past Order */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>All Order Items</h5>
                  </div>
                  <div>
                  <Table responsive="sm">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th style={{maxWidth:350}}>Product Name</th>
                                <th>Seller Name</th>
                                <th className="text-center">Date of Order</th>
                                <th className="text-center">Order Amount</th>
                                <th className="text-center">Order Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1. </td>
                                <td>Niki Sports Combo Pack of 4 Casual Sneakers With Sneakers For Men  (Multicolor)</td>
                                <td>Xyz Clothing</td>
                                <td className="text-center">20/12/2022</td>
                                <td className="text-center">₹ 500</td>
                                <td className="text-center"><Badge bg="warning" text="dark">In-Transit</Badge></td>
                                <td className="text-center">
                                  <Link to='../single' className='btn btn-xs btn-primary text-white'>View Order Detail</Link>
                                </td>
                            </tr>
                            <tr>
                                <td>2. </td>
                                <td>Ishwa Enterprise NOVA HAIR DRYER01005 Hair Dryer  (1000 W, Blue) Electronics</td>
                                <td>Xyz Clothing</td>
                                <td className="text-center">20/12/2022</td>
                                <td className="text-center">₹ 500</td>
                                <td className="text-center"><Badge bg="danger">Cancelled</Badge></td>
                                <td className="text-center">
                                  <Link to='../single' className='btn btn-xs btn-primary text-white'>View Order Detail</Link>
                                </td>
                            </tr>
                            <tr>
                                <td>3. </td>
                                <td>Combo Pack of 2 Casual Shoes (Sneakers Shoes) Loafers For Men  (Navy, Black)</td>
                                <td>Xyz Clothing</td>
                                <td className="text-center">20/12/2022</td>
                                <td className="text-center">₹ 500</td>
                                <td className="text-center"><Badge bg="success">Delivered</Badge></td>
                                <td className="text-center">
                                  <Link to='../single' className='btn btn-xs btn-primary text-white'>View Order Detail</Link>
                                </td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td>Portronics 6 Amp Turbo Car Charger  (Black)</td>
                                <td>Xyz Clothing</td>
                                <td className="text-center">20/12/2022</td>
                                <td className="text-center">₹ 500</td>
                                <td className="text-center"><Badge bg="success">Delivered</Badge></td>
                                <td className="text-center">
                                  <Link to='../single' className='btn btn-xs btn-primary text-white'>View Order Detail</Link>
                                </td>
                            </tr>
                            <tr>
                                <td>5. </td>
                                <td>Unique & Perfect Collection Combo Pack of 02 Shoes </td>
                                <td>Xyz Clothing</td>
                                <td className="text-center">20/12/2022</td>
                                <td className="text-center">₹ 500</td>
                                <td className="text-center"><Badge bg="danger">Cancelled</Badge></td>
                                <td className="text-center">
                                  <Link to='../single' className='btn btn-xs btn-primary text-white'>View Order Detail</Link>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>

  );
}

export default OrderSearchDetails;
