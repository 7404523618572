import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

const AddSTockPrice = (props) => {
    console.log(props , 'props check edi stsdiosahdjah')
    const [taxTable, setTaxTable] = useState(false);
    const [tax, setTax] = useState();
    const [adminCommission, setAdminCommission] = useState();
    const [ data, setData ] = useState({
        mrp: '',
        price: '',
        discount: '',
    })
    const openTaxTable = (e) =>{
        if (e.length > 0){
            setTaxTable(true);
        }else{
            setTaxTable(false);
        }
        discountCal(e);
        taxCal();
        adminCom();
    }
    
    const discountCal = (e) => {
        const discount1 = parseFloat((props.productDetails.mrp - e)) / parseFloat(props.productDetails.mrp);
        const discount = parseFloat(discount1 * 100 );
        // console.log('Discount',discount);
        setData({...data,discount:discount,price:e})
    }
    
    const taxCal = () => {
          // Api Call for tax
        const discounttax = parseFloat(18/100) * parseFloat(props.productDetails.price);
        setTax(discounttax.toFixed(2));
    }
    
    const adminCom = () => {
        // Api Call for tax
        const adminCommission = parseFloat(2/100) * parseFloat(props.productDetails.price);
        setAdminCommission(adminCommission.toFixed(2));
    }
    
    useEffect(()=> {
        taxCal();
        adminCom();
    })
    return (
        <div>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                {props.messageAddModal && props.messageAddModal ?
                <p style={{color : 'green' , fontSize : 14}}>{props.messageAddModal}</p> : ''    
            }
                <Form.Label>Sizes</Form.Label>
                <Form.Select aria-label="Default select example" 
                onChange={(e) => props.setProductDetails({...props.productDetails , size_id:e.target.value})}>
                        <option disabled selected> Select Product Size</option>
                        {props.productSize && props.productSize.map((item,index) => {
                            return (
                                <option key={index}   value={item.size_id}>{item.size_name}</option>
                            );
                        })
                    }
                </Form.Select>
                {props && props.errorAddModal ? <p style={{color : 'red' , fontSize : 12}}>{props.errorAddModal.size}</p> : '' }
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Product MRP</Form.Label>
                    <Form.Control type="text" 
                        onChange={(e) => props.setProductDetails({...props.productDetails , mrp:e.target.value})}
                    />
                    {props && props.errorAddModal ? <p style={{color : 'red' , fontSize : 12}}>{props.errorAddModal}</p> : '' }
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Product Price</Form.Label>
                    <Form.Control type="text" 
                        onChange={(e) => props.setProductDetails({...props.productDetails , price:e.target.value}, openTaxTable(e.target.value) )}
                    />
                    {props && props.errorAddModal ? <p style={{color : 'red' , fontSize : 12}}>{props.errorAddModal.price}</p> : '' }

                {/* Price Table */}
                {taxTable && ( <table className="table table-bordered mt-2">
                    <tbody>
                    <tr>
                        <td>Tax (18%)</td>
                        <td>₹ {tax}</td>
                    </tr>
                    <tr>
                        <td>Admin Commission (2%)</td>
                        <td>₹ {adminCommission}</td>
                    </tr>
                    <tr>
                        <td>You will get </td>
                        <td style={{fontSize:15}}>₹ {parseInt(data.price)-parseInt(tax)-parseInt(adminCommission)}</td>
                    </tr>
                    </tbody>
                </table> )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Product Stock</Form.Label>
                <Form.Control type="text" 
                      onChange={(e) => props.setProductDetails({...props.productDetails , stock:e.target.value})}
                />
                {props && props.errorAddModal ? <p style={{color : 'red' , fontSize : 12}}>{props.errorAddModal.stock}</p> : '' }
                </Form.Group>
            </Form>
        </div>
    );
};

export default AddSTockPrice;