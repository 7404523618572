import React, { useEffect, useState } from "react";
import { Outlet, Link,useNavigate } from "react-router-dom";
import { AiOutlinePullRequest, AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineRotateLeft } from "react-icons/ai";
import {VscServerProcess} from "react-icons/vsc";
import { Modal } from "react-bootstrap";
import { Badge, Button, Table} from 'react-bootstrap';
import Loader from '../../component/loader';
import OrderStatus from '../../component/orderStatus';
import axios from 'axios';
import Cookies from 'js-cookie';
import {decrpt} from '../../component/encryption';
import Pagination from "react-js-pagination";


function OrderList() {  
  const navigate = useNavigate();
  const [show,setShow] = useState(false);
  const [sidebarClose,setSidebarClose] = useState(false)
  const [loader,setLoader] = useState(false)
  const [showOrder,setOrderShow]=useState();
  //product list btn change ststus
  const [selectedStatus, setSelectedStatus] = useState();
  console.log(selectedStatus,'selectedStatus');
  const [orderList,setOrderList]=useState();
  // console.log(orderList,'orderList');
  const [selectedOrderList,setSelectedOrderList]=useState();
  // console.log(selectedOrderList,'selectedOrderList');
 
  // order fetch Api
    const fetchOrderList = async (status) =>{
      setLoader(true); 
      const data = {
          status: status,
      }
      console.log(data,'data check');
      try {
        let token = decrpt(Cookies.get('s_l'));
      // let user = Cookies.get('username');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/seller/orders/list`,data)
      console.log(response.data,'response 2');
      if (response.data.success) {
        setOrderList(response.data.data);
        setSelectedOrderList(response.data.data);
        setLoader(false);
      } else {
          setLoader(false);
      }
      } catch (error) {
          console.log('Error',error);
          setLoader(false);
      }
  }

    //list btn status change
  //   const statusTypeHandler = (status) => {
  //       fetchProductList(status);
  //       setSelectedStatus(status);
  //       console.log(status,'status');
  //   }
  // console.log(orderList.selectedStatus,"selectedStatus");
  
  // const statusHandler = (status) => {
  //     // setLoader(true);

  //     // let orders =[];
  //     // orderList && orderList.order.map((item)=>{
  //     //   if(item.status==status){
  //     //     orders.push(item);
  //     //   }
       
        
  //     // })
  //     // setOrderShow(orders);
  //     fetchOrderList (status);
  //     setSelectedStatus(status);
  // }

   
    //list btn status change
    const statusHandler = (status) => {
      console.log(status,'status');
      // fetchProductList(status);
      if(status==1){
        let array=[];
        console.log(orderList && orderList , 'orderList')
        orderList && orderList.map((item,index)=>{
          if(item.order_status==1){
            array.push(item);
          }
        })
        setSelectedOrderList(array);
        fetchOrderList (array);

      }else if (status==2) {
        let array=[];
        orderList && orderList.map((item,index)=>{
          if(item.order_status==2){
            array.push(item);
          }
        })
        setSelectedOrderList(array);
        fetchOrderList (array);
      } else if (status==3) {
        let array=[];
        orderList && orderList.map((item,index)=>{
          if(item.order_status==3){
            array.push(item);
          }
        })
        setSelectedOrderList(array);
        fetchOrderList (array);
        console.log(array && array , 'array')
      }else if (status==4) {
      let array=[];
      orderList && orderList.map((item,index)=>{
        if(item.order_status==4){
          array.push(item);
        }
      })
      setSelectedOrderList(array);
      fetchOrderList (array);
    }
    else if (status==5) {
      let array=[];
      orderList && orderList.map((item,index)=>{
        if(item.order_status==5){
          array.push(item);
        }
      })
      setSelectedOrderList(array);
      fetchOrderList (array);
    } else if (status==6) {
      let array=[];
      orderList && orderList.map((item,index)=>{
        if(item.order_status==6){
          array.push(item);
        }
      })
      setSelectedOrderList(array);
      fetchOrderList (array);
    }
    else if (status==7) {
      let array=[];
      orderList && orderList.map((item,index)=>{
        if(item.order_status==7){
          array.push(item);
        }
      })
      setSelectedOrderList(array);
      fetchOrderList (array);
    }else if (status==8){
      let array=[];
      orderList && orderList.map((item,index)=>{
        if(item.order_status==8){
          array.push(item);
        }
      })
      setSelectedOrderList(array);
      fetchOrderList (array);
    } else {
      
      console.log("something went wrong");
      }
      
      setSelectedStatus(status);
      // console.log(status,'status');
  }

  const [orderStatus,setOrderStatus]=useState();
  console.log(orderStatus,'approve orderStatus');

  const orderStatusModalClose = () => {
    setShow(false);
  };

  const orderStatusChange = (item,status) => {
    setOrderStatus({item,status});
    setShow(true);
  }

//make approve Api
  const statusChange =async()=>{
    setLoader(true);
    setShow(false)
    const data = {
      order_id: orderStatus.item.order_id,
      status : orderStatus.status
    }
    console.log(data,'data chekc 2');
      try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/seller/orders/status/update`,data)
         console.log(response.data,'update response');
        // if (response.data.success) {
          setLoader(false);
        let newData = response.data.order_data;
        // console.log(newData,'newData');
        // let tmparray = selectedOrderList;
        // console.log(selectedOrderList ,'newData selectedOrderList');
        // let obj = tmparray.find((o, i) => {
        //   if (o.order_id === newData.id) {
        //     tmparray[i] = {...selectedOrderList[i] ,   ...newData  };
        //     return true; 
        //   }
        // });
        fetchOrderList();
      // }
  } catch (error) {
    console.log(error);
    setLoader(false);
  }
  }

  // view details
  const viewDetails =(item)=> {
    navigate('../single' , {state : {item}});
  }

  useEffect (() => {
    window.scrollTo(0, 0);
    fetchOrderList()
   }, []);
  return (
    
      <div className="main-content">
        <div className="count-section d-flex justify-content-between">
          {/* new order */}
            <button className="order-page-block d-flex align-items-center" onClick={() => statusHandler(1)} style={selectedStatus == 1 ? styles.activeBtn : null}>
              <div className="order-page-inner-block">
                  <h5 style={selectedStatus == 1 ? styles.activeBtnTxt : null} ><VscServerProcess /> New Order</h5>
              </div>
            </button>
            {/* count 2 */}
            <button className="order-page-block d-flex align-items-center" onClick={() => statusHandler(2)} style={selectedStatus == 2 ? styles.activeBtn : null}>
              <div className="order-page-inner-block">
                  <h5 style={selectedStatus == 2 ? styles.activeBtnTxt : null} ><AiOutlineCheckCircle/> Accepted Order</h5>
              </div>
            </button>
            {/* count 3 */}
            <button className="order-page-block d-flex align-items-center" onClick={() => statusHandler(3)} style={selectedStatus == 3 ? styles.activeBtn : null}>
              <div className="order-page-inner-block">
                  <h5 style={selectedStatus == 3 ? styles.activeBtnTxt : null} >< AiOutlineRotateLeft/> Dispatched Order</h5>
              </div>
            </button>
            {/* count 3 */}
            <button className="order-page-block d-flex align-items-center" onClick={() => statusHandler(4)} style={selectedStatus == 4 ? styles.activeBtn : null}>
              <div className="order-page-inner-block">
                  <h5 style={selectedStatus == 4 ? styles.activeBtnTxt : null} >< AiOutlineCloseCircle/> Out For Deliver </h5>
              </div>
            </button>
            {/* count 2 */}
            <button className="order-page-block d-flex align-items-center" onClick={() => statusHandler(5)} style={selectedStatus == 5 ? styles.activeBtn : null}>
                <div className="order-page-inner-block">
                    <h5 style={selectedStatus == 5 ? styles.activeBtnTxt : null} ><AiOutlineCheckCircle/> Delivered Order</h5>
              </div>
          </button>
          {/* count 3 */}
          <button className="order-page-block d-flex align-items-center" onClick={() => statusHandler(6)} style={selectedStatus == 6 ? styles.activeBtn : null}>
              <div className="order-page-inner-block">
                  <h5 style={selectedStatus == 6 ? styles.activeBtnTxt : null} >< AiOutlineRotateLeft/> Cancelled By Seller</h5>
              </div>
          </button>
          {/* count 3 */}
          <button className="order-page-block d-flex align-items-center" onClick={() => statusHandler(7)} style={selectedStatus == 7 ? styles.activeBtn : null}>
          <div className="order-page-inner-block">
              <h5 style={selectedStatus == 7 ? styles.activeBtnTxt : null} >< AiOutlineRotateLeft/> Cancelled By User</h5>
          </div>
          </button>
          <button className="order-page-block d-flex align-items-center" onClick={() => statusHandler(8)} style={selectedStatus == 8 ? styles.activeBtn : null}>
          <div className="order-page-inner-block">
              <h5 style={selectedStatus == 8 ? styles.activeBtnTxt : null} >< AiOutlineRotateLeft/> Cancelled By admin</h5>
          </div>
          </button>
      </div>
      {loader ? 
        <Loader />
        :
        <>
          {/* New User Registration */}
          <div className="section">
            <div className="row">
              {/* Past Order */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>All Order Items</h5>
                    <div>
                      {/* <Link to='../orderSearch' className="btn btn-sm btn-warning" >Search</Link> */}
                    </div>
                  </div>
                  <div>
                  <Table responsive="sm">
                        <thead>
                            <tr>
                                {/* <th>SL</th> */}
                                <th>Order ID</th>
                                <th>Product Name</th>
                                <th>Brand Name</th>
                                <th className="text-center">Date of Order</th>
                                <th className="text-center">Quantity</th>
                                <th className="text-center">Order Amount</th>
                                <th className="text-center">Payment Type</th>
                                <th className="text-center">Order Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {selectedOrderList && selectedOrderList.length > 0 ?
                          <>
                          {selectedOrderList && selectedOrderList.map((item,index)=>{
                              return (
                                <tr key={index}>
                                    <td>{item.unique_id}</td>
                                    <td> <img src={item.product_image} className="product-table-img"/>{item.product_name} </td>
                                    <td>{item.brand}</td>
                                    <td className="text-center">{item.order_date}</td>
                                    <td className="text-center"> {item.quantity}</td>
                                    <td className="text-center">₹ {item.price}</td>
                                    <td className="text-center">{item.payment_type}</td>
                                    <td className="text-center">
                                      { item.order_status == 1 ? <Badge bg="primary"> New</Badge> : (item.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : (item.order_status == 7)  ? <Badge bg="danger"> Cancel By Seller </Badge> : 
                                      (item.order_status == 5)  ? <Badge bg="danger"> Delivered </Badge> : (item.order_status == 6)  ? <Badge bg="danger"> Cancel By User </Badge> : (item.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
                                      (item.order_status == 4) ? <Badge bg="secondary"> Out For Delivered</Badge> : <Badge bg="success">Cancelled By Admin </Badge> }
                                    </td>
                                    <td className="text-center">
                                      <button type="button" className='btn btn-xs btn-primary text-white'  onClick={()=> viewDetails(item.order_id) }>View</button> &nbsp;
                                      {item.order_status == 1 ?
                                          <>
                                          <button type="button" className='btn btn-xs btn-success text-white me-1' onClick={()=>orderStatusChange(item,2)}> Accept</button> 
                                          <button type="button" className='btn btn-xs btn-danger text-white' onClick={()=>orderStatusChange(item,'7')}> Deny </button> 
                                          </>
                                          : (item.order_status == 5 ? ''
                                          :<button type="button"   className='btn btn-xs btn-warning text-dark me-1' >Review By Admin</button>  ) 
                                        }
                                        {/* {item.order_status == 2 ?   
                                        <button type="button"   className='btn btn-xs btn-warning text-dark me-1' onClick={()=>orderStatusChange(item,'3')}>Dispatched</button> :  
                                        (item.order_status == 3)  ?  
                                        <button type="button"   className='btn btn-xs btn-secondary text-white me-1' onClick={()=>orderStatusChange(item,'4')}>Out For Delivery</button> : 
                                        (item.order_status == 4)  ?
                                        <button type="button"   className='btn btn-xs btn-success text-white me-1' onClick={()=>orderStatusChange(item,'5')}>Delivered</button> :'' } */}
                                    </td>
                                </tr>
                              );
                            })}
                            </>:
                            <tr>
                              <td colSpan={5}>No Order Found</td>
                            </tr>
                            }
                        </tbody>
                      </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* approve reject */}
          <Modal size="sm" show={show} onHide={orderStatusModalClose} centered>
            <Modal.Header>
            {orderStatus && orderStatus.status == '2' ?
                <Modal.Title className="h6 fw-bolder">Are You Sure to Accept?</Modal.Title>:
                (orderStatus && orderStatus.status == '3')?
                <Modal.Title className="h6 fw-bolder">Are You Sure to Dispatched?</Modal.Title>:
                (orderStatus && orderStatus.status == '4')?
                <Modal.Title className="h6 fw-bolder">Are You Sure to Out For Delivery?</Modal.Title>:
                (orderStatus && orderStatus.status == '5')?
                <Modal.Title className="h6 fw-bolder">Are You Sure to Delivered?</Modal.Title>:
                <Modal.Title className="h6 fw-bolder">Are You Sure to Deny?</Modal.Title>
                }
            </Modal.Header>
            <Modal.Footer className="p-1 justify-content-end" style={{borderTop:'none'}}>
              <button onClick={()=>orderStatusModalClose()} className="btn btn-light btn-sm">
                  No
              </button>
              <button onClick={()=>statusChange()} className="btn btn-primary btn-sm">
                  Yes
              </button>
            </Modal.Footer>
        </Modal>
        </>
      }
    </div>

  );
}

export default OrderList;
const styles = {

  activeBtn: { backgroundColor: '#ffc001ad' },
  activeBtnTxt: { color: '#000' },
}; 