import React, { useEffect, useState,useContext } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import { BsPlusCircleDotted ,BsFillChatSquareTextFill } from "react-icons/bs";
import { BiCategory, BiFilterAlt } from "react-icons/bi";
import { CgShoppingBag } from "react-icons/cg";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import NotApprove from '../../component/notApprove';
import Loader from "../../component/loader";
import  RegistrationStatus  from "../../context/RegistrationStatus";

function MasterPage () {
  const [loader,setLoader] = useState(false);
  const navigate = useNavigate();
  const profile = useContext(RegistrationStatus);
  console.log(profile,'Context Profile Status');

  return(
    <div className="main-content"> 
    { profile && profile.profile.registration_status == 5 ?
      <NotApprove/> :
      <>
      {loader && loader ? 
        <Loader /> 
      :
      <>
      <div className="section">
          <div className="row">
            {/* Category & Sub category */}
            <div className="col-md-12 mb-4">
              <div className="section-title">
                <h5><BiCategory className="me-1" />Orders and Refund Orders</h5>
              </div>
              <div className="count-section d-flex gap-3 mt-2">
                {/* count 1 */}
                <div className="count-block d-flex align-items-center justify-content-between no-divider">
                  <div  className="count-figure"><CgShoppingBag style={{fontSize:25}}/></div>
                  <div className="count-content">
                    <h5>Orders</h5>
                    <Link to="list" className="link-btn">view</Link>
                  </div>
                </div>
                {/* count 2 */}
                <div className="count-block d-flex align-items-center justify-content-between no-divider">
                  <div  className="count-figure"><HiOutlineReceiptRefund style={{fontSize:25}}/></div>
                  <div className="count-content">
                    <h5>Refund Orders</h5>
                    <Link to="refundOrderList" className="link-btn">view</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      } 
    </>
    }

    </div>
  )
}

export default MasterPage;
