import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";

import { Modal, Button, Table} from 'react-bootstrap';

function ProductSearchDetails() {

    // console.log(productShow,'productShow');
    const [poductList, setProductList] = useState({
        product: [
            {
                id: 'P01',
                name: 'Men Blue T-Shirt',
                image:'https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/12554514/2020/10/16/7e767b2e-b393-4354-aa24-3e86e92abd031602822707923-MANQ-Men-Suits-5401602822706180-1.jpg',
                sellername:'Xyz Clothing',
                amount: '500',
                shortdes: 'good',
                logdes: 'good',
                status: 1,

            },
            {
                id: 'P02',
                name: 'Men Green T-Shirt',
                image:'https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/16262972/2022/1/19/024eb163-e314-450e-a82f-9fea25c3c0c11642569047829-URBANIC-Women-Jeans-4661642569047115-1.jpg',
                sellername:'Xyr Clothing',
                amount: '800',
                shortdes: 'good',
                logdes: 'good',
                status: 2,

            }, {
                id: 'P03',
                name: 'Men Red T-Shirt',
                image:'https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/16477694/2021/12/27/6290d5f5-2b44-4254-b970-b21771a30f591640607086661-anayna-Women-Dresses-2031640607085991-1.jpg',
                sellername:'Abc Clothing',
                amount: '700',
                shortdes: 'good',
                logdes: 'good',
                status: 3,

            },
            {
                id: 'P01',
                name: 'Men Blue T-Shirt',
                image:'https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/1364628/2016/8/31/11472636737718-Roadster-Men-Blue-Regular-Fit-Printed-Casual-Shirt-6121472636737160-1.jpg',
                sellername:'Def Clothing',
                amount: '500',
                shortdes: 'good',
                logdes: 'good',
                status: 1,

            },
            {
                id: 'P02',
                name: 'Men Green T-Shirt',
                image:'https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/12554514/2020/10/16/7e767b2e-b393-4354-aa24-3e86e92abd031602822707923-MANQ-Men-Suits-5401602822706180-1.jpg',
                sellername:'Def Clothing',
                amount: '800',
                shortdes: 'good',
                logdes: 'good',
                status: 2,

            }, {
                id: 'P03',
                name: 'Men Red T-Shirt',
                image:'https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/16477694/2021/12/27/6290d5f5-2b44-4254-b970-b21771a30f591640607086661-anayna-Women-Dresses-2031640607085991-1.jpg',
                sellername:'Def Clothing',
                amount: '700',
                shortdes: 'good',
                logdes: 'good',
                status: 3,

            },

        ],

    });
    return (
        <div className="responsive-xs responsive-sm">
            {/* New Order */}
                <>
                <div className="section">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card p-2">
                                <div className="card-title d-flex align-items-center justify-content-between">
                                     <div>
                                        <h5>New Order</h5>
                                        </div>
                                    </div>
                                    <Table responsive="sm">
                                        <thead>
                                            <tr>
                                                <th>Product ID</th>
                                                <th>Product Name</th>
                                                <th>Seller Name</th>
                                                <th>Amount</th>
                                                <th>Order Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{verticalAlign: 'middle'}}>
                                            {poductList && poductList.product.length > 0 ?
                                                <>
                                                {poductList && poductList.product.map((item, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td>{item.id} </td>
                                                                <td><img src={item.image} className="product-table-img"/>{item.name}</td>
                                                                <td>{item.sellername}</td>
                                                                <td>{item.amount}</td>
                                                                <td><span class="badge text-dark bg-warning">In-Transit</span></td>
                                                                <td><Link to="../single" className='btn btn-xs btn-success text-white table-btn' style={{fontSize:13}}>View</Link></td>
                                                            </tr>
                                                        );
                                                    })}
                                                </>
                                                :
                                                <tr>
                                                    <td colSpan={5}>No Data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            <Outlet />
        </div>
    );
}

export default ProductSearchDetails;
