import React, { useEffect, useState } from "react";
import {Outlet, } from "react-router-dom";

function Product() {  
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
       <Outlet />
    </div>

  );
}

export default Product;
