import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap';

import { FaBookmark } from "react-icons/fa";
import { Link } from 'react-router-dom';


const CommisionPageDetails = () => {


    return (
        <div className="section">
          <div className="row">
                <div className="col-md-12 mb-4">
                    <div className="section-title">
                        <h5><FaBookmark className="me-1" />Commission Brief Details</h5>
                    </div>
                    <div className="section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-2">
                            <div className=" mt-3">
                                <div className="row">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>
        </div>
    );
}

export default CommisionPageDetails