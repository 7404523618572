import React , {useState, useEffect, useContext} from "react";
import { BrowserRouter , Route, Routes, Navigate } from "react-router-dom";
// Context 
import AuthUser from "../context/AuthUser";
import RegistrationStatus from "../context/RegistrationStatus";
import Loader from "../component/loader";
// import ProfileName2  from "../context/ProfileName";

import Homepage from '../screen/homePage/';
import Templete from '../screen/templete';
import Registration from '../screen/registrationPage'
import BusinessDetails from '../screen/registrationPageOld/businessDetails';
import PersonContactDetails from '../screen/registrationPageOld/personContactDetails';
import Login from '../screen/loginPage/';
import ForgotPassword from '../screen/forgotPasswordPage';
import OtpVerification from '../screen/forgotPasswordPage/otp';
import NewPassword from '../screen/forgotPasswordPage/newPassword';
import CompanyBusinessDetails from '../screen/registrationPage/companyBusinessDetails';
import DocumentUpload from '../screen/registrationPage/documentUpload';
import RegistrationApprovalPage from '../screen/registrationPage/registrationApprovalPage';

// Main Page Import
import Dashboard from "../screen/dashboard";
import Product from '../screen/productPage/';
import Order from '../screen/order';
import Profile from '../screen/profile';
import ChangePass from '../screen/changePass';

// Product Import
import ProductList from '../screen/productPage/product';
import ProductSingle from '../screen/productPage/single';
import ProductSearch from '../screen/productPage/productSearch';
import ProductSearchDetails from '../screen/productPage/productSearchDetails';

// Order Import
import MasterPage from '../screen/order/masterPage';
import OrderList from '../screen/order/list';
import OrderSingle from '../screen/order/single';
import OrderSearch from '../screen/order/orderSearch';
import OrderSearchDetails from '../screen/order/orderSearchDetails';
import RefundOrderList from '../screen/order/refundOrderList';
import RefundOrderSingle from '../screen/order/refundSingle';

// Profile Import
import ProfileDetails from '../screen/profile/profileDetails';
// commision page
import CommisionPage from "../screen/commision/list";
import CommisionPageDetails from "../screen/commision/details";
import Commision from "../screen/commision";
import Policy from "../screen/additional/policy"

// Other Import
import Cookies from 'js-cookie';
import axios from "axios";
import { decrpt } from "../component/encryption";
import  ProfileNameContext  from "../context/ProfileName";

function MainNavigation() {
  const [auth, setAuth ] = useState(false);
  const [profile, setProfile ] = useState('ProfilE Status Here');
  const [initalRoute, setInitalRoute ] = useState(null);
  const [loader, setLoader] = useState(false)
  const [dashboardData , setDashboardData ] = useState();
  const [profileNameState , setProfileNameState ] = useState();

  // console.log('Inital Route',initalRoute)
  
  const readCookie = async () => {
    const initalRouteNew = Cookies.get('i_r');   
    // console.log('Inital Route New',initalRouteNew)
    const token = Cookies.get('s_l');   
    if(token && token.length > 15){
      await setAuth(true);
    }
    // alert('hi');
    // if(initalRouteNew){
    //   setInitalRoute(initalRouteNew);
    // }else{
    //   setInitalRoute(initalRouteNew);
    // }
  }
  console.log('Auth',auth)

  useEffect(() => {
    readCookie();

  }, []);
  return (
    <>
    {loader ? <Loader /> :
    <>
    <BrowserRouter>
      <AuthUser.Provider value={{auth,setAuth}}>
        <RegistrationStatus.Provider value={{profile,setProfile}}>
          <ProfileNameContext.Provider value={{profileNameState , setProfileNameState}} >
            <Routes>
              <Route exact path="/" element={auth ? <Templete /> : <Homepage /> } >
              {/* <Route path="/" element={initalRoute === true ? <Navigate to="home" /> : (auth ? <Templete /> : <Navigate to="/login" /> ) } > */}
                {/* Dashboard */}
                <Route exact index path="" element={<Dashboard />} />
                {/* Product */}
                <Route path="product/*" element={<Product/>} >
                  <Route index path="" element={<ProductList />} />
                  <Route path="single" element={<ProductSingle />} />
                  <Route path="productSearch" element={<ProductSearch/>}/>
                  <Route path="productSearchDetails" element={<ProductSearchDetails/>}/>
                </Route>

                {/* Order */}
                <Route path="order/*" element={<Order/>} >
                  <Route exact index path="" element={<MasterPage />} />
                  <Route exact path="list" element={<OrderList />} />
                  <Route exact path="single" element={<OrderSingle />} />
                  <Route exact path="orderSearch" element={<OrderSearch />} />
                  <Route exact path="orderSearchDetails" element={<OrderSearchDetails/>}/>
                  <Route exact path="refundOrderList" element={<RefundOrderList/>}/>
                  <Route exact path="refundSingle" element={<RefundOrderSingle/>}/>
                </Route>

                {/* Profile */}
                <Route path="profile" element={<Profile/>} >            
                  <Route exact index path="" element={<ProfileDetails />} />
                </Route>

                {/* Commision page */}
                
                <Route path="commision" element={<Commision/>} >            
                  <Route  exact index  path="" element={<CommisionPage />} />
                  <Route exact path="commisionDetails" element={<CommisionPageDetails />} />
                </Route> 

                {/* Change Password */}
                <Route path="changePass" element={<ChangePass/>} />
              </Route>
              
              <Route path="home" element={<Homepage />} />
              <Route path="policy" element={<Policy />} />
              <Route path="login" element={auth ? <Navigate to="/" /> : <Login /> } />
              <Route path="forgotPassword" element={<ForgotPassword/>}/>
              <Route path="otpVerification" element={<OtpVerification/>}/>
              <Route path="newPassword" element={<NewPassword/>}/>
              <Route path="registration" >
                <Route exact index path="" element={<Registration />} />
                {/* <Route path="businessDetails" element={<BusinessDetails />} />
                <Route path="personContactDetails" element={<PersonContactDetails/>}/> */}
                <Route path="companyBusinessDetails" element={<CompanyBusinessDetails/>}/>
                <Route path="documentUpload" element={<DocumentUpload/>}/>
                <Route path="registrationApprovalPage" element={<RegistrationApprovalPage/>}/>
              </Route>
              {/* <Route path="registrationApprovalPage" element={<RegistrationApprovalPage/>}/> */}
            </Routes>  
          </ProfileNameContext.Provider>
        </RegistrationStatus.Provider>   
      </AuthUser.Provider>   
    </BrowserRouter>
    </>
}
    </>
  );
}

export default MainNavigation;
