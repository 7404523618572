// import React,{useState,useEffect} from "react";
// import { Outlet,Link,useLocation , useNavigate} from "react-router-dom";
// import { Spinner } from 'react-bootstrap';
// import OtpTimer from 'otp-timer';
// import axios from "axios";


// const ForgotPassword = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [errors , setErrors ] = useState();
//   const [passValidation , setPassValidation ] = useState();
//   const [counter, setcounter] = useState(10);
//   const [message , setMessage] = useState();
//   const [counterVisible, setcounterVisible] = useState(false);
//   const [otpSendProgress, setOtpSendProgress] = useState(false);
//   const [otpVerifyProgress, setOtpVerifyProgress] = useState(false);
//   const [basicDetailsProgress, setBasicDetailsProgress] = useState(false);
//   const [sendBtnVisible, setSendBtnVisible] = useState(true);
//   const [fieldVisibility,setFieldVisibility] = useState(0);
//   const [data,setData]=useState({
//     id:'',
//     mobile:'',
//     otp:'',
//     name:'',
//     email:'',
//     password:'',
//     password_confirmation:'',
//     isValidMobile: '',
//     check_textInputChange: '',
//   });
// console.log(errors, 'check passValidation');


//   const reSendOtpHandle = () => {
//     setcounter(10);
//     sendOtpHandle(data.mobile);
//   }
//   const sendOtpHandle = async mobile => {
//     console.log(mobile, 'clcllc');
//     setOtpSendProgress(true)
//     try {
//       const response = await axios.post(`seller/forgot/password/send/otp`, {mobile: mobile});
//       // console.log('response', response);
//       if (response.data.success) {
//         setFieldVisibility(1)
//         setcounterVisible(true)
//         setSendBtnVisible(false)
//         setOtpSendProgress(false)
//         setErrors(false);
//         setMessage(response.data.message);
//       } else {
//         // console.log(response.data.message) 
//         setOtpSendProgress(false)
//         setErrors(false);
//       }
//     } catch (error) {
//       console.log(error);
//       setErrors( error.response.data.message);
//       setOtpSendProgress(false)
//     }
//   };
//   const textInputChange = e =>{
//     console.log('input', e);
//     if (e.trim().length===10) {
//      setData({...data,
//        mobile:e,
//        isValidMobile: true,
//        check_textInputChange: true,
 
//      })
//     } else {
//      setData({...data,
//        mobile:e,
//        isValidMobile: false,
//        check_textInputChange: false,
 
//      })
//     }
//    }
//    const selectOtp = (otp) => {
//     setData({
//       ...data,
//       otp: otp,
//     })
//   }
//   const sendOtpVerify = async () => {
//     setMessage(false);
//     // setOtpVerifyProgress(true)
//     try {
//       const response = await axios.post(`seller/forgot/password/verify/otp`,data);
//       console.log('otp response', response);
//       setOtpVerifyProgress(false);
//       if (response.data.success === true) {
//         setFieldVisibility(2);
//         setMessage(response.data.message);
//       } 
//     } catch (error) {
//       setOtpVerifyProgress(false)
//       setMessage(error.response.data.message);
//       // console.log(error , 'erro');
//     }
//   };

//   // const submitPassword = async () => {
//   //   try {
//   //     const sendata = {
//   //       mobile : data.mobile,
//   //       otp : data.otp,
//   //       password : data.password,
//   //       password_confirmation : data.password_confirmation
//   //     }
//   //     const response = await axios.post(`seller/forgot/password/submit`,sendata);
//   //     console.log('polololo',response)
//   //     // if(response.data.success == true){
//   //     //   navigate('/Login' );
//   //     // }else {
//   //     //   setErrors(response.data.errors);
//   //     // }
      
//   //   }catch(err) {
//   //     // console.log(err , 'error ccath');
//   //     setErrors(err.response.data.errors);
//   //   }
//   // }
  
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location]);
//   return (
//     <div>
//       {otpSendProgress &&
//         <div style={{backgroundColor:'#c1bdbdcf',position:'absolute', zIndex:999, width:'100%', height:'100vh'}}>
//           <div style={styles.loader}>
//               <div className="loaderInner">
//                   <Spinner animation="border" size="xl" style={{color:'#ff0000'}}></Spinner>
//               </div>
//               <p>Sending OTP</p>
//               <p style={{marginTop: -17}}>Please, wait...</p>
//           </div>
//         </div>
//       }
//       {otpVerifyProgress &&
//         <div style={{backgroundColor:'#c1bdbdcf',position:'absolute', zIndex:999, width:'100%', height:'100vh'}}>
//           <div style={styles.loader}>
//             <div className="loaderInner">
//                 <Spinner animation="border" size="xl" style={{color:'#ff0000'}}></Spinner>
//             </div>
//             <p>Verify OTP</p>
//             <p style={{marginTop: -17}}>Please, wait...</p>
//           </div>
//         </div> 
//       }
//       {basicDetailsProgress &&
//         <div style={{backgroundColor:'#c1bdbdcf',position:'absolute', zIndex:999, width:'100%', height:'100vh'}}>
//           <div style={styles.loader}>
//             <div className="loaderInner">
//                 <Spinner animation="border" size="xl" variant='warning'></Spinner>
//             </div>
//             <p>Adding Details</p>
//           </div>
//         </div> 
//       }
//       <div className="container register-block">
//         <div style={{ textAlign: "center" }}>
//           <img src={require("../../assets/ooooo.png")} style={{ width: 130 }} />
//         </div>
//         <div className="col-md-12 mt-3">
//           <div className="col-md-4 mx-auto">
//             <div className="loginFormBlock">
//               <div>
//                 <h4>Password assistance</h4>
//                 <p style={{fontSize:13}}>Enter mobile phone number associated with your AiviMart account.</p>
//               </div>
//               <form className="row ">
//                 <div className="col-md-12">
//                   <label className="form-label label-txt">Mobile phone number</label>
//                   <input type="text" className="form-control " maxlength="10" onChange={e => textInputChange(e.target.value)}  required />
//                   {
//                     data.check_textInputChange ? 
//                     <div>
//                     {counterVisible &&
//                     <div className="registration-reSendOtp-btn">
//                     <OtpTimer 
//                     seconds= {counter}  
//                     buttonColor={"#000"}
//                     background={"#efefef"}
//                     textColor={"#000"}
//                     ButtonText="Resend Otp"
//                     text="Resend Otp in :"
//                     minutes={0}
//                     resend={() => reSendOtpHandle()}
//                     />
//                   </div>
//                     }
//                     {sendBtnVisible &&
//                     <button className="registration-otp-verify-btn" type="button" onClick={()=>sendOtpHandle(data.mobile)}>
//                     Send Otp
//                   </button>
//                   }
//                   </div>:null
//                   }
//                 {errors && errors ? <p style={{color : 'red' , fontSize : '12'}}>{errors.mobile}</p> : ''}
//                 {( fieldVisibility >=1) && (
//                   <div className="col-md-12 " style={{marginTop : '10px'}}>
//                   <label className="form-label label-txt col-md-7">
//                     Enter OTP
//                   </label>
//                   <input type="text" className="form-control" placeholder="Enter Otp" onChange={otp => selectOtp(otp.target.value)} required/>
//                   <button className="registration-otp-verify-btn" type="button" onClick={()=>sendOtpVerify()}>
//                   Verify Otp
//                 </button>
//                   {message && message ? <p style={{color : 'green' , fontSize : '12'}}>{message}</p> : ''}
//                 {errors && errors.otp && (<p style={{color: 'red',fontSize:12}}>{errors.otp}</p>)}
//                 </div>
//                 )
//               }
//               {( fieldVisibility >=2) && (
//                 <div >
//                   <div className="col-md-12 mt-1">
//                     <label className="form-label label-txt col-md-7">
//                       Password
//                     </label>
//                     <input type="text" className="form-control" onChange={e=>setData({...data, password:e.target.value})} required/>
//                   </div>
//                   <div className="col-md-12 mt-1" >
//                     <label className="form-label label-txt col-md-7">
//                       Confirm Password
//                     </label>
//                     <input type="text" className="form-control" onChange={e=>setData({...data, password_confirmation:e.target.value})} required/>
//                     {errors && errors.password && (<p style={{color: 'red',fontSize:12}}>{errors.password}</p>)}
//                   </div>
//                   <p style={{fontSize:13,marginTop:10}}>We will send you a text to verify your phone.Message and   Data rates may apply.</p>
// {/*                   
//                   <button 
//                    onClick={()=> submitPassword()} 
//                    className="col-md-12 mt-1 text-center signIn-btn"
//                     type="button" >
//                     Continue
//                   </button> */}
//                   <div style={{alignItems:"center",marginTop:15}}>
//                 <button
//                   // className="col-md-12 mt-4 text-center signIn-btn"
//                   style={{width:"100%",backgroundColor:"#ff0000",borderColor:"#fff",padding:5,color:"#fff"}}
//                   // onClick={submitPassword}
//                   // type="submit">
//                   >
//                  Continue1212
//                 </button>
//                 </div>
//                 </div>
//                 )
//               }
//                 </div>
//                 {/* <Link to="" style={{ textDecorationLine: "none" }}>
//                   <div className="col-md-12 mt-3 text-center signIn-btn">
//                     <p>Continue</p>
//                   </div>
//                 </Link> */}
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Outlet />
//     </div>
//   );
// };

// export default ForgotPassword;
// const styles = {
//   loader:{position: 'absolute',right: 0,left: 0,top: 200,zIndex: 1,textAlign: 'center',},  
// }


import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import OtpTimer from 'otp-timer';
import axios from "axios";

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [passValidation, setPassValidation] = useState();
  const [counter, setCounter] = useState(10);
  const [message, setMessage] = useState();
  const [counterVisible, setCounterVisible] = useState(false);
  const [otpSendProgress, setOtpSendProgress] = useState(false);
  const [otpVerifyProgress, setOtpVerifyProgress] = useState(false);
  const [basicDetailsProgress, setBasicDetailsProgress] = useState(false);
  const [sendBtnVisible, setSendBtnVisible] = useState(true);
  const [fieldVisibility, setFieldVisibility] = useState(0);
  const [data, setData] = useState({
    id: '',
    mobile: '',
    otp: '',
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    isValidMobile: '',
    check_textInputChange: '',
  });

  const reSendOtpHandle = () => {
    setCounter(10);
    sendOtpHandle(data.mobile);
  }

  const sendOtpHandle = async mobile => {
    setOtpSendProgress(true);
    try {
      const response = await axios.post(`seller/forgot/password/send/otp`, { mobile: mobile });
      if (response.data.success) {
        setFieldVisibility(1);
        setCounterVisible(true);
        setSendBtnVisible(false);
        setOtpSendProgress(false);
        setErrors(false);
        setMessage(response.data.message);
      } else {
        setOtpSendProgress(false);
        setErrors(false);
      }
    } catch (error) {
      setErrors(error.response.data.message);
      setOtpSendProgress(false);
    }
  };

  const textInputChange = e => {
    if (e.trim().length === 10) {
      setData({
        ...data,
        mobile: e,
        isValidMobile: true,
        check_textInputChange: true,
      });
    } else {
      setData({
        ...data,
        mobile: e,
        isValidMobile: false,
        check_textInputChange: false,
      });
    }
  }

  const selectOtp = (otp) => {
    setData({
      ...data,
      otp: otp,
    });
  }

  const sendOtpVerify = async () => {
    setMessage(false);
    try {
      const response = await axios.post(`seller/forgot/password/verify/otp`, data);
      setOtpVerifyProgress(false);
      if (response.data.success === true) {
        setFieldVisibility(2);
        setMessage(response.data.message);
      }
    } catch (error) {
      setOtpVerifyProgress(false);
      setMessage(error.response.data.message);
    }
  };

  const submitPassword = async () => {
    setBasicDetailsProgress(true);
    try {
      const sendata = {
        mobile: data.mobile,
        otp: data.otp,
        password: data.password,
        password_confirmation: data.password_confirmation
      };
      const response = await axios.post(`seller/forgot/password/submit`, sendata);
      console.log("lklkl",response)
      setBasicDetailsProgress(false);
      if (response.data.success) {
        navigate('/Login');
      } else {
        setErrors(response.data.errors);
      }
    } catch (err) {
      setBasicDetailsProgress(false);
      setErrors(err.response.data.errors);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  console.log("errors1212",errors)
  return (
    <div>
      {otpSendProgress &&
        <div style={{ backgroundColor: '#c1bdbdcf', position: 'absolute', zIndex: 999, width: '100%', height: '100vh' }}>
          <div style={styles.loader}>
            <div className="loaderInner">
              <Spinner animation="border" size="xl" style={{ color: '#ff0000' }}></Spinner>
            </div>
            <p>Sending OTP</p>
            <p style={{ marginTop: -17 }}>Please, wait...</p>
          </div>
        </div>
      }
      {otpVerifyProgress &&
        <div style={{ backgroundColor: '#c1bdbdcf', position: 'absolute', zIndex: 999, width: '100%', height: '100vh' }}>
          <div style={styles.loader}>
            <div className="loaderInner">
              <Spinner animation="border" size="xl" style={{ color: '#ff0000' }}></Spinner>
            </div>
            <p>Verify OTP</p>
            <p style={{ marginTop: -17 }}>Please, wait...</p>
          </div>
        </div>
      }
      {basicDetailsProgress &&
        <div style={{ backgroundColor: '#c1bdbdcf', position: 'absolute', zIndex: 999, width: '100%', height: '100vh' }}>
          <div style={styles.loader}>
            <div className="loaderInner">
              <Spinner animation="border" size="xl" variant='warning'></Spinner>
            </div>
            <p>Adding Details</p>
          </div>
        </div>
      }
      <div className="container register-block">
        <div style={{ textAlign: "center" }}>
          <img src={require("../../assets/ooooo.png")} style={{ width: 130 }} />
        </div>
        <div className="col-md-12 mt-3">
          <div className="col-md-4 mx-auto">
            <div className="loginFormBlock">
              <div>
                <h4>Password assistance</h4>
                <p style={{ fontSize: 13 }}>Enter mobile phone number associated with your AiviMart account.</p>
              </div>
              <form className="row ">
                <div className="col-md-12">
                  <label className="form-label label-txt">Mobile phone number</label>
                  <input type="text" className="form-control " maxLength="10" onChange={e => textInputChange(e.target.value)} required />
                  {
                    data.check_textInputChange ?
                      <div>
                        {counterVisible &&
                          <div className="registration-reSendOtp-btn">
                            <OtpTimer
                              seconds={counter}
                              buttonColor={"#000"}
                              background={"#efefef"}
                              textColor={"#000"}
                              ButtonText="Resend Otp"
                              text="Resend Otp in :"
                              minutes={0}
                              resend={() => reSendOtpHandle()}
                            />
                          </div>
                        }
                        {sendBtnVisible &&
                          <button className="registration-otp-verify-btn" type="button" onClick={() => sendOtpHandle(data.mobile)}>
                            Send Otp
                          </button>
                        }
                      </div> : null
                  }
                  {errors && errors ? <p style={{ color: 'red', fontSize: '12' }}>{errors.mobile}</p> : ''}
                  {(fieldVisibility >= 1) && (
                    <div className="col-md-12 " style={{ marginTop: '10px' }}>
                      <label className="form-label label-txt col-md-7">
                        Enter OTP
                      </label>
                      <input type="text" className="form-control" placeholder="Enter Otp" onChange={otp => selectOtp(otp.target.value)} required />
                      <button className="registration-otp-verify-btn" type="button" onClick={() => sendOtpVerify()}>
                        Verify Otp
                      </button>
                      {message && message ? <p style={{ color: 'green', fontSize: '12' }}>{message}</p> : ''}
                      {errors && errors.otp && (<p style={{ color: 'red', fontSize: 12 }}>{errors.otp}</p>)}
                    </div>
                  )}
                  {(fieldVisibility >= 2) && (
                    <div >
                      <div className="col-md-12 mt-1">
                        <label className="form-label label-txt col-md-7">
                          Password
                        </label>
                        <input type="password" className="form-control" onChange={e => setData({ ...data, password: e.target.value })} required />
                      </div>
                      <div className="col-md-12 mt-1" >
                        <label className="form-label label-txt col-md-7">
                          Confirm Password
                        </label>
                        <input type="password" className="form-control" onChange={e => setData({ ...data, password_confirmation: e.target.value })} required />
                        {errors && errors.password_confirmation ? <p style={{ color: 'red', fontSize: '12' }}>{errors.password_confirmation}</p> : ''}
                        <button type="button" className="registration-otp-verify-btn" onClick={submitPassword}>Submit</button>
                        {errors && errors.password ? <p style={{ color: 'red', fontSize: '12' }}>{errors.password}</p> : ''}
                        {/* {message && message ? <p style={{ color: 'green', fontSize: '12' }}>{message}</p> : ''} */}
                      </div>
                    </div>
                  )}
                </div>
                <Link className="btn-link  p-0 m-0" to="/Login" style={{ fontSize: 13 }}>Back to Login</Link>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  )
};

const styles = {
  loader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  loaderInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    width: 200,
    borderRadius: '50%',
    backgroundColor: 'white',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)'
  }
}

export default ForgotPassword;

