import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

const EditProductFilterModal = (props) => {


    console.log(props.productFilterValue , 'props check filter modal')
    return (
        <div>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Namess</Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(e) => props.handleFilterAdd(e.target.value)}
                        >
                            <option  selected disabled>------------Select From Below ------------</option>
                            {
                                props.productFilter  && props.productFilter.map((item,index) => {
                                    return (
                                        <option key={index} value={item. filter_master_id} >{item.name}</option>
                                    );
                                })
                            }
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Details</Form.Label>
                    <Form.Select aria-label="Default select example"
                     onChange={(e) => 
                        {
                          e.preventDefault();
                          props.handleFilterDetails(e.target.value);
                        }}
                        // onChange={()}
                            defaultValue={props.productDetails && props.productDetails.filter_master_details_id}>
                            <option  selected disabled>------------Select From Below ------------</option>
                            {
                                props.productFilterValue  && props.productFilterValue.map((item,index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    );
                                })
                            }
                    </Form.Select>
                </Form.Group>
            </Form>
        </div>
    );
};

export default EditProductFilterModal;