
import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { BiBuildingHouse, BiEditAlt } from "react-icons/bi";
import { BsGenderMale,BsCalendarDate} from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { FiSmartphone } from "react-icons/fi";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
// import axios from "axios";
// import Cookies from "js-cookie";
// import { decrpt } from "../../../component/encryption";


const BasicInfo = (props) => {
    // const [basicInfoModal, props.setBasicInfoModal] = useState(false);
    return (
        <div>
            {/* New User Registration */}
            <div className="section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-2">
                            <div className="d-flex justify-content-between">
                                <h4 className="h4 fw-bold" style={styles.header}>Personal Information</h4>
                                <button className="profile-edit-btn" onClick={() => props.setBasicInfoModal(true)}><BiEditAlt style={{ color: '#000', fontSize: 18 }}  /></button>
                            </div>
                            <div className=" mt-3">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label><strong><FaRegUser /> Contact Person</strong></label>
                                        <p className="mb-2" style={{ paddingLeft: 20 }}> { props.sellerProfile && props.sellerProfile.name} </p>
                                    </div>
                                    <div className="col-md-4">
                                        <label><strong><AiOutlineMail /> Email</strong></label>
                                        <p className="mb-2" style={{ paddingLeft: 20 }}>{ props.sellerProfile && props.sellerProfile.email}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label><strong><FiSmartphone /> Phone</strong></label>
                                        <p className="mb-2" style={{ paddingLeft: 20 }}>{props.sellerProfile && props.sellerProfile.mobile}</p>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <label><strong><FiSmartphone />Alternate Phone</strong></label>
                                        <p className="mb-2" style={{ paddingLeft: 20 }}>{props.sellerProfile && props.sellerProfile.business_details.alt_mobile}</p>
                                    </div> */}
                                    <div className="col-md-4">
                                        <label><strong><BsGenderMale /> Gender</strong></label>
                                        <p className="mb-2" style={{ paddingLeft: 20 }}>
                                            {props.sellerProfile && props.sellerProfile.gender == 'M' ? 'Male': ( props.sellerProfile && props.sellerProfile.gender == "F" ? "Female" : "")  }
                                            </p>
                                    </div>
                                    <div className="col-md-4">
                                        <label><strong><BsCalendarDate /> Date of Birth</strong></label>
                                        <p className="mb-2" style={{ paddingLeft: 20 }}>{props.sellerProfile && props.sellerProfile.dob}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label><strong><BsCalendarDate /> Alternate Number</strong></label>
                                        <p className="mb-2" style={{ paddingLeft: 20 }}>{props.sellerProfile && props.sellerProfile.alt_mobile}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Modal size="lg" show={props.basicInfoModal} onHide={() => props.setBasicInfoModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Basic Info Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={props.updatePersonal} method="post">
                        <Row>
                            <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Contact Person</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="contact person"
                                    value={props.basicUpdate ? props.basicUpdate.name : '' }
                                    onChange={e=>props.setBasicUpdate({...props.basicUpdate, name:e.target.value })}
                                />
                                {props?.error  ? <p style={{color : 'red' , fontSize : 12}}>{props.error.name}</p> : '' }
                            </Form.Group>
                            <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>E mail</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="contact person email"
                                    value={props.basicUpdate ? props.basicUpdate.email : '' }
                                    readOnly = {true}
                                    // onChange={e=>props.setBasicUpdate({...props.basicUpdate, email:e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-6">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select  aria-label="Default select example" value={props.basicUpdate.gender} onChange={e=>props.setBasicUpdate({...props.basicUpdate, gender:e.target.value })}>
                                    <option defaultValue={"value"} disabled >--- select Below---</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="phone number"
                                    value={props.basicUpdate ? props.basicUpdate.phone : '' }
                                    // onChange={e=>props.setBasicUpdate({...props.basicUpdate, phone:e.target.value})}
                                    readOnly = {true}

                                />
                            </Form.Group>
                            <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>ALternate Phone</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="ALternate phone number"
                                    value={props.basicUpdate ? props.basicUpdate.alt_mobile : '' }
                                    onChange={e=>props.setBasicUpdate({...props.basicUpdate, alt_mobile:e.target.value})}
                                />
                                {props?.error  ? <p style={{color : 'red' , fontSize : 12}}>{props.error.alt_mobile}</p> : '' }
                            </Form.Group>
                            <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="phone number"
                                    value={props.basicUpdate ? props.basicUpdate.dob : '' }
                                    onChange={e=>props.setBasicUpdate({...props.basicUpdate, dob:e.target.value})}
                                />
                            </Form.Group>
                        </Row>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => props.setBasicInfoModal(false)}>
                                Close
                            </Button>
                            <Button className="saveChangesBtn" type="submit" 
                            // onClick={() => props.setBasicInfoModal(false)}
                            >Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default BasicInfo;
const styles = {
    header: { textTransform: 'uppercase', color: '#149348', marginBottom: 0 },
    box: { background: '#e1e1e1', borderRadius: 10, paddingLeft: 10, paddingRight: 10, padding: 10, flex: 1, boxShadow: '0 0 5px 0 #868686', border: '2px dotted #fff' },
    boxHeading: { color: '#333', fontWeight: '900', fontSize: 15, marginBottom: 0 },
    boxNumber: { color: 'green', fontWeight: '900', fontSize: 25, marginBottom: 0 },
}