import React, { useEffect , useState } from "react";
import {  Link } from "react-router-dom";
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import Loader from "../../component/loader";



function Policy() {
  const [policydata , setPolicyData] = useState();
  const [loader , setLoader] = useState();

  const policyFetch  = async()  => {
    setLoader(true);
    // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axios.get(`/user/setting/sellerPolicy/view` )
    .then((response) => {
      console.log('password data' , response);
      if(response.data){
        setPolicyData(response.data.data.desc)
      }
    setLoader(false);
    }).catch((errors)=>{
      console.log('password data errors' , errors.response);

  })
 
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    policyFetch();
  }, []);

  return (
    <div className="responsive-xs responsive-sm">
      {/* Header */}
      <div className="header">
        <div style={{ width: "15%" }}>
          <Link to="/home">
            {/* <img src="./logo.webp" className="site-logo" /> */}
            <img src={require("../../assets/ooooo.png")} className="site-logo" />
          </Link>
        </div>

    </div>
    { loader && loader ? <div style={{height : '100vh'}}> <Loader /></div>
    
    : 
        <div className="policy">
          <div className="container my-5">
            <h4 className="text-center">Seller Policy</h4>
            { ReactHtmlParser(policydata && policydata) }
          </div>
        </div>
  }
      {/* Footer */}
      <div className="footer">
        <p > <Link style={{color : 'white' , textDecoration : 'none'}} to="policy"> Seller Policy</Link></p>
        <p style={{marginTop:15}}>©2022 AiviMart. All rights reserved</p>
      </div>
    </div>
  );
}

export default Policy;

