import React from 'react';
import { HiOutlineDocumentSearch } from "react-icons/hi";

function NotApprove() {
    return (
        <div style={styles.loader}>
            <div className="loaderInner">
                <HiOutlineDocumentSearch style={{fontSize:35}}/>
            </div>
            <h4 style={{marginTop:'10px'}}>Your Account Under Review By AiviMart</h4>
        </div>
    );
}

export default NotApprove;

const styles = {
    loader:{color: '#8f8d8d', marginTop: 170, textAlign: 'center',backgroundColor:'transparent'},
}