    import React, { useEffect, useState } from 'react';

    const ProductPriceChange = (props) => {
    const [taxTable, setTaxTable] = useState(false);
    const [tax, setTax] = useState();
    const [adminCommission, setAdminCommission] = useState();
    const [ data, setData ] = useState({
        mrp: '',
        price: '',
        discount: '',
    })

    console.log(data);
    const openTaxTable = (e) =>{
        if (e.length > 0){
            setTaxTable(true);
        }else{
            setTaxTable(false);
        }
        discountCal(e);
        taxCal();
        adminCom();
    }

    const discountCal = (e) => {
        const discount1 = parseFloat((data.mrp - e)) / parseFloat(data.mrp);
        const discount = parseFloat(discount1 * 100 );
        // console.log('Discount',discount);
        setData({...data,discount:discount,price:e})
    }

    const taxCal = () => {
          // Api Call for tax
        const discounttax = parseFloat(18/100) * parseFloat(data.price);
        setTax(discounttax.toFixed(2));
    }

    const adminCom = () => {
        // Api Call for tax
        const adminCommission = parseFloat(2/100) * parseFloat(data.price);
        setAdminCommission(adminCommission.toFixed(2));
    }

    useEffect(()=> {
        taxCal();
        adminCom();
    })
  
  return (
    <div>
      <div className="container mb-4">
        <form>
          <>
            <div className="row mt-2">
              <div className="col-md-4 ">
                <label className="form-label label-txt col-md-7">
                  Product MRP
                </label>
                <input type="text" className="form-control"
                  defaultValue={props.changePrice && props.changePrice.mrp}
                onChange={(e) => props.setChangePrice({...props.changePrice,mrp:e.target.value})} />
                {props.updatePriceError && props.updatePriceError ? <p style={{color : 'red' , fontSize : 12}}>{props.updatePriceError.mrp}</p> : ''}
              </div>
              <div className="col-md-4 ">
                <label className="form-label label-txt col-md-7">
                  Product Price
                </label>
                <input type="text"  className="form-control" 
                  defaultValue={props.changePrice && props.changePrice.price}
                onChange={(e) => props.setChangePrice({...props.changePrice, price:e.target.value})} />
                {props.updatePriceError && props.updatePriceError ? <p style={{color : 'red' , fontSize : 12}}>{props.updatePriceError.price}</p> : ''}
                {taxTable && (
                   <table class="table table-bordered mt-2">
                   <tbody>
                     <tr>
                       <td>Tax (18%)</td>
                       <td>₹ {tax}</td>
                     </tr>
                     <tr>
                       <td>Admin Commission (2%)</td>
                       <td>₹ {adminCommission}</td>
                     </tr>
                     <tr>
                      <td >You will get </td>
                       <td style={{fontSize:15}}>₹ {parseInt(data.price)-parseInt(tax)-parseInt(adminCommission)}</td>
                     </tr>
                   </tbody>
                 </table>  
                )}
              </div>
             <div className="col-md-4 ">
                <label className="form-label label-txt col-md-7">Stock</label>
                <input type="text" className="form-control" defaultValue={props.changePrice && props.changePrice.stock} 
                   onChange={(e) => props.setChangePrice({...props.changePrice, stock:e.target.value})}
                />
                {props.updatePriceError && props.updatePriceError ? <p style={{color : 'red' , fontSize : 12}}>{props.updatePriceError.stock}</p> : ''}
              </div>
              </div>
          </>
        </form>
      </div>
    </div>
  );
};

export default ProductPriceChange;