import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation,useNavigate } from "react-router-dom";
import OtpTimer from 'otp-timer';
import axios from "axios";
import { Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Registration = () => {
  
  const navigate = useNavigate();
  const [counter, setcounter] = useState(10);
  const [counterVisible, setcounterVisible] = useState(false);
  const [sendBtnVisible, setSendBtnVisible] = useState(true);
  const [otpSendProgress, setOtpSendProgress] = useState(false);
  const [otpVerifyProgress, setOtpVerifyProgress] = useState(false);
  const [basicDetailsProgress, setBasicDetailsProgress] = useState(false);
  const location = useLocation();
  const reSendOtpHandle = () => {
    setcounter(10);
    sendOtpHandle(data.mobile);
  }
  const [errors,setErrors]=useState();
  const [message,setMessage]=useState();
  const [fieldVisibility,setFieldVisibility] = useState(0);
  const [data,setData]=useState({
    id:'',
    mobile:'',
    otp:'',
    name:'',
    uu_id : '',
    email:'',
    password:'',
    password_confirmation:'',
    isValidMobile: '',
    check_textInputChange: '',
  });
// console.log(data.uu_id , 'sdsdsd');
  const textInputChange = e =>{
   console.log('input', e);
   if (e.trim().length===10) {
    setData({...data,
      mobile:e,
      isValidMobile: true,
      check_textInputChange: true,

    })
   } else {
    setData({...data,
      mobile:e,
      isValidMobile: false,
      check_textInputChange: false,

    })
   }
  }
  
  const sendOtpHandle = async mobile => {
    setErrors(false)
    setOtpSendProgress(true)
    try {
      const response = await axios.post(`seller/otp/send`, {mobile: mobile});
      console.log('response', response);
      if (response.data.success) {
        setFieldVisibility(1)
        setcounterVisible(true)
        setSendBtnVisible(false)
        setOtpSendProgress(false)
        
      } else {
        console.log(response.data.message) 
        setOtpSendProgress(false)
      }
    } catch (error) {
      console.log(error);
      setErrors(error.response.data.message)
      setOtpSendProgress(false)
    }
  };
  const selectOtp = (otp) => {
    setData({
      ...data,
      otp: otp,
    })
  }
  const sendOtpVerify = async () => {
    setOtpVerifyProgress(true)
    try {
      const response = await axios.post(`seller/otp/verify`,data);
      console.log('otp response', response);

      if (response.data.success) {
        // setFieldVisibility(2)
        setData({
          ...data,
          id:response.data.data.id,
        })
        console.log(response.data,'response data');
        setOtpVerifyProgress(false)
        if (response.data.data && response.data.data.registration_status == 2 ) {
          setFieldVisibility(2);
        } else if(response.data.data && response.data.data.registration_status == 3) {
          //redirect to business & bank detail page
          navigate('companyBusinessDetails', { state: { id:response.data.data.id, user:response.data.data } });
        }
         else if (response.data.data && response.data.data.registration_status == 4) {
          //redirect to File page
          navigate('documentUpload', { state: { id:response.data.data.id,  user:response.data.data  } });
        } else {
          //Complete
          navigate('registrationApprovalPage');
        }
        
      } else {
        setOtpVerifyProgress(true)
      }
    console.log(response.data.data.id,"id");
    } catch (error) {
      setOtpVerifyProgress(false);
      console.log(error,'catch');
      if (error.response.status==400) {
        // console.log(error.response.data.errors.otp,'catch')
        setErrors(error.response.data.errors);
      } else if (error.response.status == 412){
        setMessage(error.response.data.message)
        setErrors('');
      }
    }
  };

  const basicDetailsAdd= async ( id) => {
    setBasicDetailsProgress(true)
    try {
      const response = await axios.post(`seller/registration/basic`,data);
      console.log(response,"basic details");
     if (response.data.success) {
       navigate('companyBusinessDetails' , { state: { id:id, user:response.data.data} } );
       setBasicDetailsProgress(false);

     } else {
      setBasicDetailsProgress(false)
     }
    } catch (error) {
      setBasicDetailsProgress(false)
      console.log(error,'catch');
      // if (error.response.status==400) {
        // console.log(error.response.data.errors,'catch')
        setErrors(error.response.data.errors);
      // } else {
      
      // }
    }
  }

  // const uuidFetch  = async (uu_id) => {
  //   const data ={
  //     uu_id : uu_id
  //   }
  //   console.log(uu_id);
  //   try {
  //     const response = await axios.post(`seller/registration/uuid/check`, data ).then((response) => {
  //       if(response && response.data.success == true) {
  //         setData(data);
  //         console.log(response.data.message , ' response true');
  //         setMessage(response.data.message);
  //       }else {
  //           setMessage(response.data.message);
  //       }
         
  //     })
  //   }catch(error) {
  //       console.log(error.response);
  //       setErrors(error.response.data.errors);
  //   }
  // }
// console.log(message , ' response true');
// setMessage(errors);
// console.log(response ,' uuid response');
// setErrors(response && response.data.message ? response.data.message : 'no data');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <ToastContainer />
      {otpSendProgress &&
        <div style={{backgroundColor:'#c1bdbdcf',position:'absolute', zIndex:999, width:'100%', height:'100vh'}}>
          <div style={styles.loader}>
              <div className="loaderInner">
                  <Spinner animation="border" size="xl" style={{color:'#ff0000'}}/>
              </div>
              <p>Sending OTP</p>
              <p style={{marginTop: -17}}>Please, wait...</p>
          </div>
        </div>
      }
      {otpVerifyProgress &&
        <div style={{backgroundColor:'#c1bdbdcf',position:'absolute', zIndex:999, width:'100%', height:'100vh'}}>
          <div style={styles.loader}>
            <div className="loaderInner">
                <Spinner animation="border" size="xl" variant='warning'></Spinner>
            </div>
            <p>Verify OTP</p>
            <p style={{marginTop: -17}}>Please, wait...</p>
          </div>
        </div> 
      }
      {basicDetailsProgress &&
        <div style={{backgroundColor:'#c1bdbdcf',position:'absolute', zIndex:999, width:'100%', height:'100vh'}}>
          <div style={styles.loader}>
            <div className="loaderInner">
                <Spinner animation="border" size="xl" variant='warning'></Spinner>
            </div>
            <p>Adding Details</p>
          </div>
        </div> 
      }
      <div className="container register-block">
        <div className="col-md-12 mt-3">
          <div className="col-md-4 mx-auto">
            <div className="loginFormBlock">
              <div className="text-center mb-3">
                <img src={require("../../assets/ooooo.png")} style={{ width: 130 }} />
              </div>
              <p style={{color : 'red' , fontSize : 12}}>{message && message ? message : ''}</p>
              <div>
                <h4>Create Account</h4>
              </div>
                
              <form className="row ">
                <div className="col-md-12 mt-1">
                  <label className="form-label label-txt col-md-7">Mobile number</label>
                  <input type="text" className="form-control" placeholder="Enter Your Number" maxlength="10" onChange={e => textInputChange(e.target.value)}  required />
                
                  {
                    data.check_textInputChange ? 
                    <div>
                    {counterVisible &&
                    <div className="registration-reSendOtp-btn">
                    <OtpTimer 
                      seconds= {counter}  
                      buttonColor={"#000"}
                      background={"#efefef"}
                      textColor={"#000"}
                      ButtonText="Resend Otp"
                      text="Resend Otp in :"
                      minutes={0}
                      resend={() => reSendOtpHandle()}
                    />
                  </div>
                    }
                    {sendBtnVisible &&
                    <button className="registration-otp-verify-btn" type="button" onClick={()=>sendOtpHandle(data.mobile)}>
                    Send Otp
                  </button>
                  }
                  </div>:null
                  }
                    {/* {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors}</p>)} */}
                 </div>
                {( fieldVisibility >=1) && (
                  <div className="col-md-12 " style={{marginTop:-25}}>
                  <label className="form-label label-txt col-md-7">
                    Enter OTP
                  </label>
                  <input type="text" className="form-control" placeholder="Enter Otp" onChange={otp => selectOtp(otp.target.value)} required/>
                  <button className="registration-otp-verify-btn" type="button" onClick={()=>sendOtpVerify()}>
                  Verify Otp
                </button>
                {errors && errors.otp && (<p style={{color: 'red',fontSize:12}}>{errors.otp}</p>)}
                </div>
                )
              }
               {( fieldVisibility >=2) && (
                <div style={{marginTop:-15}}>
                  <div className="col-md-12">
                    <label className="form-label label-txt">Your name</label>
                    <input type="text" className="form-control " onChange={e=>setData({...data, name:e.target.value})} required/>
                    {errors && errors.name ? (<p style={{color: 'red',fontSize:12}}>{errors.name}</p>) : ''}
                  </div>
                  <div className="col-md-12 mt-1" >
                    <label className="form-label label-txt col-md-7">
                      E-mail address (Optional)
                    </label>
                    <input type="email" className="form-control" onChange={e=>setData({...data, email:e.target.value})}/>
                    {errors && errors.email ? (<p style={{color: 'red',fontSize:12}}>{errors.email}</p>) : ''}
                  </div>

                  {/* <div className="col-md-12 mt-1" >
                    <label className="form-label label-txt col-md-7">
                    Unique Id 
                    </label>
                    <input type="text" className="form-control" maxlength="5"  onKeyUp={() => uuidFetch(data.uu_id)} onChange={e=>setData({...data, uu_id:e.target.value})}/>
                    <small className="text-success">Set a unique id using numbers and text</small>
                    { errors && errors.uu_id ? (<p style={{color: 'red',fontSize:12, marginBottom : 0 }}>{errors.uu_id }</p>): '' }
                    { message  && ( <p style={{color: 'red',fontSize:12}}>{message && message }</p>)   }
                  </div> */}

                  <div className="col-md-12 mt-1">
                    <label className="form-label label-txt col-md-7">
                      Password
                    </label>
                    <input type="text" className="form-control" onChange={e=>setData({...data, password:e.target.value})} required/>
                    {errors && errors.password ? (<p style={{color: 'red',fontSize:12}}>{errors.password}</p>) : ''}
                  </div>
                  <div className="col-md-12 mt-1" >
                    <label className="form-label label-txt col-md-7">
                      Confirm Password
                    </label>
                    <input type="text" className="form-control" onChange={e=>setData({...data, password_confirmation:e.target.value})} required/>
                    {errors && errors.password_confirmation ? (<p style={{color: 'red',fontSize:12}}>{errors.password_confirmation}</p>) : ''}
                  </div>
                  <p style={{fontSize:13,marginTop:10}}>We will send you a text to verify your phone.Message and   Data rates may apply.</p>
                  
                  <button className="col-md-12 mt-1 text-center signIn-btn" type="button" onClick={()=>basicDetailsAdd(data.id)}>
                    Continue
                  </button>
                </div>
                )
              }
              <p style={{fontSize:12}} className="mt-3">Already have an account ? <Link to="/login" style={{ textDecorationLine: "none" }}> Sign In</Link></p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Registration;
const styles = {
  loader:{position: 'absolute',right: 0,left: 0,top: 200,zIndex: 1,textAlign: 'center',},
  
}

