import MainNavigation from './navigation/MainNavigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
//  axios.defaults.baseURL = 'https://jvmart.backend.webinfoghy.co.in/public/api/';
// axios.defaults.baseURL = 'https://avmart.backend.3decolutions.com/public/api';
axios.defaults.baseURL ="https://backend.aivimart.com/public/api";
// axios.defaults.baseURL = 'https://super.jivimart.com/public/api/';

function App() {
  return (
    <div className="App">
      <MainNavigation />
    </div>
  );
}

export default App;
