
import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { BiBuildingHouse, BiEditAlt } from "react-icons/bi";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";


const PickUp = (props) => {
    // console.log(props , 'props');
    // const [show, setPickUpModel] = useState(false);
    const [data , setData] = useState();
    const[fetchStates,setFetchStates] = useState();
    const[ fetchCities, setFetchCities] = useState();
    const[ fetchDistricts, setFetchDistricts] = useState();
    const[ fetchBusinessCat, setFetchBusinessCat] = useState();

    console.log(props , 'errororor')
    const handleOpen =  () => {
        props.setPickUpModel(true) ;
        fetchBusinessCategory();
        fetchState();
    } 

    const fetchBusinessCategory = async () => {
        try {
          const response = await axios.get(`seller/masters/business/category`);
        //   console.log("city" , response.data);
          if (response.data.success){
            setFetchBusinessCat(response.data.data);
          }
        }catch (error) {
          console.log(error);
        }
      };

    const fetchState = async () => {
        try {
          const response = await axios.get(`/seller/masters/state/list`);
          if (response.data.success){
            //   console.log("state data",response.data.data);
            setFetchStates(response.data.data);
          }else{
  
          }
      } catch (error) {
          console.log(error);
      }      
      };
  
      const fetchDistrict = async (state_id) => {
        console.log ('stateid', state_id);
        const data = {
          state_id :state_id
        }
        try {
          const response = await axios.post(`/seller/district/dropdown/list`, data);
          // console.log("district data",response.data);
            setFetchDistricts(response.data.data);
      } catch (error) {
          console.log(error);
      }      
      };
  
      const fetchCity = async ( district) => {
        // console.log ('district id', district );
        const data ={
          // stateId : stateId,
          district : district
        }
        try {
          const response = await axios.get(`/seller/masters/city/list/${district}`);
          console.log("city" , response.data);
          if (response.data.success){
            setFetchCities(response.data.data);
          }
        }catch (error) {
          console.log(error);
        }
      };

      const handleDistrict = ( district) => {
        console.log(district  , 'fetch districts id');
        props.setBussinesDetails({
            ...props.bussinesDetails,
          // state : state, 
          district : district
        })
        fetchCity( district);
      }
  
      const handleState = (state) => {
        props.setBussinesDetails({
          ...props.bussinesDetails,
          state : state, 
        })
        fetchDistrict(state);
        console.log(state , 'handle state fetch districts');
        // fetchCity(state);
      }
useEffect(() => {
   
})

    return (
        <div>
            {/* New User Registration */}

            <div className="card mb-3 p-2">
                <div className="d-flex justify-content-between">
                    {/* <h4 className="h4 fw-bold" style={styles.header}>Pick Up Location</h4> */}
                    <h4 className="h4 fw-bold" style={styles.header}>Bussiness Details</h4>
                    <button className="profile-edit-btn"><BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={handleOpen } /></button>
                </div>

                <div className=" mt-3">
                    <div className="row">
                        <div className="col-md-5">
                            <label><strong>Address</strong></label>
                            {/* <p className="mb-2" > House No. - 03, 3rd Floor, Rajbari Path, Dispur, Ganeshguri, Guwahati, Assam 781005</p> */}
                            <p className="mb-2" > {props.sellerProfile && props.sellerProfile.address}</p>
                        </div>
                        <div className="col-md-2">
                            <label><strong>Pin Code</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.pin}</p>
                        </div>
                        <div className="col-md-3">
                            <label><strong>State</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.stateName}</p>
                        </div>
                        <div className="col-md-2">
                            <label><strong>District</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.districtName}</p>
                        </div>
                        <div className="col-md-3">
                            <label><strong>City</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.cityName}</p>
                        </div>
                       
                        <div className="col-md-3">
                            <label><strong>Landmark</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.land_mark}</p>
                        </div>
                        <div className="col-md-3">
                            <label><strong>Street Name</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.street}</p>
                        </div>
                        <div className="col-md-3">
                            <label><strong>Business Category Name</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.business_category_name}</p>
                        </div>
                        <div className="col-md-3">
                            <label><strong>Business Description</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.business_description}</p>
                        </div>
                        <div className="col-md-3">
                            <label><strong>Business Email</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.business_email}</p>
                        </div>
                        <div className="col-md-3">
                            <label><strong>Business Name</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.business_name}</p>
                        </div>
                        <div className="col-md-3">
                            <label><strong>Alt Mobile No</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.alt_mobile}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={props.pickUpModal} size="lg" onHide={() => props.setPickUpModel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Bussiness Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Address</Form.Label>
                            <Form.Control as="textarea" rows={3} 
                            defaultValue={props.sellerProfile && props.sellerProfile.address}
                            onChange={e => props.setBussinesDetails({...props.bussinesDetails , address:e.target.value})}
                            />
                            {props.pickupError?.address ? <p style={{fontSize : 12 , color : 'red'}}>{props.pickupError.adress}</p> : ''}
                        </Form.Group>
                        <Form.Group  className="col-lg-4  mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Pin Code</Form.Label>
                            <Form.Control type="text"  
                            defaultValue={props.sellerProfile && props.sellerProfile.pin}
                            onChange={e => props.setBussinesDetails({...props.bussinesDetails , pin:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-4 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>State</Form.Label>
                            {/* <Form.Control type="text"  
                            defaultValue={props.sellerProfile && props.sellerProfile.stateName}
                            /> */}
                             <select className="form-select" aria-label="Default select example"  
                                onChange={state => handleState(state.target.value)}>
                                    <option selected value=''> Select states</option>
                                    {
                                    fetchStates && fetchStates.map((item , index) => {
                                        return (
                                        <option  key={index} value={item.id}> {item.name}</option>
                                    ) })
                                    }
                            </select>
                            {props.pickupError?.state ? <p style={{fontSize : 12 , color : 'red'}}>{props.pickupError.state}</p> : ''}
                        </Form.Group>
                        <Form.Group className="col-md-4 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>District</Form.Label>
                            {/* <Form.Control type="text" 
                            defaultValue={props.sellerProfile && props.sellerProfile.districtName}
                            /> */}
                            <select className="form-select" aria-label="Default select example" 
                                onChange={district =>  handleDistrict(district.target.value)}>
                                    <option selected value=''> Select District</option>
                                    { fetchDistricts && fetchDistricts.map((item ,index) => {
                                    return (
                                        <option  key={index} value={item.id}> {item.name}</option>
                                    )
                                    })
                                    }
                            </select>
                            {props.pickupError?.city ? <p style={{fontSize : 12 , color : 'red'}}>{props.pickupError.city}</p> : ''}
                        </Form.Group>
                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>City</Form.Label>
                            {/* <Form.Control type="text"  
                            defaultValue={props.sellerProfile && props.sellerProfile.cityName}
                            /> */}
                             <select className="form-select" aria-label="Default select example" 
                                onChange={e => props.setBussinesDetails({...props.bussinesDetails, city:e.target.value})}>
                                    <option selected value=''> Select City</option>
                                    { fetchCities && fetchCities.map((item ,index) => {
                                    return (
                                        <option  key={index} value={item.id}> {item.name}</option>
                                    )
                                    })
                                    }
                            </select>
                            {props.pickupError?.city ? <p style={{fontSize : 12 , color : 'red'}}>{props.pickupError.city}</p> : ''}
                        </Form.Group>
                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Land Mark</Form.Label>
                            <Form.Control type="text"  
                            defaultValue={props.sellerProfile && props.sellerProfile.land_mark}
                            onChange={e => props.setBussinesDetails({...props.bussinesDetails , land_mark:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control type="text"  disabled={true}
                            defaultValue={props.sellerProfile && props.sellerProfile.mobile}
                            // onChange={e => props.setBussinesDetails({...props.bussinesDetails , mobile:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Street</Form.Label>
                            <Form.Control type="text" placeholder="state" 
                            defaultValue={props.sellerProfile && props.sellerProfile.street}
                            onChange={e => props.setBussinesDetails({...props.bussinesDetails , street:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Business Name</Form.Label>
                            <Form.Control type="text" placeholder="Business Name" 
                            defaultValue={props.sellerProfile && props.sellerProfile.business_name}
                            onChange={e => props.setBussinesDetails({...props.bussinesDetails , business_name:e.target.value})}
                            />
                            {props.pickupError?.business_name ? <p style={{fontSize : 12 , color : 'red'}}>{props.pickupError.business_name}</p> : ''}
                        </Form.Group>
                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Business Type</Form.Label>
                            {/* <Form.Control type="text" placeholder="state" 
                            defaultValue={props.sellerProfile && props.sellerProfile.business_category_name}
                            onChange={e => props.setBussinesDetails({...props.bussinesDetails , business_category_name:e.target.value})}
                            
                            /> */}
                              <select className="form-select" aria-label="Default select example"   
                            //   value={props.sellerProfile && props.sellerProfile.business_category}
                                onChange={e => props.setBussinesDetails({...props.bussinesDetails , business_category:e.target.value})}
                                    >
                                    <option selected value=''> Select Bussiness Type</option>
                                        { fetchBusinessCat && fetchBusinessCat.map((item ,index) => {
                                        return (
                                            <option  key={index} value={item.id}> {item.name}</option>
                                        )
                                        })
                                        }
                                </select>
                            {props.pickupError?.business_category ? <p style={{fontSize : 12 , color : 'red'}}>{props.pickupError.business_category}</p> : ''}
                                
                        </Form.Group>
                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Business Email</Form.Label>
                            <Form.Control type="text" placeholder="state" 
                            defaultValue={props.sellerProfile && props.sellerProfile.business_email}
                            onChange={e => props.setBussinesDetails({...props.bussinesDetails , business_email:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Business Description</Form.Label>
                            <Form.Control type="textarea" as="textarea" row={3} placeholder="state" 
                            defaultValue={props.sellerProfile && props.sellerProfile.business_description}
                            onChange={e => props.setBussinesDetails({...props.bussinesDetails , business_description:e.target.value})}
                            />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Alt Mobile No</Form.Label>
                            <Form.Control type="text" placeholder="mobile number"
                            defaultValue={props.sellerProfile && props.sellerProfile.business_description}
                            />
                        </Form.Group> */}
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setPickUpModel(false)}>Close</Button>
                    <Button type="button" className="saveChangesBtn" onClick={() => props.updateBussinessDetails()}>Save Changes</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PickUp;
const styles = {
    header: { textTransform: 'uppercase', color: '#149348', marginBottom: 0 },
    box: { background: '#e1e1e1', borderRadius: 10, paddingLeft: 10, paddingRight: 10, padding: 10, flex: 1, boxShadow: '0 0 5px 0 #868686', border: '2px dotted #fff' },
    boxHeading: { color: '#333', fontWeight: '900', fontSize: 15, marginBottom: 0 },
    boxNumber: { color: 'green', fontWeight: '900', fontSize: 25, marginBottom: 0 },
}