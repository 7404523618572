import React, { useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import Modal from "react-modal";

function Homepage() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="responsive-xs responsive-sm">
      {/* Header */}
      <div className="header">
        <div style={{ width: "15%",paddingTop:5,paddingBottom:5 }}>
          <img src={require("../../assets/ooooo.png")} className="site-logo" />
        </div>

        <div className="header-start-selling-btn">
          {/* <Link to="./" className="logout">Logout</Link> */}
          <Link to="/login">
            <button onClick={openModal} className="login">
              Start Selling
            </button>
          </Link>
        </div>
      </div>

      <div className="page-content-area">
        {/* Slider */}
        <div className="seller-banner">
          <div className="container-fluid">
            <img src="./seller-banner.jpg" className="seller-banner-image" />
            <div className="seller-banner-content">
              <h2 className="seller-banner-title">Sell on AiviMart to your target audience</h2>
              <div><Link to="/login"><button className="start-Selling">Start Selling</button></Link></div>
              {/* <div>
                <input
                  className="seller-banner-input"
                  placeholder="Enter Your 10 Digit Mobile Number"
                />
                <Link to="/login">
                  <button className="seller-banner-button">register</button>
                </Link>
              </div> */}
            </div>
          </div>
        </div>

        {/* how to sell on jivimart */}
        <div className="section">
          <div className="container">
            <div className="section-header">
              <h2>How to sell on Aivimart</h2>
            </div>
            <div className="content-area">
              <div className="how-sell-jivimart-step position-relative">
                <span className="how-sell-jivimart-shape position-absolute">
                  <img src="./line.png" alt="" className="lineDisplayNone"/>
                </span>
                <div style={{ marginBottom: 50 }}>
                  <div className="how-sell-jivimart-icon-text d-inline-block">
                    <div className="how-sell-jivimart-icon text-center">
                      <img src="./homePage/icon1.png" className="site-logo" />
                    </div>
                    <div className="how-sell-jivimart-text headline pera-content text-center">
                      <h3>Create an Account</h3>
                      {/* <p>Lorem ipsum dolor sit amet, csectetur adipis icing.</p> */}
                    </div>
                  </div>
                  <div className="how-sell-jivimart-icon-text d-inline-block">
                    <div className="how-sell-jivimart-icon text-center">
                      <img src="./homePage/icon2.png" className="site-logo" />
                    </div>
                    <div className="how-sell-jivimart-text headline pera-content text-center">
                      <h3>List, store & deliver</h3>
                      {/* <p>Lorem ipsum dolor sit amet, csectetur adipis icing.</p> */}
                    </div>
                  </div>
                  <div className="how-sell-jivimart-icon-text d-inline-block how-sell-mt-40">
                    <div className="how-sell-jivimart-icon text-center">
                      <img src="./homePage/icon3.png" className="site-logo" />
                    </div>
                    <div className="how-sell-jivimart-text headline pera-content text-center">
                      <h3>Monitor sales & track growth</h3>
                      {/* <p>Lorem ipsum dolor sit amet, csectetur adipis icing.</p> */}
                    </div>
                  </div>
                  <div className="how-sell-jivimart-icon-text d-inline-block how-sell-mt-25">
                    <div className="how-sell-jivimart-icon text-center">
                      <img src="./homePage/icon4.png" className="site-logo" />
                    </div>
                    <div className="how-sell-jivimart-text headline pera-content text-center">
                      <h3>Get paid for your sales</h3>
                      {/* <p>Lorem ipsum dolor sit amet, csectetur adipis icing.</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* how to sell on jivimart */}
        <div className="section mt-5 ">
          <div className="container-fluid">
            <div className="section-header">
              <h2>Why sell on Aivimart?</h2>
            </div>
            <section className="why-sell-jivimart-section">
              <div className="container-fluid">
                <div className="why-sell-jivimart-content">
                  <div className="row">
                    <div className="col-md-4 wow fadeInUp animated">
                      <div className="why-sell-jivimart-innerbox position-relative headline pera-content">
                        <div className="nio-eig-ft-icon-title">
                          <div className="why-sell-jivimart-icon float-left">
                            <img
                              src="./homePage/icon4.png"
                              className="site-logo"
                            />
                          </div>
                          <div className="why-sell-jivimart-title">
                            <h3>Secure payments, regularly</h3>
                          </div>
                        </div>
                        <div className="why-sell-jivimart-text">
                          <p>
                              We have made the payment easy for you with a secured and wide range of options like UPI, Netbanking, Bhim Pay, Debit Card/Credit Card
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 wow fadeInUp animated">
                      <div className="why-sell-jivimart-innerbox position-relative headline  pera-content">
                        <div className="nio-eig-ft-icon-title">
                          <div className="why-sell-jivimart-icon float-left">
                            <img
                              src="./homePage/icon4.png"
                              className="site-logo"
                            />
                          </div>
                          <div className="why-sell-jivimart-title">
                            <h3>Ship your orders, stress-free</h3>
                          </div>
                        </div>
                        <div className="why-sell-jivimart-text">
                          <p>
                            Aivi Mart consists of an experienced Shipping Team who handles your
                            Products with care and delivers to the warehouse in time.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 wow fadeInUp animated">
                      <div className="why-sell-jivimart-innerbox position-relative headline pera-content">
                        <div className="nio-eig-ft-icon-title">
                          <div className="why-sell-jivimart-icon float-left">
                            <img
                              src="./homePage/icon4.png"
                              className="site-logo"
                            />
                          </div>
                          <div className="why-sell-jivimart-title">
                            <h3>Expand Your Target market </h3>
                          </div>
                        </div>
                        <div className="why-sell-jivimart-text">
                          <p>
                            If you have a physical business you are limited to a particular area but when your product is online you can expand your target market with a global audience  
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 wow fadeInUp animated">
                      <div className="why-sell-jivimart-innerbox position-relative headline pera-content">
                        <div className="nio-eig-ft-icon-title">
                          <div className="why-sell-jivimart-icon float-left">
                            <img
                              src="./homePage/icon4.png"
                              className="site-logo"
                            />
                          </div>
                          <div className="why-sell-jivimart-title">
                            <h3>Anyone can avail your business 24/7</h3>
                          </div>
                        </div>
                        <div className="why-sell-jivimart-text">
                          <p>
                            With an online market presence, you can sell your products at any point in time 24/7
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
              <div className="mt-5">
              <h1 >Start your Seller Journey</h1>
              <p style={{fontSize:18,textAlign: 'justify'}}>
                   AiviMart is the most reputed e-commerce marketplace in the Northeast, where every seller is increasing their sales, whether they are a manufacturer, retailer, or a supplier. We are a unique e-commerce platform where a local manufacturer in the Northeast can also sell their products to a broad audience. With a minimum investment, you can be the top player as a seller in your category.
              </p>
              <div>
              <Link to="/login">
                <button className="start-Selling">Start Selling</button></Link>
                </div>
            </div>
              </div>
              <div className="col-md-6">
                <img src="./homePage/image.png" style={{marginTop:-30}}/>
              </div>
            </div>
            </div>
            </div>
      </div>

      {/* Footer */}
      <div className="footer">
        <p > <Link style={{color : 'white' , textDecoration : 'none'}} to="/policy"> Seller Policy</Link></p>
        <p style={{marginTop:15}}>©2022 AiviMart. All rights reserved</p>
      </div>
    </div>
  );
}

export default Homepage;

