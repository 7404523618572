import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation , useNavigate ,useParams } from "react-router-dom";
import axios from "axios";

const CompanyBusinessDetails = (props) => {
  const {state} = useLocation();

  const navigate = useNavigate();
  // const {id} = useLocation();
  // const { id } = state;
  // const { state} = props.navigation; 
  // console.log(state,"Id From Page");
  
  const [errors , setErrors] = useState(false);
  const [disable, setDisable] = useState(true);
  const [ checkboxMsg , setCheckboxMsg ] = useState(true);
  const [aggrementCheck , setAggrementCheck ] = useState(false);
  console.log(errors , 'error errorss');

  const [data,setData] =useState({
    id: state ? state.id : '',
    bisiness_name :'',
    trade_lic_no : '',
    gst_no : '',
    pan_no : '',
    state : '',
    district : '',
    city : '',
    pin : '',
    street : '',
    land_mark : '',
    mobile : state ? state.user.mobile : '',
    alt_mobile : '',
    address : '',
    business_category : '',
    name_of_ac_owner : '',
    ac_no : '',
    bank_name : '',
    ifsc_code : '',
    bank_branch : '',
    business_email : '',
    business_description : '',
  });
  console.log(data, 'initialdata');
  console.log(data, 'initialdata');
  const[fetchStates,setFetchStates] = useState();
  const[ fetchCities, setFetchCities] = useState();
  const[ fetchDistricts, setFetchDistricts] = useState();
  const[ fetchBusinessCat, setFetchBusinessCat] = useState();
  const [selectedStateId , setSelectedStateId  ] = useState("");


    const handleCheckbox = () => {
      // setDisable(true);
      if( disable === true){
        setDisable(false);
        setCheckboxMsg(false);
      }else {
        setDisable(true);
        setCheckboxMsg(true);
      }
    }

    const handleSubmit= async () => {
        try {
          const response = await axios.post(`seller/registration/business` ,data);
          console.log('data', response.data);
          if(response.data.success){
            navigate('/registration/documentUpload', { state: { id:response.data.data.id,  user:response.data.data  } });
          }
          // console.log(response,'saijdio')
        }catch (error) {
          console.log('password data erros' , errors);
          if (error.response.status == 400) {
            setErrors(error.response.data.errors);
          }
        }
    };

    const fetchState = async () => {
      try {
        const response = await axios.get(`/seller/masters/state/list`);
        console.log("statedata",response.data);
        if (response.data.success){
          setFetchStates(response.data.data);
        }else{

        }
    } catch (error) {
        // console.log(error);
    }      
    };

    const fetchDistrict = async (state_id) => {
      console.log ('stateid', state_id);
      const data = {
        state_id :state_id
      }
      setSelectedStateId(state_id);
      try {
        const response = await axios.post(`/seller/district/dropdown/list`, data);
        console.log("districtdata",response.data);
          setFetchDistricts(response.data.data);
    } catch (error) {
        // console.log(error);
    }      
    };


    const fetchCity = async ( district) => {
      try {
        console.log("lklklklkl",district)
        const response = await axios.get(`/seller/masters/city/list/${selectedStateId}/${district}`);
        console.log("selectedStateIdv",selectedStateId)
        console.log("city" , response.data);
        if (response.data.success){
          setFetchCities(response.data.data);
        }

      }catch (error) {
        // console.log(error);
      }
    };

    const handleDistrict = ( district) => {
      // console.log(district  , 'fetch districts id');
      setData({
        ...data,
        // state : state, 
        district : district
      })
      fetchCity( district);
    }

    const handleState = (state) => {
  
      console.log("mmmm",state)
      setData({
        ...data,
        state : state, 
      })
      fetchDistrict(state);
      // console.log(state , 'handle state fetch districts');
      // fetchCity(state);
    }
    const fetchBusinessCategory = async () => {
      try {
        const response = await axios.get(`seller/masters/business/category`);
        // console.log("city" , response.data);
        if (response.data.success){
          setFetchBusinessCat(response.data.data);
        }else{

        }
      }catch (error) {
        // console.log(error);
      }
    };

  useEffect(() => {
    // window.scrollTo(0, 0);
    fetchState();
    fetchBusinessCategory();
    
  }, []);
  return (
    <div className="responsive-xs responsive-sm">
      {/* Header */}
      <div className="companyBusinessDetailsPageHeader">
        <div style={{ width: "15%" }}>
          <img src="/logo.webp" className="site-logo" />
        </div>
        <div>
          <Link to="/registration" style={{ textDecorationLine: "none" }}>
            <div className="login">Create New Account</div>
          </Link>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="a-section legal-page-fragment ng-scope">
            <div className="a-box seller_message_box">
              <div className="a-box-inner">
                <h1>Register and Start Selling</h1>
                <p className="seller_message_p"></p>
                <p className="seller_message_p">
                  Please have the following ready before you begin:
                </p>
                <ul className="mt-3">
                  <li className="seller_message_p">
                    Your bank account details for receiving payments from Aivimart
                  </li>
                  <li className="seller_message_p">
                    Tax (Trade Licence/PAN) details of your business
                  </li>
                </ul>
                <p className="seller_message_p">
                  Please ensure that all the information you submit is accurate.
                </p>
                <p></p>
                <h5 style={{ fontWeight: "bold" }}>
                  Enter details below to continue registration
                </h5>
              </div>
            </div>
          </div>
          <form  >
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <label className="form-label label-txt">
                    Company/Business name 
                  </label>
                  <input type="text" className="form-control " 
                    onChange={e => setData({...data, bisiness_name:e.target.value})}
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>The Business field is required.</p>)}
                </div>

                <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    Trade Licence Number
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e =>setData({...data, trade_lic_no:e.target.value}) }
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.trade_lic_no}</p>)}
                </div>

                <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    GST Number
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data , gst_no:e.target.value})}
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.gst_no}</p>)}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3 ">
                  <label className="form-label label-txt col-md-7">Pan No</label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data, pan_no:e.target.value})}
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.pan_no}</p>)}
                </div>
                <div className="col-md-3 ">
                  <label className="form-label label-txt col-md-7">State</label>
                  <select class="form-select" aria-label="Default select example"  
                  onChange={state => handleState(state.target.value)}>
                  
                    <option selected value=''> Select states</option>
                    {
                      fetchStates && fetchStates.map((item , index) => {
                        return (
                          <option  key={index} value={item.id}> {item.name}</option>
                      ) })
                    }
                  </select>
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.state}</p>)}
                </div>

                <div className="col-md-3 ">
                  <label className="form-label label-txt col-md-7">District</label>
                    <select class="form-select" aria-label="Default select example" 
                       onChange={district =>  handleDistrict(district.target.value)}>
                        <option selected value=''> Select District</option>
                        { fetchDistricts && fetchDistricts.map((item ,index) => {
                          return (
                            <option  key={index} value={item.id}> {item.name}</option>
                          )
                        })
                        }
                  </select>
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.district}</p>)}
                </div>
                <div className="col-md-3 ">
                  <label className="form-label label-txt col-md-7">City</label>
                    <select class="form-select" aria-label="Default select example"    onChange={e => setData({...data, city:e.target.value})}>
                        <option selected value=''> Select City</option>
                        { fetchCities && fetchCities.map((item ,index) => {
                          return (
                            <option  key={index} value={item.id}> {item.name}</option>
                          )
                        })
                        }
                  </select>
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.city}</p>)}
                </div>
                {/* </div> */}
                {/* <div className="row mt-2"> */}
                <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">Pin</label>
                  <input type="text" className="form-control" 
                    onChange ={e => setData({...data,  pin:e.target.value})}
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.pin}</p>)}
                </div>

                <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    Street
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data, street:e.target.value})}
                  />
                </div>

                <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    Landmark
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data , land_mark:e.target.value})}
                  />
                </div>
                <div className="row mt-2">
                <div className="col-md-12 ">
                  <label className="form-label label-txt col-md-7">
                    Address
                  </label>
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    onChange={e => setData({...data , address:e.target.value})}
                  ></textarea>
                </div>
              </div>
                </div>
                <div className="row mt-2">
                <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    Alt Mobile
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data , alt_mobile:e.target.value})}
                  />
                </div>
              <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    Bussiness  Type
                  </label>
                    <select class="form-select" aria-label="Default select example"    onChange={e => setData({...data, business_category:e.target.value})}>
                        <option selected value=''> Select Bussiness Type</option>
                        { fetchBusinessCat && fetchBusinessCat.map((item ,index) => {
                          return (
                            <option  key={index} value={item.id}> {item.name}</option>
                          )
                        })
                        }
                  </select>
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.business_category}</p>)}
                </div>
              <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    Bussiness Email
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data , business_email:e.target.value})}
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.business_email}</p>)}
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-md-12">
                  <label className="form-label label-txt col-md-7">
                    Bussiness Description
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data , business_description:e.target.value})}
                  />
                </div>
              </div>

              <div className="mt-5">
                <p style={{ fontWeight: "bold" }}>Seller Agreement</p>
                <div>
                  <label>
                    <input type="checkbox" 
                      onChange={() => handleCheckbox()}
                    />
                    <span style={{marginLeft:5}}>
                        I have read and agree to comply with and/or be bound by
                      the terms and conditions of
                      <span>
                        <a target="_blank" href="https://jivimart.com/additional/termsCondition">
                           AiviMart (B2B) Terms &amp; Conditions
                        </a>
                      </span>
                    </span>
                  </label>
                </div>
              </div>

              <div className="row mt-5 ">
                <h5 style={{ fontWeight: "bold" }} className="mb-4">
                  Bank Details
                </h5>
                <div className="col-md-4 ">
                  <label className="form-label label-txt">
                    Name of account Owner
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data , name_of_ac_owner:e.target.value})}
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.name_of_ac_owner}</p>)}
                </div>

                <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    Account Number
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data , ac_no:e.target.value})}
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.ac_no}</p>)}
                </div>
                <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    IFSC Code
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data, ifsc_code:e.target.value})}
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.ifsc_code}</p>)}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    Bank Name
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data , bank_name:e.target.value})}
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.bank_name}</p>)}
                </div>
                <div className="col-md-4 ">
                  <label className="form-label label-txt col-md-7">
                    Branch
                  </label>
                  <input type="text" className="form-control" 
                    onChange={e => setData({...data , bank_branch:e.target.value})}
                  />
                  {errors && errors && (<p style={{color: 'red',fontSize:12}}>{errors.bank_branch}</p>)}
                </div>
               
              </div>
              <div className="d-flex align-items-baseline">
                <div className=" mt-5 mb-1 text-center legal-page-continue-btn">
                {/* <Link
                to="/documentUpload"
                style={{ textDecorationLine: "none" }}
              >Continue</Link> */} 
                 <button disabled={disable} type="button"  style={{ textDecorationLine: "none" , border : "none" ,background : "none" }} onClick={()=>handleSubmit()} >Continue</button>
                </div>
                {checkboxMsg && checkboxMsg ? <p style={{color : 'grey' , fontSize : 12 , marginLeft :10}}>* Approve the Seller Agreement to Continue</p> : ''}
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <Outlet /> */}
    </div>
  );
};

export default CompanyBusinessDetails;
