import React from 'react';
import {Link} from 'react-router-dom';
import {Button,Form} from 'react-bootstrap';

const OrderSearch = () => {
    return (
        <div>
        <Form>
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-4">
              <label className="form-label label-txt">
                From Date
              </label>
              <input type="date" className="form-control"  placeholder='enter price'/>
            </div>
            <div className="col-md-4 ">
              <label className="form-label label-txt col-md-7">
                To Date
              </label>
              <input type="date" className="form-control "  placeholder='enter price'/>
            </div>
            <div className="col-md-4 ">
              <label className="form-label label-txt col-md-7">
                Select Status
              </label>
              <select class="form-select" aria-label="Default select example" >
                <option selected disabled> Select Status</option>
                <option value="1">Active Order</option>
                <option value="2">Delivered Order</option>
                <option value="3">Returned Order</option>
                <option value="4">Cancelled Order</option>
              </select>
            </div>
            </div>


          
          <div style={{textAlign: 'right'}} className="mt-4">
          <Link to="../orderSearchDetails" className="saveChangesBtn btn btn-xl btn-warning" >
            Search
        </Link>
        </div>
         </div>
      </Form>
        </div>
    );
};

export default OrderSearch;