import React, { useEffect, useState } from 'react';
// import { AiFillDelete } from 'react-icons/ai';
// import { BiPlusCircle } from 'react-icons/bi';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { logDOM } from '@testing-library/react';




const ProductInformation = (props) => {
  console.log(props, 'check props information')
  const handleDaySelect = (event) => {
    var dayArr = [...props.addProductData.policies];
    const value = event.target.value
    const index = dayArr.findIndex(day => day === value);
    if(index > -1) {
        dayArr = [...dayArr.slice(0, index), ...dayArr.slice(index + 1)]
    } else {
        dayArr.push(value);
    }
    props.setAddProductData({
      ...props.addProductData,
      policies : dayArr, 
    })
    // if (props.addProductData.policies) {
    //     var dayArr = [...props.addProductData.policies]
    // } else {
    //     var dayArr = [];
    // }
    // if(event.target.checked){
    //   if(dayArr.length  === 0){
    //     dayArr.push(event.target.value)  
    //   }
    //   for (let i = 0; i < dayArr.length; i++) {
    //     if(dayArr[i] != event.target.value){
    //       dayArr.push(event.target.value[i])
    //     }
    //   }
    // }else{
    // }
    // console.log(dayArr,'dayArr');
    // props.setAddProductData({
    //   ...props.addProductData,
    //   policies : dayArr, 
    // }) 
  };
console.log(props , 'check imnages new')
  return (
    <div>
      <div className="container mt-4 mb-4">
        <p style={{ fontWeight: "bold" }}>Fill Basic Information</p>
        {/* Product Basic Information */}
        <div className="row mt-2">
          {props.brandData =='' ? <></>:
          <div className="col-md-6 ">
            <label className="form-label label-txt col-md-7">Select Brand</label>
            <select className="form-select" aria-label="Default select example" 
              onChange={(e) =>{ 
                e.preventDefault();
                props.setAddProductData({...props.addProductData, brand_id:e.target.value})
              }
            }>
              <option defaultValue > Select  Brand</option>
              {props.brandData && props.brandData.map((item,index)=>{
                return(
                  <option key={index} value={item.brand_id}>{item.brand_name}</option>
                )
              })}
            </select>
            {props.errors && props.errors.brand_id ? <p style={{color : 'red' , fontSize : '12'}}>Brand Name is Required</p> : '' }
          </div>
          }

          <div className="col-md-6 ">
            <label className="form-label label-txt">Product Name</label>
            <input type="text" className="form-control " onChange={e => props.setAddProductData({...props.addProductData , product_name:e.target.value})}/>
            {props.errors && props.errors.brand_id ? <p style={{color : 'red' , fontSize : '12'}}>{props.errors.product_name}</p> : '' }
          </div>

          <div className="col-md-4 mt-2">
            <label className="form-label label-txt">Product Weight</label>
            <input type="text" className="form-control " onChange={e => props.setAddProductData({...props.addProductData , weight:e.target.value})}/>
            {props.errors && props.errors.weight ? <p style={{color : 'red' , fontSize : '12'}}>{props.errors.weight}</p> : '' }
          </div>
          <div className="col-md-4 mt-2">
            <label className="form-label label-txt">Product Height</label>
            <input type="text" className="form-control " onChange={e => props.setAddProductData({...props.addProductData , height:e.target.value})}/>
            {props.errors && props.errors.height ? <p style={{color : 'red' , fontSize : '12'}}>{props.errors.height}</p> : '' }
          </div>

          <div className="col-md-4 mt-2">
            <label className="form-label label-txt">Product Width</label>
            <input type="text" className="form-control " onChange={e => props.setAddProductData({...props.addProductData , width:e.target.value})}/>
            {props.errors && props.errors.width ? <p style={{color : 'red' , fontSize : '12'}}>{props.errors.width}</p> : '' }
          </div>
        </div>

        {/* Product Short Description */}
        <div className="row mt-2">
          <div className="col-md-12 ">
            <label className="form-label label-txt col-md-7">Short Description</label>
            <textarea
              className="form-control"
              rows={3}
              aria-label="With textarea" onChange={e => props.setAddProductData({...props.addProductData , short_description:e.target.value})}
            ></textarea>
            {props.errors && props.errors.short_description ? <p style={{color : 'red' , fontSize : '12'}}>{props.errors.short_description}</p> : '' }
          </div>
        </div>

        {/* Product Long Description */}
        <div className="row mt-2">
          <div className="col-md-12 " >
            <label className="form-label label-txt col-md-7">Long Description</label>
            <CKEditor 
                editor={ ClassicEditor }
                data="<p></p>"
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    props.setAddProductData({...props.addProductData , long_description:data})
                    console.log( { event, editor, data } );
                } }
                onBlur={ ( event, editor ) => {
                    // console.log( 'Blur.', editor );
                } }
                onFocus={ ( event, editor ) => {
                    // console.log( 'Focus.', editor );
                } }
            />
          </div>
        </div>

        {/* Multiple Sizes Selection */}
        <div className='mt-4'>
          <p style={{ fontWeight: "bold" }}>Do you have multiple size in your product?</p>
          <div className="col-md-4 ">
            <div className="form-check">
              <input type="radio" value="2" name="size" checked={props.sizeOption == '2'} onChange={(e) => props.onchangeSizeOption(e.currentTarget.value)}/> Yes
              <input type="radio" value="1" name="size" checked={props.sizeOption == '1'} onChange={(e) => props.onchangeSizeOption(e.currentTarget.value)} style={{marginLeft: 20}} /> No
            </div>
          </div>
        </div>

        {/* Product Image Selection */}
        <div className='mt-4'>
          <p style={{ fontWeight: "bold" }}>Add Product Images</p>
          <small style={{ color : 'red' }}>Photo (Max Resolution 450x450 and Size under 2 mb)</small>
          <div className="col-md-4 ">
            <label className="form-label label-txt col-md-7">Upload Product Image </label>
            <div className='imageUploadBlock'>
              <input type="file"  className="form-control"  onChange={props.handleChange} />
              {/* <input type="file" className="form-control" multiple 
              onChange={e => props.setAddProductData[({...props.addProductData , image:e.target.files[0]})]}/> */}
              <button type="button" className='imageUploadBtn btn btn-primary '>Add Product Images</button>
            </div>
          </div> 
          <div className='d-flex mt-3 column-gap-30' >
            {props.filePreview  && props.filePreview ?
                <div  >
                  {console.log(props.filePreview , 'picd')}
                  <img
                    src={props.filePreview.image}
                    style={{ width: 150, height: 120 }}
                  />
                </div> : ''
            }
          </div>
          {props.errors && props.errors ? <p style={{color : 'red' , fontSize : 12}}>{props.errors.image}</p> : ''}
        </div>

        {/* Product policies */}
        <div className='mt-4'>
          <p style={{ fontWeight: "bold" }}>Any Return Policy ?</p>
          <div className="col-sm-6">
            <div className="form-check">
              <input
                type="radio"
                name="radio1"
                value="2"
                checked={props.addProductData && props.addProductData.is_policy !== "1" && 'checked'}
                onChange={(e) => props.setAddProductData({...props.addProductData, is_policy:e.currentTarget.value})}
              /> No
              
              <input
                style={{marginLeft: 20}} 
                type="radio"
                value="1"
                name="radio1"
                checked={props.addProductData && props.addProductData.is_policy == "1" && 'checked'}
                onChange={(e) => props.setAddProductData({...props.addProductData, is_policy:e.currentTarget.value})}
              /> Yes
            </div>
          </div>
          <div className="col-sm-10">
            {props.addProductData && props.addProductData.is_policy == '1' && (
              <div className="col-sm-7 mt-3">
                <select className="form-select" aria-label="Default select example" onChange={e => props.setAddProductData({...props.addProductData , return_day:e.target.value})}>
                  <option selected disabled> Select Day</option>
                  <option value='15day'>15 Days Return</option>
                  <option value='30day'>30 Days Return</option>
                </select>
                <div className="col-md-12 mt-2">
                  <label className="form-label label-txt">Return Policy</label>
                  <textarea className="form-control" rows={5}
                    aria-label="With textarea" onChange={e => props.setAddProductData({...props.addProductData , return_policy:e.target.value})}
                  ></textarea>
              </div>
                {/* <div className="mt-3">
                  {props.policyList && props.policyList.map((item,index)=>{
                      return(
                        <div className="mb-12" key={index}>
                        <input
                        // onChange={(e) => props.setAddProductData({...props.addProductData, policies:e.currentTarget.value})}
                        onChange={handleDaySelect}
                        type="checkbox"
                        value={item.id}
                        name="check"
                      /> {item.type + ' - ' + item.return_policy}
                      </div>
                      )
                    })}
                </div> */}
              </div>
              )}
          </div>
        </div>
        {/* Divider */}
        <div style={{padding:30}}></div>

        {/* Product Add Button */}
        <div className="product-Modal-footer" style={{position: 'fixed', width: '100%',left: 0,bottom: 0, marginLeft: 0,backgroundColor:'#fff'}}>
          <button type="button" className="btn btn-light mx-1" onClick={() =>props.backBtnHandle()}>Back</button>
          <button type="button" className="btn btn-primary product-modal-saveBtn" onClick={() => props.AddProductData()} >Save & Continue</button>
        </div>
      </div>
    </div>
  );
};

export default ProductInformation;