
import React, { useEffect, useState } from "react";
import { BiBuildingHouse, BiEditAlt } from "react-icons/bi";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ImageThumb from '../../../component/imageThumb';

const Document = (props) => {
  const [show, setShow] = useState(false);
  const [infoGst , setInfoGst] = useState('');
  const [infoTrade , setInfoTrade] = useState('');
  const [infoPan , setInfoPan] = useState('');
   
    function handleChangePan(e) {
    props.setFilePreview({...props.filePreview, pan_image:URL.createObjectURL(e.target.files[0])});
    props.setSellerDocument({...props.sellerDocument, pan_image:e.target.files[0]});
    }
    function handleChangeGst(e) {
    props.setFilePreview({...props.filePreview, gst_image:URL.createObjectURL(e.target.files[0])});
    props.setSellerDocument({...props.sellerDocument, gst_image:e.target.files[0]});
    }
    function handleChangeTrade(e) {
    props.setFilePreview({...props.filePreview, trade_lic_image:URL.createObjectURL(e.target.files[0])});
    props.setSellerDocument({...props.sellerDocument, trade_lic_image:e.target.files[0]});
    }
    const keyHandleTrade = (event) => {
        if(event.key){setInfoTrade('Upload the new image');}
    };
    const keyHandlePan = (event) => {
        if(event.key){setInfoPan('Upload the new image');}
    };
    const keyHandleGst = (event) => {
        if(event.key){setInfoGst('Upload the new image');}
    };
    return (
        <div>
          <div className="section">
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                <div  className="d-flex justify-content-between">
                    <h4 className="h4 fw-bold" style={styles.header}>Document</h4>
                    <button className="profile-edit-btn"><BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={() => setShow(true)} /></button>
                    </div>
                  <div className=" mt-3">
                  <div className="row">
                    <div className="col-md-4">
                    <p className='mb-2' > 
                        <strong>Trade Lisence :</strong> {props.sellerProfile && props.sellerProfile.trade_lic_no}<br />
                        <ImageThumb image={props.sellerProfile && props.sellerProfile.trade_lic_image} />
                    </p>
                    </div>
                    <div className="col-md-4">
                    <p className='mb-2' > 
                        <strong>GST No. :</strong>  {props.sellerProfile && props.sellerProfile.gst} <br />
                        <ImageThumb image={props.sellerProfile && props.sellerProfile.gst_image} />
                    </p>
                    </div>
                    <div className="col-md-4">
                    <p className='mb-2' > 
                        <strong>Pan No. :</strong>{props.sellerProfile && props.sellerProfile.pan} <br />
                        <ImageThumb image={props.sellerProfile && props.sellerProfile.pan_image} />
                    </p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal size="lg" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Document Upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={props.updateDocument}  method="post">
                        <Row>
                            {/*  */}
                        <Row className="align-items-center">
                                <div className="col-md-8">
                                    <Form.Group className="col-md-12 mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Trade License.</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="GST Number"
                                            defaultValue={props.sellerProfile && props.sellerProfile.trade_lic_no}
                                            onChange={e =>props.setSellerDocument({...props.sellerDocument, trade_lic_no:e.target.value})}
                                            onKeyPress={(e)=> keyHandleTrade(e) }
                                            />
                                    </Form.Group>
                                    <Form.Group className="col-md-12 mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Trade License Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            placeholder="contact person"
                                            // onChange={e =>props.setSellerDocument({...props.sellerDocument, trade_lic_image:e.target.value})}
                                            // defaultvalue={props.sellerDocument && props.sellerDocument.trade_lic_image}
                                            onChange={handleChangeTrade}
                                            multiple={false}
                                            />
                                            {<p style={{color: 'red',fontSize:12}}> {infoTrade}</p>}
                                        {props.error && props.error.trade_lic_image && (<p style={{color: 'red',fontSize:12}}>{props.error.trade_lic_image}</p>)}
                                    </Form.Group>
                                </div>
                                <div className="preview text-center col-md-4" >
                                    <img
                                    src={props.filePreview && props.filePreview.trade_lic_image !== '' ? props.filePreview.trade_lic_image: props.sellerDocument.trade_lic_image}
                                    style={{ width: 100,height: 100 }}
                                    />
                                </div>
                            </Row>
                            {/*  */}
                            <Row className="align-items-center">
                                <div className="col-md-8">
                                    <Form.Group className="col-md-12 mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>GST No.</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="GST Number"
                                            defaultValue={props.sellerProfile && props.sellerProfile.gst}
                                            onChange={e =>props.setSellerDocument({...props.sellerDocument, gst:e.target.value})}
                                            onKeyPress={(e)=> keyHandleGst(e) }
                                            />
                                    </Form.Group>
                                    <Form.Group className="col-md-12 mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>GST Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            placeholder="contact person"
                                            // onChange={e =>props.setSellerDocument({...props.sellerDocument, gst_image:e.target.value})}
                                            // value={props.sellerDocument && props.sellerDocument.gst_image}
                                            onChange={handleChangeGst}
                                            multiple={false}
                                            />
                                            {<p style={{color: 'red',fontSize:12}}> {infoGst}</p>}
                                        {props.error && props.error.gst_image && (<p style={{color: 'red',fontSize:12}}>{props.error.gst_image}</p>)}
                                    </Form.Group>
                                </div>
                            <div className="preview text-center col-md-4" >
                            <img src={props.filePreview && props.filePreview.gst_image !== '' ? props.filePreview.gst_image: props.sellerDocument.gst_image}
                              style={{ width: 100,height: 100 }}
                            />
                            </div>
                            </Row>
                            {/*  */}
                            <Row className="align-items-center">  
                                <div className="col-md-8">
                            <Form.Group className="col-md-12 mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Pan No.</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="GST Number"
                                    defaultValue={props.sellerProfile && props.sellerProfile.pan}
                                    onChange={e =>props.setSellerDocument({...props.sellerDocument, pan:e.target.value})}
                                    onKeyPress={(e)=> keyHandlePan(e) }
                                    />
                            </Form.Group>
                            <Form.Group className="col-md-12 mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Pan Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    // value={props.sellerProfile && props.sellerProfile.pan_image}
                                    // onChange={e =>props.setSellerDocument({...props.sellerDocument, pan_image:e.target.value})}
                                    onChange={handleChangePan}
                                    multiple={false}
                                    />
                                    {<p style={{color: 'red',fontSize:12}}> {infoPan}</p>}
                                    {props.error && props.error.pan_image && (<p style={{color: 'red',fontSize:12}}>{props.error.pan_image}</p>)}
                            </Form.Group>
                                </div>
                            <div className="preview text-center col-md-4" >
                            <img
                            src={props.filePreview && props.filePreview.pan_image !== '' ? props.filePreview.pan_image: props.sellerDocument.pan_image}
                            style={{ width: 100,height: 100 }}
                            />
                            </div>
                            </Row>
                        </Row>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => props.setShow(false)}>
                                Close
                            </Button>
                            <Button className="saveChangesBtn" type="submit" 
                            onClick={() => setShow(false) }
                            >Save Changes</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Document;
const styles = {
    header: {textTransform: 'uppercase',color:'#149348',marginBottom:0},
    box : {background:'#e1e1e1',borderRadius:10, paddingLeft:10,paddingRight:10,padding:10,flex:1,boxShadow: '0 0 5px 0 #868686',border: '2px dotted #fff'},
    boxHeading : {color:'#333',fontWeight:'900',fontSize:15,marginBottom:0},
    boxNumber : {color:'green',fontWeight:'900',fontSize:25,marginBottom:0},
  }