import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation,useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import { Badge, Button, Table} from 'react-bootstrap';
import Loader from '../../component/loader';
import Modal from 'react-bootstrap/Modal';
import CategoryEditModal from '../productPage/ProductComponent/categoryEditModal';
import ProductPriceChange from '../productPage/ProductComponent/changePriceModal'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ProductImgEditModal from '../productPage/ProductComponent/productImgEditModal';
import ProductDetailsThumbImage from '../../component/productDetailsThumbImg';
import axios from 'axios';
import Cookies from 'js-cookie';
import {decrpt} from '../../component/encryption';
import EditMultipleProductModal from '../productPage/ProductComponent/editMultipleProductModal';
import EditProductFilterModal from '../productPage/ProductComponent/editProductFilterModal';
import ProductFilterModalEdit from "./ProductComponent/productFilterModalEdit";
import EditReturnPolicyModal from '../productPage/ProductComponent/editReturnPolicyModal';
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 }
// from 'react-html-parser';
import ReactHtmlParser from 'react-html-parser';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import AddSTockPrice from "./ProductComponent/addmodal";


function ProductSingle() {  
  const location = useLocation();
  const [editStockPriceMrp , setEditStockPriceMrp ] = useState();
  const [changePrice , setChangePrice ] = useState();
  const [editProductLongDescription , setEditProductLongDescription ] = useState('');
  const [addnewfilterModal , setAddnewfilterModal] = useState(false);
  const [ disabled , setDisabled ] = useState(true);
  const [ disabled2 , setDisabled2 ] = useState(true);
  const [filterAddModal, setFilterAddModal ] = useState('');
  // error state
  const [error , setError ] = useState();
  const [errorAddModal , setErrorAddModal] = useState();
  const [errorAddModalupdate , setErrorAddModalupdate] = useState();
  const [updatePriceError , setUpdatePriceError ] = useState('');
  const [longdescError , setLongdescError ] = useState('');
  // error state
  // message state
  const [messageAddModal , setMessageAddModal] = useState('');
  // message state
  const [show, setShow] = useState(false);
  const [editCatMode, setEditCatMode] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [longDesModal, setLongDesModal] = useState(false);
  const [priceChangeModal, setPriceChangeModal] = useState(false);
  const [filterEditModal, setFilterEditModal] = useState(false);
  const [editMultiProductSizeModal, setEditMultiProductSizeModal] = useState(false);
  const [loader,setLoader] = useState(false);
  const [image , setImage] = useState();
  const [selectedItems , setSelectedItems ] = useState();
  const [ allImages , setAllImages ] = useState();
  const [toastText , setToastText] = useState();
  const [file, setFile] = useState([]);
  const [selectedPhoto , setSelectedPhoto ] = useState();
  const [confirmDelete , setConfirmDelete] = useState(false);


  const [filterMasterId , setFilterMasterID] = useState();
  const [selectedDelete , setSelectedDelete] = useState();
  //1st category data
  const [categoryData, setCategoryData] = useState('');

  //2nd category data
  const [subCategoryData, setSubCategoryData] = useState('');
  
  //3rdt category data
  const [lastCategoryData, setLastCategoryData] = useState('');

  // Brand Array
  const [brandData, setBrandData] = useState('');
  const [productFilterValue, setProductFilterValue] = useState('');
  const [productAddFilter,setProductAddFilter] = useState ({
    product_filter_id:'',
    product_id: location.state.item,
    filter_master_id:'',
    filter_master_details_id:'',
})
  const [productAddFilterEdit ,setProductAddFilterEdit ] = useState ({
    product_filter_id:'',
    product_id: location.state.item,
    filter_master_id:'',
    filter_master_details_id:'',
})
  //product size array
  const [productSize, setProductSize] = useState('');
  // console.log(productSize,'size');
  
  //product filter array
  const [productFilter, setProductFilter] = useState('');
  // console.log(productFilter,'productFilter');

  const [productDetails,setProductDetails]=useState('');
  // console.log(productDetails,'productDetails');
  // const[updateProductData, setUpdateProductData]=useState({
  //   id: location.state && location.state.item,
  //   category_id: '',
  //   sub_category_id:'',
  //   third_category_id:'',
  //   brand_id:'',
  //   product_name:'',
  //   short_description:'',
  //   long_description:'',
  //   weight:'',
  //   height:'',
  //   width:'',
  //   is_policy:'2',
  //   return_day:'',
  //   return_policy: '',
  // });
  const[updateProductData, setUpdateProductData]=useState({
    id: location.state && location.state.item,
    category_id: productDetails && productDetails.category_id ? productDetails.category_id : '',
    sub_category_id:productDetails && productDetails.sub_category_id ? productDetails.sub_category_id : '',
    third_category_id:productDetails && productDetails.third_category_id ? productDetails.third_category_id : '',
    brand_id:productDetails && productDetails.brand_id ? productDetails.brand_id : '',
    product_name:productDetails && productDetails.name ? productDetails.name :  '',
    short_description:productDetails && productDetails.short_description ? productDetails.short_description :  '',
    long_description:productDetails && productDetails.long_description ? productDetails.long_description :'',
    weight:productDetails && productDetails.weight ? productDetails.weight : '',
    height:productDetails && productDetails.height ? productDetails.height :  '',
    width:productDetails && productDetails.width ? productDetails.width :  '',
    is_policy:'2',
    return_day:productDetails && productDetails.return_day ? productDetails.return_day : '',
    return_policy:productDetails && productDetails.return_policy ? productDetails.return_policy : '',
  });


  const fetchProductDitails = async() => {
    setLoader(true);
    const data = {
      product_id: location.state.item,
    }
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization']=`Bearer ${token}`;
      const response = await axios.post(`/seller/product/detail`,data);
      if (response.data.success) {
        setLoader(false);
        // setImage(response.data.data.product)
        // setMainImage(response.data.data.product.main_image)
        setAllImages(response.data.data.product)
        await setProductDetails(response.data.data.product);        
        // await fetchSubCategory(response.data.data.product.category_id);
        // await fetchLastCategory(response.data.data.product.sub_category_id);
        // await fetchCategory();
        await fetchBrandData(response.data.data.product.third_category_id);
        // const regex = /(<([^>]+)>)/ig;
        // const result = response.data.data.product.description.replace(regex, '');
        // setProductDetails({...productDetails,description:result})
        // console.log(image , ' check response')
        console.log(response.data , ' check response 2')
      } else {
        setLoader(false);
      }
      
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }  
  // console.log(productDetails,'productDetails');

  // const sizeMaster = async () => {
  //   try {
  //     await axios.post()
  //   }catch(error) {
  //     console.log(error , 'error')
  //   }
  // }
  //1st Category data fetch api
  const fetchCategory = async (id,edit) => {
    // setLoader(true);
    try {
    let token = decrpt(Cookies.get('s_l'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`/seller/category/list/dropdown`);
      if (response.data){
        await setCategoryData(response.data.data);
        // if(edit){
        //   fetchSubCategory(id,true);
        //   // console.log('category Edit Mode');
        // }else {
        //   fetchSubCategory(productDetails && productDetails.category_id);
        // }
        console.log(response.data , 'first cate check')
      } 
    }catch (error) {
      console.log(error);
      // setLoader(false);
    }
  };

  //2nd subCategory data fetch api
  const fetchSubCategory = async (item) => {
    // setLoader(true);
    console.log(item , 'item of first cat')
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/seller/category/sub/category/list/dropdown`,{category_id:item});
      if (response.data.success){
        console.log(response.data , 'check sub cetegory response')
        await setSubCategoryData(response.data.data);    
        setDisabled(false)   
        // if(edit){
        //   // fetchLastCategory(category_id,true);
        //   // console.log('Sub category Edit Mode');
        // }else {
        //   fetchLastCategory(productDetails && productDetails.sub_category_id);
        //   // console.log('New Load');
        // }
      } 
    }catch (error) {
      console.log(error);
      // setLoader(false);
    }

  };

  // 3rd lastCategory data fetch api
  const fetchLastCategory = async (item) => {
      // setLoader(true);
      let data ={
          category_id:item,
      }
      try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/seller/category/sub/category/list/dropdown`,data);
        // console.log(response,"third Category Called");
        if (response.data.success){
          await setLastCategoryData(response.data.data);
          setDisabled2(false)
          // if(edit){
          //   // fetchBrandData(category_id)
          //   // console.log('Last category Edit Mode');
          // }else {
          //   fetchBrandData(productDetails && productDetails.third_category_id);
          //   // console.log('New Load');
          // }
          setLoader(false);
        } else{
        // setLoader(false);
        }
      }catch (error) {
          console.log(error);
          // setLoader(false);
      }
  };

  //Fetch Update Category data fetch api
  const fetchUpdateCategory = async (item) => {
      console.log(item ,"item cate");
      // let data =JSON.parse(item);
      // if (data.level == '1') {
      //   setEditCatMode(true)
      //   fetchSubCategory(data.id,true);
      // }else if(data.level == '2'){
      //   setEditCatMode(true)
      //   fetchLastCategory(data.id,true);
      // }else {
      //   setEditCatMode(true)
      //   fetchBrandData(data.id,true);
      // }
        fetchSubCategory(item);

  };


  const handleCategoryFirst = (item) => {
      console.log(item ,"item cate");
      fetchSubCategory(item);
      setProductDetails({
        ...productDetails,
        category_id : item
      })
  }
  const handleCategorySecond = (item) => {
    console.log(item , 'seconds check')
    fetchLastCategory(item)
    setProductDetails({
      ...productDetails,
      sub_category_id : item
    })
  }
  const handleCategoryLast = (item) => {
    console.log(item , 'seconds check')
    setProductDetails({
      ...productDetails,
      third_category_id : item
    })
  }


  //fetch brand api
  const fetchBrandData = async (third_category_id) => {
      // setLoader(true);
      // console.log(third_category_id,'thirdCategoryId')
      try {
          let token = decrpt(Cookies.get('s_l'));
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const response = await axios.post(`/seller/product/brand/size/filter/fetch`,{category_id:third_category_id});
          // console.log(response.data,'response fetch filter');
          if (response.data.success){
              console.log(response.data.data,'brand name');
              setBrandData(response.data.data.brand_list)
              setProductSize(response.data.data.size_list)
              setProductFilter(response.data.data.filter_list)
              // setLoader(false);
          } else{
              // setLoader(false);
          }
      }catch (error) {
          console.log(error);
          // setLoader(false);
      }
  };  

  //add product category and information

  const [productPrice,setProductPrice] = useState ({
    product_id: location.state.item,
    stock:'',
    mrp:'',
    price:'',
    size:'',
    size_name:'',
    type: productDetails.size_type,
    product_size_id:'',
  })
 
  let prodetails = productDetails && productDetails.description;

 

  const handleImages = (selectedImages) => {
    console.log(selectedImages, 'item check')
    setSelectedItems(selectedImages);
    setImgModal(true);
  }


  const updateInfoProduct = async () => {
    try {
      const data = {
        id: location.state && location.state.item,
        category_id: productDetails && productDetails.category_id ? productDetails.category_id :  '',
        sub_category_id:productDetails && productDetails.sub_category_id ? productDetails.sub_category_id : '',
        third_category_id:productDetails && productDetails.third_category_id ? productDetails.third_category_id :  '',
        brand_id:productDetails && productDetails.brand_id ? productDetails.brand_id :  '',
        product_name:productDetails && productDetails.name ? productDetails.name :  '',
        short_description:productDetails && productDetails.short_description ? productDetails.short_description :  '',
        long_description:productDetails && productDetails.long_description ? productDetails.long_description :  '',
        weight:productDetails && productDetails.weight ? productDetails.weight :  '',
        height:productDetails && productDetails.height ? productDetails.height :  '',
        width:productDetails && productDetails.width ? productDetails.width :  productDetails && productDetails.width,
        is_policy:1,
        return_day:productDetails && productDetails.return_day ? productDetails.return_day : '',
        return_policy:productDetails && productDetails.return_policy ? productDetails.return_policy : '',
      }

      console.log(data , 'check api before initialized')
        await axios.post(`/seller/product/add` , data  )
        .then(res => {
          fetchProductDitails();
          setShow(false)
          console.log(res , 'update info')
        })
    }catch(error) {
      console.log(error , 'error info')
      setError(error.response.data.errors)
    }
  }

  // console.log(error && error , 'error check state ')
  const addSizeStockPrice = async () => {
    try {
      const data = {
        product_id: location.state && location.state.item,
        stock:      productDetails && productDetails.stock ? productDetails.stock :  '',
        mrp:        productDetails && productDetails.mrp ? productDetails.mrp :  '',
        price:      productDetails && productDetails.price ? productDetails.price :  '',
        size:       productDetails && productDetails.size_id ? productDetails.size_id :  null,
        type :      productDetails && productDetails.size_type == null ? 2 :  productDetails.size_type 
      }
      console.log('before call data ' , data)
      await axios.post(`/seller/product/size/add` , data)
      .then(res => {
        console.log(res , 'check size add res')
        fetchProductDitails()
        setAddnewfilterModal(false)
      })
    }catch(error) {
      console.log(error , 'error size aadd')
      setAddnewfilterModal(true);
      if(error.response.data.errors ){
        setMessageAddModal(error.response.data.errors);
      } else if (error.response.data.message) {
        setErrorAddModal(error.response.data.message);
      } else {
        console.log('other stuff')
      }
    }
  }

// console.log(editStockPriceMrp , 'editStockPriceMrpeditStockPriceMrp')
  const updateSizeStockPrice = async () => {
    const data = {
      product_id: location.state && location.state.item,
        stock:      editStockPriceMrp.stock ,
        mrp:        editStockPriceMrp.mrp ,
        price:      editStockPriceMrp.price ,
        size:       editStockPriceMrp.size_id ,
        type :      2  ,
        product_size_id : editStockPriceMrp && editStockPriceMrp.id
    }
    try {
      console.log(data , 'check datat')
      await axios.post(`/seller/product/size/add` ,data ).then(res => {
        console.log(res , 'check update data')
        fetchProductDitails()
      setAddnewfilterModal(false);
      setEditMultiProductSizeModal(false)
      })
    }catch(error) {
      console.log(error , 'error size')
      if(error.response.data.message ){
        setMessageAddModal(error.response.data.message);
      } else if (error.response.data.errors) {
        setErrorAddModal(error.response.data.errors);
      } else {
        console.log('other stuff')
      }
    }
  }


// console.log(editStockPriceMrp , 'editStockPriceMrp check')
// for images----------------------------------------------------------------------------------
const   handleChange = (e) => {
    // setSelectedPhoto([]);
    // if(e.target.files){
    //   const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
    //   setSelectedPhoto((prevImages) => prevImages.concat(filesArray));
    //   Array.from(e.target.files).map(
    //     (file) => URL.revokeObjectURL(file)
    //     );
    //   console.log(filesArray , 'chekc files filesArray')
    //   console.log(file , 'chekc files file')
    // }
    // console.log(selectedPhoto && selectedPhoto , 'chekc files now')
    setSelectedPhoto(e.target.files[0]);
    console.log(selectedPhoto && selectedPhoto , ' selectedPhotoselectedPhoto')

}

 async function uploadImagestuff ()  {
  setLoader(true);
  try {
    
    console.log(selectedPhoto, 'files check')
    const formData = new FormData();
    formData.append("product_id", allImages && allImages.id);
    formData.append("image", selectedPhoto && selectedPhoto);
    for(const value of formData.values()){
      console.log(value,'fromdata check');
  }
    const response = await axios.post(`/seller/product/image/upload` , formData )
    .then(response => {
      console.log(response.data , 'doasjdfoisajdoj')
      fetchProductDitails();
      setImgModal(false)
    })
  }catch(error) {
    console.log(error , 'error fsdfsd')
  }
}
// for images----------------------------------------------------------------------------------

const fetchChangePrice = async () => {
    setLoader(true);
    try {
      const data = {
        product_id : location.state && location.state.item, 
        mrp : changePrice.mrp ,
        price : changePrice.price,
        stock : changePrice.stock,
        type : 1
      }
      console.log(data , 'data check')
     const response = await axios.post(`/seller/product/size/add` , data )
      .then(response => {
        fetchProductDitails();
        setPriceChangeModal(false);
        console.log(response, 'check api response')
        setLoader(false);
      })
    }catch(error) {
      console.log(error , 'error')
      setLoader(false);
      setUpdatePriceError(error.response.data.errors)
    }
}


  const updateProductLongDescription = async () => {
    try {
      const data = {
        id: location.state && location.state.item,
        category_id:        productDetails && productDetails.category_id ? productDetails.category_id : '',
        sub_category_id:    productDetails && productDetails.sub_category_id ? productDetails.sub_category_id : '',
        third_category_id:  productDetails && productDetails.third_category_id ? productDetails.third_category_id : '',
        brand_id:           productDetails && productDetails.brand_id ? productDetails.brand_id : '',
        product_name:       productDetails && productDetails.name ? productDetails.name :  '',
        short_description:  productDetails && productDetails.short_description ? productDetails.short_description :  '',
        long_description:   editProductLongDescription && editProductLongDescription.long_description,
        is_policy:'2',
      }
      await axios.post(`/seller/product/add`,data)
      .then(res => {
        console.log(res , 'check res')
        fetchProductDitails();
        setLongDesModal(false);
      })
    }catch(error) {
      console.log(error , 'error')
      setLongdescError(error.response.data.errors)
    }
  }


const  handleFilter = async () => {
  try {
    const data = {
      product_id : productDetails && productDetails.id,
      brand_id : productDetails && productDetails.brand_id
    }
    await axios.post(`/seller/product/filter/fetch`, data)
    .then(res => {
      console.log(res , 'check brand res')
    })
  }catch(error) {
      console.log(error, 'error')
  }
}
// console.log( productDetails , 'setProductDetails')

const DeleteImage = async (item) => {
  // console.log(item , 'item check thumb')
      setLoader(true);
  try {
    const data = {
      product_id : selectedItems.id && selectedItems.id,
      image_id : selectedDelete && selectedDelete
    }
    // console.log(data , 'setConfirmDelete')
    setLoader(true);
    const response = await axios.post(`/seller/product/image/delete` , data )
    .then(response => {
      // console.log(response.data , 'thumb response')
      fetchProductDitails();
      setImgModal(false);
      setLoader(false);
      setConfirmDelete(false)
    })
  }catch(error) {
    console.log(error, 'error')
    setLoader(false);
  }
}


  const handleDelete = (item) => {
    setConfirmDelete(true);
    setSelectedDelete(item.id)
  }

  const filterFetchListMaster = async()=>{
    // setLoader(true); 
    try {
      const data ={product_id :  productDetails && productDetails.id}
      // console.log(data , '{product_id :  productDetails && productDetails.id}')
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`seller/product/filter/fetch` , data)
          .then((res) => {
            console.log(res , 'check master filter')
            setFilterMasterID(res.data.data.list)
          })

        // setLoader(false); 
    } catch (error) {
        console.log(error,'error cs');
        setLoader(false); 
    }
}


  const handleaddNewFilter = () => {
    filterFetchListMaster();
    setFilterAddModal(true);
  }

  const handleFilterAdd = (item) => {
    console.log(item,'filter data');
    let tmparray = productFilter;
    let obj = tmparray.find((o, i) => {
        console.log(o,'ooo');
        if (o.filter_master_id == item) {
            setProductFilterValue(o.values);
            console.log(productFilterValue && productFilterValue , 'productFilterValue check')
        }
    });
    setProductAddFilter({...productAddFilter, filter_master_id:item})
  }
  const handleFilterEdit = (item) => {
    console.log(item,'filter data');
    let tmparray = productFilter;
    let obj = tmparray.find((o, i) => {
        console.log(o,'ooo');
        if (o.filter_master_id == item) {
            setProductFilterValue(o.values);
            console.log(productFilterValue && productFilterValue , 'productFilterValue check')
        }
    });
    setProductAddFilterEdit({...productAddFilterEdit, filter_master_id:item})
  }
  const handleFilterDetails=(item)=>{
    setProductAddFilter({...productAddFilter, filter_master_id:item})
    setProductAddFilter({...productAddFilter, filter_master_details_id:item})
}
  const handleFilterDetailsEdit=(item)=>{
    setProductAddFilterEdit({...productAddFilterEdit, filter_master_id:item})
    setProductAddFilterEdit({...productAddFilterEdit, filter_master_details_id:item})
}

const handleEditProductFilter = async (item) => {
   setFilterEditModal(true)
   console.log(item,'productFilterItem');
  await setProductAddFilter({
      product_filter_id:item.id,
      product_id:location.state,
      filter_master_id:item.main_category_id,
      filter_master_details_id:item.sub_category_id,        
  });
}
console.log(productAddFilterEdit && productAddFilterEdit , 'productAddFilter hioli')

// add new filter 
  const addNewFilter = async () => {
    try {
    setLoader(true);
    let token = decrpt(Cookies.get('s_l'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axios.post(`/seller/product/filter/add` , productAddFilter )
        await  fetchProductDitails();
        setLoader(false);
        setFilterAddModal(false)
    }catch(error) {
      console.log(error , 'error')
        setLoader(false);
        setFilterAddModal(true);
        setError(error.response.data.message)
    }
  }
  const EditNewFilter = async () => {
    try {
    setLoader(true);
    let token = decrpt(Cookies.get('s_l'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axios.post(`/seller/product/filter/add` , productAddFilter )
        await  fetchProductDitails();
        setLoader(false);
        setFilterAddModal(false)
    }catch(error) {
      console.log(error , 'error')
        setLoader(false);
        setFilterAddModal(true);
        setError(error.response.data.message)
    }
  }


  useEffect (() => {
    window.scrollTo(0, 0);
    fetchProductDitails(); 
    fetchCategory(); 
    
  }, []);

  useEffect(() => {
    setProductPrice({
        ...productPrice,
        product_id: location.state.item,
        stock:productDetails.stock,
        mrp:productDetails.mrp,
        price:productDetails,
        size:productDetails.size_id,
        type: productDetails.size_type,
        product_size_id:productDetails.id,
    })
  },[productDetails]);

  useEffect(() => {
    setUpdateProductData({
      ...updateProductData,
      product_id: location.state && location.state.item,
    category_id: productDetails && productDetails.category_id ? productDetails.category_id :  '',
    sub_category_id:productDetails && productDetails.sub_category_id ? productDetails.sub_category_id : '',
    third_category_id:productDetails && productDetails.third_category_id ? productDetails.third_category_id :  '',
    brand_id:productDetails && productDetails.brand_id ? productDetails.brand_id :  '',
    product_name:productDetails && productDetails.name ? productDetails.name :  '',
    short_description:productDetails && productDetails.short_description ? productDetails.short_description :  '',
    long_description:productDetails && productDetails.long_description ? productDetails.long_description :  '',
    weight:productDetails && productDetails.weight ? productDetails.weight :  '',
    height:productDetails && productDetails.height ? productDetails.height :  '',
    width:productDetails && productDetails.width ? productDetails.width :  updateProductData && updateProductData.width,
    is_policy:'2',
    return_day:productDetails && productDetails.return_day ? productDetails.return_day : '',
    return_policy:productDetails && productDetails.return_policy ? productDetails.return_policy : '',
    })
  }, [productDetails]);
// console.log(productDetails , 'prouct details check')
  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
          {/* New User Registration */}
          <div className="section">
              <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>      
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="d-flex justify-content-between mb-2">
                          <h3 className="h5 fw-bold" style={styles.header}>{productDetails && productDetails.name}</h3>
                          <button className="product-deatils-edit-Btn"><BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={() => setShow(true)} /></button>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <label>{productDetails && productDetails.category}</label>
                        &nbsp; <AiOutlineRight /> &nbsp; 
                        <label>{productDetails && productDetails.sub_category}</label>
                        &nbsp; <AiOutlineRight /> &nbsp; 
                        <label>{productDetails && productDetails.third_category}</label>
                      </div>
                      
                      <div className="d-flex mb-2">
                        <label><strong>Brand </strong></label>
                        <p className="mb-2" style={{paddingLeft:10}}><Badge bg="primary" text="light">{productDetails && productDetails.brand}</Badge></p>
                      </div>

                      <div className="d-flex">
                      <div >
                        {productDetails && productDetails.weight != null ? 
                        <div className="d-flex mb-2" style={{marginRight: 30}}>
                          <label><strong>Weight : </strong></label>
                          <p className="mb-1" style={{paddingLeft:10}}>{productDetails && productDetails.weight} (gm)</p>
                        </div>:''
                        }
                      </div>
                      
                      <div>
                      {productDetails && productDetails.height != null ?
                      <div className="d-flex mb-2" style={{marginRight: 30}}>
                        <label><strong>Height : </strong></label>
                        <p className="mb-1" style={{paddingLeft:10}}>{productDetails && productDetails.height} (inch)</p>
                      </div>:''
                    }
                    </div>
                    
                      <div>
                      {productDetails && productDetails.width != null ?
                      <div className="d-flex mb-2">
                        <label><strong>Width : </strong></label>
                        <p className="mb-1" style={{paddingLeft:10}}>{productDetails && productDetails.width} (inch)</p>
                      </div>:''
                    }
                    </div>
                  </div>
                  <div className="row">
                     {/* Shipping Address */}
                      <div>
                        <label className="mb-0"><strong>Product Short Description</strong> </label> 
                        <p className="mb-0" style={{fontSize:15}}> {productDetails && productDetails.short_description}</p>
                      </div>
                  </div>
                  </div>
                    <div className="col-md-3">
                      <div className="d-flex gap-2 py-3">
                        {
                          allImages && allImages ?
                              <>
                                  <div className="product-single text-center">
                                    <img src={allImages  && allImages.main_image} />
                                  </div>
                                  <div className="d-flex">
                                    <div  className="productDetails-thumbnail d-flex flex-column">
                                        {
                                          allImages && allImages.images.slice(0,3).map((item,index) => {
                                            return (
                                              <>
                                                <ProductDetailsThumbImage key={index} image={item.image}  className="mb-2"></ProductDetailsThumbImage>
                                              </>
                                            );
                                          })
                                        }
                                        </div>
                                        <button className="product-img-edit-Btn"><BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={() => handleImages(allImages)} /></button>
                                  </div>
                              </>
                            : ''
                            }
                                </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //size and price */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>Product Price and stock Details</h5>
                    {productDetails && productDetails.size_type == '1' ?
                    '' :
                    <button type="button" onClick={() =>{setAddnewfilterModal(true) ; setMessageAddModal(false) } }  className="btn btn-success btn-sm" >Add New</button>
                  }
                  </div>
                  <div>
                      {productDetails && productDetails.size_type == '1' ?
                      <div>
                      <div className="d-flex">
                        <div className="d-flex">
                          <label><strong>MRP : </strong></label>
                          <p className="mb-1" style={{paddingLeft:10}}>₹ {productDetails && productDetails.mrp}</p>
                        </div>
                        
                        &nbsp;&nbsp; | &nbsp;&nbsp; 
                      
                        <div className="d-flex">
                          <label><strong>Price : </strong></label>
                          <p className="mb-1" style={{paddingLeft:10}}>₹ {productDetails && productDetails.price}</p>
                        </div>
                        
                        &nbsp;&nbsp; | &nbsp;&nbsp; 
                      
                        <div className="d-flex">
                            <label><strong>Stock : </strong></label>
                            <p className="mb-1" style={{paddingLeft:10}}>{productDetails && productDetails.stock}</p>
                        </div>
                     </div>
                        <button className="mb-2 mt-2 product-details-changePrice" onClick={() =>{setPriceChangeModal(true) ;setChangePrice(productDetails) }} >Change Price</button>
                      </div>:
                      <table className='table tabe-bordered'>
                        <thead>
                            <tr className="text-center">
                                {/* <th>SL</th> */}
                                <th>Size</th>
                                <th>MRP</th>
                                <th>Price</th>
                                <th>Stock</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {productDetails && productDetails.stock_details.map((item,index)=>{
                          return(
                            <tr className="text-center" key={index}>
                                {/* <td>{item.id}</td> */}
                                <td>{item.size}</td>
                                <td>₹ {item.mrp}</td>
                                <td>₹ {item.price}</td>
                                <td>{item.stock}</td>
                                <td><button className="product-deatil-edit-Btnn"><BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={() => {setEditMultiProductSizeModal(true); setEditStockPriceMrp(item)}  } /></button></td>
                            </tr>
                            )
                          })
                        }
                        </tbody>
                    </table>
                      }
                  </div>
                </div>
              </div>
              {/* long description */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>Product Long Description</h5>
                      <button className="product-deatil-edit-Btnn"><BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={() => {setLongDesModal(true); setEditProductLongDescription(productDetails && productDetails.description) }} /></button>
                  </div>
                  <div>
                  <div>
                      <p className="mb-0" style={{fontSize:15}}>
                      { ReactHtmlParser(prodetails) }
                        {/* {productDetails && productDetails.description} */}
                        </p>
                  </div>
                  </div>
                </div>
              </div>
              {/* fiter List */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>Product Filter Details</h5>
                      <button className="btn btn-sm btn-success text-white" onClick={() => handleaddNewFilter()}>Add New</button>
                      {/* <button className="btn btn-sm btn-success text-white" onClick={() => setFilterAddModal(true)}>Add New</button> */}
                  </div>
                  <table className='table tabe-bordered'>
                      <thead>
                          <tr className="text-center">
                              {/* <th>SL</th> */}
                              <th>Name</th>
                              <th>Details</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                        <tbody>
                        {productDetails && productDetails.filters.map((item,index)=>{
                          return(
                            <tr className="text-center" key={index}>
                                {/* <td>{item.id}</td> */}
                                <td>{item.name}</td>
                                <td>{item.value}</td>
                                <td><button className="product-deatil-edit-Btnn"><BiEditAlt style={{ color: '#000', fontSize: 18 }} 
                                onClick={() => handleEditProductFilter(item)} 
                                /></button></td>
                            </tr>
                            )
                          })
                        }
                        </tbody>
                    </table>
                    </div>
                  </div>
              {/* return policy */}
              <div className="col-md-12">
                <div className="card mb-3 p-2">
                <div className="card-title d-flex align-items-center justify-content-between">
                    <h5>Return policy Details</h5>
                      {/* <button className="product-deatil-edit-Btnn"><BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={() => setEditReturnPolicyModal(true)} /></button> */}
                  </div>
                  <div>
                  {productDetails && productDetails.return_day!= 0 || productDetails && productDetails.return_policy != null ?
                  <div>
                    {productDetails && productDetails.return_day != null ? 
                      <p className="mb-0" style={{fontSize:15}}> Return Days : {productDetails && productDetails.return_day}</p>:''
                    }
                    {productDetails && productDetails.return_day != null ? 
                      <p className="mb-0" style={{fontSize:15}}> Return Policy : {productDetails && productDetails.return_policy}</p>:''
                    }
                  </div>:
                    <p className="mb-0" style={{fontSize:15}}>No Return policy here</p>
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
            {/* edit category section modal */}
            <Modal show={show} onHide={() => setShow(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Update Info</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mb-5">
                  <CategoryEditModal 
                  editCatMode={editCatMode}
                  productDetails={productDetails}
                  setProductDetails={setProductDetails}
                  categoryData={categoryData} 
                  fetchSubCategory={fetchSubCategory} 
                  subCategoryData={subCategoryData}  
                  fetchLastCategory={fetchLastCategory} 
                  setSubCategoryData={setSubCategoryData}
                  lastCategoryData={lastCategoryData}  
                  setLastCategoryData={setLastCategoryData}
                  brandData={brandData}
                  fetchBrandData={fetchBrandData}
                  fetchUpdateCategory={fetchUpdateCategory}
                  // by mk
                  setUpdateProductData={setUpdateProductData}
                  handleCategoryFirst={handleCategoryFirst}
                  disabled={disabled}
                  disabled2={disabled2}
                  handleCategorySecond={handleCategorySecond}
                  handleCategoryLast={handleCategoryLast}
                  error={error}
                  />
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="secondary" onClick={() => {setShow(false) ; fetchProductDitails();}}>
                        Close
                    </Button>
                    <Button className="saveChangesBtn" type="button" onClick={() => updateInfoProduct()}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* edit image modal */}
            <Modal show={imgModal} onHide={() => setImgModal(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title> Update Image  {'\n'} <small style={{fontSize : 12 , color : 'red'}}>(Max Image Limit 5)</small></Modal.Title>
                    
                </Modal.Header>
                {/* <form 
                // onSubmit={(e) =>uploadImagestuff(e)} encType="Multipart/form-data"
                 > */}
                  <Modal.Body> 
                    <ProductImgEditModal selectedItems={selectedItems} fetchProductDitails={fetchProductDitails} setImgModal={setImgModal} uploadImagestuff={uploadImagestuff} 
                      setFile={setFile} file={file}  handleChange={handleChange} setConfirmDelete={setConfirmDelete} handleDelete={handleDelete}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                      <Button variant="secondary" onClick={() => setImgModal(false)}>
                          Close
                      </Button>
                      <Button className="saveChangesBtn" type="submit" onClick={() => uploadImagestuff()}>
                          Save Changes
                      </Button>
                  </Modal.Footer>
                {/* </form> */}
            </Modal>

                 {/* for deete confirmation */}
                 <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)} size="sm" centered>
                      <Modal.Body>
                      <h6> Are you sure to Delete ?</h6>
                            <Button variant="secondary" className="btn-sm me-2" onClick={() => setConfirmDelete(false)}>
                                Close
                            </Button>
                            <Button className="saveChangesBtn btn-sm text-dark" onClick={() => DeleteImage()}>Delete</Button>
                        </Modal.Body>
                </Modal>
                 {/* for deete confirmation */}

              {/* edit price  change one size product modal */}
              <Modal show={priceChangeModal} onHide={() => setPriceChangeModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Update Price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ProductPriceChange updatePriceError={updatePriceError} changePrice={changePrice} setChangePrice={setChangePrice} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setPriceChangeModal(false)}>
                        Close
                    </Button>
                    <Button className="saveChangesBtn" onClick={() => fetchChangePrice()}>
                        Save Changes
                    </Button>
                </Modal.Footer>
              </Modal>

              {/* edit price & Stock multi size modal */}
                      <Modal show={editMultiProductSizeModal} onHide={() => setEditMultiProductSizeModal(false)} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Update Size Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <EditMultipleProductModal
                          productDetails={productDetails} setProductDetails={setProductDetails}
                          productSize={productSize} editStockPriceMrp={editStockPriceMrp} setEditStockPriceMrp={setEditStockPriceMrp}
                          errorAddModal={errorAddModal} messageAddModal={messageAddModal}
                          errorAddModalupdate={errorAddModalupdate}
                          />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setEditMultiProductSizeModal(false)}>
                                Close
                            </Button>
                            <Button className="saveChangesBtn" onClick={() => updateSizeStockPrice()}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
              {/* edit price & Stock multi size modal */}

                {/* edit filter modal */}
                    <Modal show={filterAddModal} onHide={() => setFilterAddModal(false)} size="md">
                      <Modal.Header closeButton>
                          <Modal.Title>Add Filter Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {
                          error && error ? <p style={{color : 'red' , fontSize : 12}}>{error}</p> : ''
                        }
                        <EditProductFilterModal 
                         productFilter={productFilter} handleFilter={handleFilter} setFilterAddModal={setFilterAddModal}  productDetails={productDetails.filters} filterMasterId={filterMasterId} handleFilterAdd={handleFilterAdd}
                         productFilterValue={productFilterValue} 
                         handleFilterDetails={handleFilterDetails}
                         />
                      </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setFilterAddModal(false)}>
                                Close
                            </Button>
                            <Button className="saveChangesBtn" onClick={() => addNewFilter()}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                {/* edit filter modal */}
                {/* edit filter modal */}
                    <Modal show={filterEditModal} onHide={() => setFilterEditModal(false)} size="md">
                      <Modal.Header closeButton>
                          <Modal.Title>Edit Filter Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <ProductFilterModalEdit  productFilter={productFilter}   productFilterValue={productFilterValue} 
                        handleFilterEdit={handleFilterEdit} handleFilterDetailsEdit={handleFilterDetailsEdit}/>
                      </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setFilterEditModal(false)}>
                                Close
                            </Button>
                            <Button className="saveChangesBtn" onClick={() => EditNewFilter()}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                {/* edit filter modal */}
                    {/* 
                      <Modal show={editReturnPolicyModal} onHide={() => setEditReturnPolicyModal(false)} size="lg">
                          <Modal.Header closeButton>
                              <Modal.Title>Update Return Policy</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <EditReturnPolicyModal  productDetails={productDetails}  setProductDetails={setProductDetails} />
                          </Modal.Body>
                          <Modal.Footer>
                              <Button variant="secondary" onClick={() => setEditReturnPolicyModal(false)}>
                                  Close
                              </Button>
                              <Button className="saveChangesBtn" onClick={() => returnPolicy()}>
                                  Save Changes
                              </Button>
                          </Modal.Footer>
                    </Modal> 
                  */}
                 
                  {/* add produyct stock size */}
                        <Modal show={addnewfilterModal} onHide={() => setAddnewfilterModal(false)} size="md">
                              <Modal.Header closeButton>
                                  <Modal.Title style={{textTransform : 'uppercase' , fontSize : 16}}>Add New PRODUCT PRICE AND STOCK DETAILS</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <AddSTockPrice 
                                      productDetails={productDetails} setProductDetails={setProductDetails}
                                      productSize={productSize} errorAddModal={errorAddModal} messageAddModal={messageAddModal}
                                />
                              </Modal.Body>
                              <Modal.Footer>
                                  <Button variant="secondary" onClick={() => setAddnewfilterModal(false)}>
                                      Close
                                  </Button>
                                  <Button className="saveChangesBtn" onClick={() => addSizeStockPrice()}>
                                      Save Changes
                                  </Button>
                              </Modal.Footer>
                      </Modal>
                  {/* add produyct stock size */}

                   {/* edit log description modal */}
                      <Modal show={longDesModal} onHide={() => setLongDesModal(false)} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Product Long Description</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={productDetails && productDetails.description}
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setEditProductLongDescription({...editProductLongDescription, long_description:data})
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                        {longdescError && longdescError ? <p style={{color : 'red' , fontSize : 12}}>{longdescError.description}</p> : ''}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setLongDesModal(false)}>
                                Close
                            </Button>
                            <Button className="saveChangesBtn" onClick={() => updateProductLongDescription(false)}>Save Changes</Button>
                        </Modal.Footer>
                      </Modal>
                   {/* ed  it log description modal */}

        </>

        
      }
    </div>

  );
}

export default ProductSingle;
const styles = {
  header: {textTransform: 'uppercase',color:'#149348',marginBottom:0},
  box : {background:'#e1e1e1',borderRadius:10, paddingLeft:10,paddingRight:10,padding:10,flex:1,boxShadow: '0 0 5px 0 #868686',border: '2px dotted #fff'},
  boxHeading : {color:'#333',fontWeight:'900',fontSize:15,marginBottom:0},
  boxNumber : {color:'green',fontWeight:'900',fontSize:25,marginBottom:0},
}
