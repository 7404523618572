import React, { useEffect,useState,useContext } from "react";
import { Outlet, Link } from "react-router-dom";
import { Badge, Button, Table} from 'react-bootstrap';
import { RiProductHuntLine, RiListSettingsFill } from "react-icons/ri";
import { Chart } from "react-google-charts";
import Loader from '../../component/loader';
import NotApprove from '../../component/notApprove';
import axios from 'axios';
import Cookies from 'js-cookie';
import {decrpt} from '../../component/encryption';
import  RegistrationStatus  from "../../context/RegistrationStatus";


const data1 = [
  ["Month", "Delivered", "Cancelled"],
  ["Jan-Mar", 300, 200],
  ["Aprl-Jun", 500, 300],
  ["Jul-Sep", 450, 600],
  ["Oct-Dec", 837, 600],
];
// console.log(data1,'data1');


function Dashboard() {
  const [loader,setLoader] = useState(false);
  const [data,setData]=useState();
  const [countData,setCountData]=useState();
  const [orderListData,setOrderListData]=useState();
  const [chartListData,setChartListData]=useState();
  const Status = useContext(RegistrationStatus);

  console.log(Status,'Context Profile Statusfor code');

  // order fetch Api
  const appLoad = async () =>{
    setLoader(true); 
    let arr=[];
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`/seller/dashboard`)
       console.log(response.data,'response dasfasfd');
      if (response.data.success) {
        let Profile = response.data.registration_status;
        Status.setProfile(Profile);           
      //  setData(response.data);
        setCountData(response.data.counts);
        setOrderListData(response.data.data_list.order_list);
      //  setChartListData(response.data.chart_counts.order_chart);
      // response.data.chart_counts.order_chart.map((item,index)=>{
      //   arr.push(item)
      // })
      // console.log(arr,'arr');
      
      //  setChartListData({
      //     data:(arr.unshift(["Month", "Delivered", "Cancelled"]),arr.map((item) => [item.month, item.completed , item.cancelled] ))
      //  });
        setLoader(false);
      } else {
          setLoader(false);
      }
    } catch (error) {
        console.log('Error',error);
        setLoader(false);
    }
  }
  const options = {
    // title: "NEW ORDER",
    hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
    vAxis: { minValue: 0 },
    chartArea: { width: "60%", height: "75%" },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    appLoad();
  }, []);
  
  return (
    <div className="responsive-xs responsive-sm">
      { Status.profile.registration_status && Status.profile.registration_status == 5 ?
      <NotApprove/> :
      <>
        {loader ? 
        <Loader />
        :
        <>
       {/* Count */}
            <div className="count-section d-flex justify-content-between">
              {/* count 1 */}
              <div className="count-block d-flex align-items-center justify-content-between">
                <div className="count-figure"><h3>{countData && countData.total_products}</h3></div>
                <div className="count-content">
                  <h5><RiProductHuntLine className="menu-icon" /> Total Products</h5>
                  <Link to="product" className="link-btn">add product</Link>
                </div>
              </div>
              {/* count 2 */}
              <div className="count-block d-flex align-items-center justify-content-between">
                <div className="count-figure"><h3>{countData && countData.total_new_orders}</h3></div>
                <div className="count-content">
                  <h5><RiProductHuntLine className="menu-icon" /> Total Orders</h5>
                  <Link to="order/list" className="link-btn">see orders</Link>
                </div>
              </div>
              {/* count 3 */}
              <div className="count-block d-flex align-items-center justify-content-between">
                <div className="count-figure"><h3>{countData && countData.total_cancelled_orders}</h3></div>
                <div className="count-content">
                  <h5><RiProductHuntLine className="menu-icon" /> Total Cancelled </h5>
                  <Link to="order/list" className="link-btn">see Cancelled orders</Link>
                </div>
              </div>
            </div>
            {/* New Order */}
            {/* <div className="section">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="card">
                  <div style={{padding:15}}>
                    <Chart
                      chartType="AreaChart"
                      width="100%"
                      height="300px"
                      // data={[
                      //   ["Month", "Completed", "Cancelled"],
                      //   chartListData.map(((d)=>{
                      //     return(
                      //       [ d.month, d.completed, d.cancelled ]
                      //     )
                      //   }))
                      // ]}
                    
                      data={data1}
                      options={options}
                    />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mobile-mt-20">
                  <div className="card">
                    <div style={{padding:15}}>
                    <Chart
                      chartType="Bar"
                      width="100%"
                      height="300px"
                      data={chartListData}
                      options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* New Order */}
            <div className="section">
              <div className="row align-items-center">
                 <div className="col-md-12">
                  <div className="card p-2">
                    <div className="card-title d-flex align-items-center justify-content-between">
                      <h5>New Orders</h5>
                      <Link to="order/list" className="btn btn-xs btn-warning">View All Order</Link>
                    </div>
                    <table className='table tabe-bordered'>
                      <thead>
                        <tr className="text-center">
                          <th>Order ID</th>
                          <th>Byer Name</th>
                          <th>Date of Order</th>
                          <th>Order Amount</th>
                          <th>Payment Type</th>
                          <th>Order Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderListData && orderListData.map((item,index)=>{
                          return(
                            <tr className="text-center" key={index}>
                              <td>{item.order_uuid}</td>
                              <td>{item.user_name}</td>
                              <td>{item.order_date}</td>
                              <td>{item.overall_price}</td>
                              <td>{item.payment_type}</td>
                              <td >
                                { item.order_status == 1 && <Badge bg="primary"> New</Badge>}
                              </td>

                          </tr>
                          )
                        })}
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          <Outlet />
          </>
        
      }
      </>
      }
    </div>
  );
}

export default Dashboard;
