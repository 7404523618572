import React from 'react';


export default function OrderStatus (props){
    // console.log(props.delivery_status);

    if(props.delivery_status == 1){
        return <span className="badge text-white bg-success"> Approved</span>;
    }
    else if(props.delivery_status == 2){
        return <span className="badge text-dark bg-warning"> disabled</span>;

    }
    else if(props.delivery_status == 3){
        return <span className="badge text-dark bg-info"> Requested</span>;

    }else if (props.delivery_status == 4){
        return <span className="badge text-dark bg-danger"> Rejected</span>;
    }else{
        return <span></span>;
    }
}

