import React, { useState } from 'react'
import { Table } from 'react-bootstrap';
import { FaBookmark } from "react-icons/fa";
import { Link } from 'react-router-dom';


const CommisionPage = () => {

    const [selectedStatus , setSelectedStatus] = useState(false);

    const styles = {
        popup:{
            color : 'red',
            background : 'red'
        }
      };

    return (
        <div className="section">
          <div className="row">
                <div className="col-md-12 mb-4">
                    <div className="section-title">
                        <h5><FaBookmark className="me-1" />Commission Section</h5>
                    </div>
                    <div className="count-section d-flex gap-3 mt-2">
                        {/* count 1 */}
                        <button type="button" onClick={()=>{setSelectedStatus(true)}}  className="count-block d-flex align-items-center justify-content-between no-divider">
                            <div  className="count-figure" style={{fontSize:30}}>90</div>
                            <div className="count-content">
                                <h5>Pending Commission</h5>
                            </div>
                        </button>
                        {/* count 2 */}
                        <button type="button" className="count-block d-flex align-items-center justify-content-between no-divider">
                            <div  className="count-figure" style={{fontSize:30}}>46</div>
                            <div className="count-content">
                                <h5>Received Commission</h5>
                            </div>
                        </button>
                    </div>
                    <div className="col-md-12">
                        <div className="card mb-3 p-2">
                            <div className="card-title d-flex align-items-center justify-content-between">
                                <h5>All Order Items</h5>
                                <div>
                                {/* <Link to='../orderSearch' className="btn btn-sm btn-warning" >Search</Link> */}
                                </div>
                            </div>
                            <div>
                            <Table responsive="sm">
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Order Id</th>
                                            <th>Sell Price</th>
                                            <th className="text-center">Aivimart Commision</th>
                                            <th className="text-center">My Commision</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>or9298928392</td>
                                            <td>₹ 5676.00</td>
                                            <td className="text-center"> ₹ 676.00 (18% of actual Price)</td>
                                            <td className="text-center"> ₹ 5000.00</td>
                                            <td className="text-center">
                                                <Link to="commisionDetails" className='btn btn-sm btn-success text-white me-1'>View</Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>or9298928392</td>
                                            <td>₹ 5676.00</td>
                                            <td className="text-center"> ₹ 676.00 (18% of actual Price)</td>
                                            <td className="text-center"> ₹ 5000.00</td>
                                            <td className="text-center">
                                                <Link to="commisionDetails" className='btn btn-sm btn-success text-white me-1'>View</Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>or9298928392</td>
                                            <td>₹ 5676.00</td>
                                            <td className="text-center"> ₹ 676.00 (18% of actual Price)</td>
                                            <td className="text-center"> ₹ 5000.00</td>
                                            <td className="text-center">
                                                <Link to="commisionDetails" className='btn btn-sm btn-success text-white me-1'>View</Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>or9298928392</td>
                                            <td>₹ 5676.00</td>
                                            <td className="text-center"> ₹ 676.00 (18% of actual Price)</td>
                                            <td className="text-center"> ₹ 5000.00</td>
                                            <td className="text-center">
                                                <Link to="commisionDetails" className='btn btn-sm btn-success text-white me-1'>View</Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>or9298928392</td>
                                            <td>₹ 5676.00</td>
                                            <td className="text-center"> ₹ 676.00 (18% of actual Price)</td>
                                            <td className="text-center"> ₹ 5000.00</td>
                                            <td className="text-center">
                                                <Link to="commisionDetails" className='btn btn-sm btn-success text-white me-1'>View</Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>or9298928392</td>
                                            <td>₹ 5676.00</td>
                                            <td className="text-center"> ₹ 676.00 (18% of actual Price)</td>
                                            <td className="text-center"> ₹ 5000.00</td>
                                            <td className="text-center">
                                                <Link to="commisionDetails" className='btn btn-sm btn-success text-white me-1'>View</Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>or9298928392</td>
                                            <td>₹ 5676.00</td>
                                            <td className="text-center"> ₹ 676.00 (18% of actual Price)</td>
                                            <td className="text-center"> ₹ 5000.00</td>
                                            <td className="text-center">
                                                <Link to="commisionDetails" className='btn btn-sm btn-success text-white me-1'>View</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommisionPage