import React, { useEffect, useState } from "react";
import { Outlet,useLocation } from "react-router-dom";
import { FaPhoneAlt, FaRegEnvelopeOpen, FaRegPaperPlane } from "react-icons/fa";
import { AiOutlinePoweroff, AiOutlineKey } from "react-icons/ai";
import { Badge, Button, Table} from 'react-bootstrap';
import Loader from '../../component/loader';
import TableThumbImage from '../../component/tableThumbImage';
import axios from 'axios';
import Cookies from "js-cookie";
import {decrpt} from "../../component/encryption";

function RefundOrderSingle() {  
  const [loader,setLoader] = useState(false)
  const location = useLocation();
  console.log(location , "location");

  const [orderDetails,setOrderDetails]=useState('');
  console.log(orderDetails,'orderDetails');
  const fetchOrderDitails=async()=>{
    setLoader(true);
    const data = {
      order_id: location.state.item,
    }
    console.log(data,'data');
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization']=`Bearer ${token}`;
      const response = await axios.post(`/seller/returned/orders/details`,data);
      if (response.data.success) {
        setLoader(false);
        setOrderDetails(response.data.data);
      } else {
        setLoader(false);
      }
      
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }
  useEffect (() => {
    fetchOrderDitails();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-content">
      {loader ? 
        <Loader />
        :
        <>
          {/* New User Registration */}
          <div className="section">
            <div className="row">

              <div className="col-md-12">
                <div className="card mb-3 p-2">
                  <h3 className="h3 fw-bold" style={styles.header}>{orderDetails && orderDetails.product_name}</h3>
                  <div className="d-flex mt-3">
                    <label className="mb-2"><strong>Order Status : </strong> 
                      { orderDetails.status == 1 ? <Badge bg="primary"> New</Badge> : 
                      (orderDetails.status == 2)  ? <Badge bg="success"> Accepted </Badge> :
                      (orderDetails.status == 3)  ? <Badge bg="secondary"> Pick Up </Badge> : 
                      (orderDetails.status == 4) ? <Badge bg="warning"> Refunde Completed </Badge> : 
                      <Badge bg="danger"> Cancelled</Badge> }</label>
                    &nbsp;  | &nbsp; 
                    <label className="mb-2"><strong>Delivered Date : </strong> {orderDetails && orderDetails.delivered_date}</label>
                    &nbsp;  | &nbsp; 
                    <label className="mb-2"><strong>Product Size : </strong> {orderDetails && orderDetails.product_size_name}</label>
                  </div>

                  <div className="d-flex order-flex-wrap mb-2">
                      <label><strong>Repaid Ammount : </strong> <span className="mb-2" >₹ {orderDetails && orderDetails.amount_to_be_repaid}</span></label>
                          &nbsp;  | &nbsp; 
                      <label><strong>Payment Status : </strong>  <span className="mb-2" >
                      { orderDetails && orderDetails.payment_status == 1 ? <Badge bg="warning"> Pending</Badge> :
                      <Badge bg="success"> Refunded</Badge> }</span></label>
                    </div>
                    <div className="d-flex order-flex-wrap mb-2">
                      <label><strong>Byer Name : </strong> <span className="mb-2" >₹ {orderDetails && orderDetails.user_name}</span></label>
                    </div>
                    <div className="d-flex order-flex-wrap mb-2">
                      <label><strong>Remarks : </strong> <span className="mb-2" >{orderDetails && orderDetails.remarks}</span></label>
                    </div>
                    <div className="d-flex order-flex-wrap mb-2">
                      <label><strong>Policy : </strong> <span className="mb-2" >{orderDetails && orderDetails.policy}</span></label>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </>
      }
    </div>

  );
}

export default RefundOrderSingle;
const styles = {
  header: {textTransform: 'uppercase',color:'#149348',marginBottom:0},
  box : {background:'#e1e1e1',borderRadius:10, paddingLeft:10,paddingRight:10,padding:10,flex:1,boxShadow: '0 0 5px 0 #868686',border: '2px dotted #fff'},
  boxHeading : {color:'#333',fontWeight:'900',fontSize:15,marginBottom:0},
  boxNumber : {color:'green',fontWeight:'900',fontSize:25,marginBottom:0},
}
