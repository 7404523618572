import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const AddCategory = (props) => {

  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-4">
            <label className="form-label label-txt">
              Select Category
            </label>
            <select className="form-select" aria-label="Default select example"  onChange={(e) =>{ 
              e.preventDefault();
              props.fetchSubCategory(e.target.value)
              props.setAddProductData({...props.addProductData, category_id:e.target.value})
            }
            }
            >
              <option defaultValue> Select Category</option>
              {props.categoryData && props.categoryData.map((item, index)=>{
                return (
                  <option key={index} value={item.id}>{item.name}</option>
                )
              })
              }
            
            </select>
          </div>
          {props.subCategoryData =='' ? <></>:
          <div className="col-md-4 ">
            <label className="form-label label-txt col-md-7">
              Select Sub Category
            </label>
            <select className="form-select" aria-label="Default select example" onChange={(e) =>{ 
              e.preventDefault();
              props.fetchLastCategory(e.target.value)
              props.setAddProductData({...props.addProductData, sub_category_id:e.target.value})
            }
              }>
              <option defaultValue> Select Sub Category</option>
              {props.subCategoryData && props.subCategoryData.map((item,index)=>{
                return(
                  <option key={index} value={item.id}>{item.name}</option>
                )
              })}
              </select>
          </div>
          }

            {props.lastCategoryData =='' ? <></>:
          <div className="col-md-4 ">
            <label className="form-label label-txt col-md-7">
              Select Last Category
            </label>
            <select className="form-select" aria-label="Default select example" onChange={(e) => {
              e.preventDefault();
              props.setDone(true);
              props.setAddProductData({...props.addProductData, third_category_id:e.target.value})
              props.fetchBrandData(e.target.value)
            }}>
              <option defaultValue> Select Last Category</option>
              {props.lastCategoryData && props.lastCategoryData.map((item,index)=>{
                return(
                  <option key={index} value={item.id}>{item.name}</option>
                )
              })}
            </select>
            </div>
          }
        </div>
      </div>
      <div className="product-Modal-footer" style={{position: 'fixed', width: '100%',left: 0,bottom: 0, marginLeft: 0,backgroundColor:'#fff'}}>
        <button type="button" className="btn btn-primary product-modal-saveBtn" disabled={!props.done} style={!props.done ? {opacity:'.3'}:null} onClick={() => props.DisableHandler1()}>Save & Continue</button>
      </div>
    </>
  );
};

export default AddCategory;