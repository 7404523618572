// Encrypt
export function encrpt (token) {
    let TextStart = Math.random().toString(16).substr(2, 10);
    let TextEnd = Math.random().toString(16).substr(2, 10);
    let newText = TextStart + token + TextEnd;
    return newText
} 

// Decrypt
export function decrpt (token) {
    let removefirst = token.slice(10);
    let removelast = removefirst.slice(0, -10);
    return removelast
} 