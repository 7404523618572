import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { BiBuildingHouse, BiEditAlt } from "react-icons/bi";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { decrpt } from "../../../component/encryption";
// import axios from "axios";
// import Cookies from "js-cookie";

const Address = (props) => {    
    const [show, setShow] = useState(false);
    const [addresvalue , setAddresvalue] = useState({
        state_id :props.sellerProfile && props.sellerProfile.state_id,
    });
    // console.log('dsdhjdhsj' , addresvalue);
    
    return (
        <div>
            {/* New User Registration */}
            <div className="card mb-3 p-2">
                <div className="d-flex justify-content-between">
                    <h4 className="h4 fw-bold" style={styles.header}>Address Details</h4>
                    <button className="profile-edit-btn"><BiEditAlt style={{ color: '#000', fontSize: 18 }} onClick={() => setShow(true)} /></button>
                </div>
                <div className=" mt-3">
                    <div className="row">
                        <div className="col-md-8">
                            <label><strong>Address</strong></label>
                            <p className="mb-2">{props.sellerProfile && props.sellerProfile.address}</p>
                            {/* <p className="mb-2"> {props.sellerProfile && props.sellerProfile.address  !== '' ?  props.sellerProfile.address : props.sellerProfile.business_details.address }</p> */}
                        </div>
                        <div className="col-md-4">
                            <label><strong>State</strong></label>
                                <p className="mb-2">{props.sellerProfile && props.sellerProfile.state}</p>
                                {/* <p className="mb-2">{props.sellerProfile && props.sellerProfile.state !== '' ?  props.sellerProfile.state : props.sellerProfile.business_details.state }</p> */}
                        </div>
                        <div className="col-md-4">
                            <label><strong>Landmark</strong></label>
                            <p className="mb-2" >{props.sellerProfile && props.sellerProfile.land_mark}</p>
                            {/* <p className="mb-2" >{props.sellerProfile && props.sellerProfile.land_mark !== '' ?  props.sellerProfile.land_mark : props.sellerProfile.business_details.land_mark }</p> */}
                        </div>
                        <div className="col-md-4">
                            <label><strong>City</strong></label>
                            <p className="mb-2">{props.sellerProfile && props.sellerProfile.city}</p>
                            {/* <p className="mb-2">{props.sellerProfile && props.sellerProfile.city !== '' ?  props.sellerProfile.city : props.sellerProfile.business_details.city }</p> */}
                        </div>
                        <div className="col-md-4">
                            <label><strong>Pin code</strong></label>
                            <p className="mb-2">{props.sellerProfile && props.sellerProfile.pin}</p>
                            {/* <p className="mb-2">{props.sellerProfile && props.sellerProfile.pin !== '' ?  props.sellerProfile.pin : props.sellerProfile.business_details.pin }</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Address Info Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={props.updateAddress} method="POST">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Address</Form.Label>
                            <Form.Control 
                            as="textarea" rows={3}
                            defaultValue ={props.address ? props.address.address : ''}
                            onChange={e => props.setAddress({...props.address, address:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>State</Form.Label>
                            <Form.Select  aria-label="Default select example" 
                                // defaultValue={addresvalue.state_id}
                                onChange={state => props.handleState(state.target.value)}
                                >
                                <option selected  disabled>select states</option>
                                {
                                props.fetchStates && props.fetchStates.map((item ) => {
                                    return (
                                    <option  key={item.id} value={item.id}> {item.name}</option>
                                ) })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>City</Form.Label>
                            <Form.Select  aria-label="Default select example" 
                                // defaultValue={props.address ? props.address.city : '' }
                                onChange={e => props.setAddress({...props.address, city:e.target.value})}
                            >
                                    <option selected disabled>select city</option>
                                {
                                    props.fetchCities && props.fetchCities.map((item ) => {
                                        return (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                        )
                                    }) 
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Landmark</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="landmark"
                                defaultValue={props.address && props.address.land_mark }
                                onChange={e => props.setAddress({...props.address, land_mark:e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Pin no.</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Pin no"
                                defaultValue={props.address && props.address.pin }
                                onChange={e => props.setAddress({...props.address, pin:e.target.value})}
                            />
                        </Form.Group>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
                    <Button className="saveChangesBtn" type="submit">Save Changes</Button>
                </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Address;
const styles = {
    header: { textTransform: 'uppercase', color: '#149348', marginBottom: 0 },
    box: { background: '#e1e1e1', borderRadius: 10, paddingLeft: 10, paddingRight: 10, padding: 10, flex: 1, boxShadow: '0 0 5px 0 #868686', border: '2px dotted #fff' },
    boxHeading: { color: '#333', fontWeight: '900', fontSize: 15, marginBottom: 0 },
    boxNumber: { color: 'green', fontWeight: '900', fontSize: 25, marginBottom: 0 },
}