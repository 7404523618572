
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';

const ProductSearch = () => {
    return (
        <div>
        <Form>
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-4">
              <label className="form-label label-txt">
                Select Category
              </label>
              <select class="form-select" aria-label="Default select example" >
                <option selected disabled> Select Category</option>
                <option value="1">Cloths</option>
                <option value="2">Beauty</option>
                <option value="3">Electronics</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="col-md-4 ">
              <label className="form-label label-txt col-md-7">
                Select Sub Category
              </label>
              <select class="form-select" aria-label="Default select example" >
                <option selected disabled> Select Sub Category</option>
                <option value="1">Men's Top Wear</option>
                <option value="2">Women's Top Wear</option>
                <option value="3">Body Care</option>
              </select>
            </div>
            <div className="col-md-4 ">
              <label className="form-label label-txt col-md-7">
                Select Last Category
              </label>
              <select class="form-select" aria-label="Default select example" >
                <option selected disabled> Select Last Category</option>
                <option value="1">Men's T-Shirt</option>
                <option value="2">Women's Top</option>
                <option value="3">Body Lotion</option>
              </select>
            </div>
            </div>
            <div className="row mt-2">
            <div className="col-md-4 ">
              <label className="form-label label-txt col-md-7">
                Brand
              </label>
              <select class="form-select" aria-label="Default select example" >
                <option selected disabled> Select Brand</option>
                <option value="1">Brandb 1</option>
                <option value="2">Brandb 2</option>
                <option value="3">Brandb 3</option>
              </select>
            </div>
            <div className="col-md-4 ">
              <label className="form-label label-txt col-md-7">
                Type
              </label>
              <select class="form-select" aria-label="Default select example" >
                <option selected disabled> Select Type</option>
                <option value="1">Requested</option>
                <option value="2">Approved</option>
                <option value="3">Rejected</option>
              </select>
            </div>
            </div>
            <div className="row mt-3">
            <div className="col-md-6 ">
              <label className="form-label label-txt col-md-7">
                Price
              </label>
              <Form.Check  label="Graterden Price"
               name="group1"
               type="radio"
               checked
               id="below"
             />
             <Form.Check
                label="Lessden Price"
                name="group1"
                type="radio"
                id="above"
            />
            <input type="text" className="form-control mt-3"  placeholder='enter price'/>
            </div>
            <div className="col-md-6 ">
              <label className="form-label label-txt col-md-7">
                Stock
              </label>
              <Form.Check label="Graterden Stock"
               name="stock1"
               type="radio"
               checked
               id="belowStock"
             />
             <Form.Check
                label="Lessden Stock"
                name="stock1"
                type="radio"
                id="aboveStock"
            />
            <input type="text" className="form-control mt-3"  placeholder='enter stock'/>
            </div>
            </div>
          
          <div style={{textAlign: 'right'}} className="mt-4">
            <Link className='btn btn-secondary me-2' to="/product" >Back</Link>
          <Link to="../productSearchDetails" className="saveChangesBtn btn btn-xl btn-warning" >
            Search
        </Link>
        </div>
         </div>
      </Form>
        </div>
    );
};

export default ProductSearch;