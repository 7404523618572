import React,{useState} from 'react';
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import axios from 'axios';
import Loader from '../../../component/loader';
import Modal from 'react-bootstrap/Modal';



const ProductImgEditModal = (props) => {
  const [loader , setLoader ] = useState();
  const [imageUpload , setImageUpload ] = useState();
 

  let allData = props.selectedItems && props.selectedItems;
  let productID = props.selectedItems && props.selectedItems.id
  let thumb_image = props.selectedItems && props.selectedItems.main_image ;
  let images = props.selectedItems && props.selectedItems.images

  const imageThumb = async (item) => {
    console.log(item , 'item check thumb')
    try {
      const data = {
        product_id : productID && productID,
        image_id : item.id
      }
      setLoader(true);
      const response = await axios.post(`/seller/product/make/thumb` , data )
      .then(response => {
        console.log(response.data , 'thumb response')
        setLoader(false);
        props.fetchProductDitails();
        props.setImgModal(false);
        props.setFilterAddModal(true)
      })
    }catch(error) {
      console.log(error, 'error')
    }
  }

 



  console.log(thumb_image , 'dsamdsml 2' )

    return (
      <>
          {loader && loader 
          ?
            <div style={{height : 420 , position : 'relative'}}> <Loader /> </div>
            :
            <>
              {/* <Modal.Body>  */}
              <div>
                <div className="row">
                  <div className="col-md-2">
                    <div>
                      <span className="badge text-dark bg-warning" style={{position: "absolute", left: '10.9%'}}>Thumb</span>
                          <img src={thumb_image && thumb_image} />
                      </div>
                  </div>
                    {images && images.map((item,index) => {
                      return (
                              <div className="col-md-2">
                        <div key={index}>
                              <img src={item.image} style={{    width: 120 ,  height: 150 }}/>
                              <div className="d-flex justify-content-center pt-2 gap-1">
                                <button className="btn btn-xs btn-success" onClick={()=>imageThumb(item) } >Set as Thumb</button>
                                {thumb_image == item.image ?
                                <>
                                    
                                </> :
                                  <button className="btn btn-xs btn-danger" onClick={()=> props.handleDelete(item)}><AiFillDelete />{console.log(item.image , 'dsamdsml')} </button>
                                  }
                              </div>
                            </div>
                        </div>      
                      );
                    })}
                </div>
                <div>
                
                  <div className="col-md-12 ">
                      <div className='d-flex mt-3 column-gap-30 col-md-12' >
                        {
                          props.file.length > 0 && props.file.map((item, index) => {
                            return (
                              <div className="uploadImageBox" key={index}>
                                <img
                                  src={item.image}
                                  style={{ width: 150, height: 120 }}
                                />
                              </div>
                            )

                          })
                        }
                      </div>
                      {console.log(props , 'props.fileprops.file')}
                      {
                        props.selectedItems && props.selectedItems.images.length == 5 ?
                        '' : <>
                        <label className="form-label label-txt mt-3">
                          Upload More Product Image
                        </label>
                        <input type="file" name="image[]" id="file" className="form-control"  onChange={props.handleChange} />
                        <small style={{color : 'red' , fontSize : 12}}>* Upload Image one at a time </small>
                      </> 

                      }
                  </div>
                </div>
              </div>
             
              {/* </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={() => props.setImgModal(false)}>Close</Button>
                  <Button className="saveChangesBtn" onClick={() => props.uploadImagestuff()}>Save Changes</Button>
              </Modal.Footer> */}
            </>
        }
      </>
    );
};

export default ProductImgEditModal;